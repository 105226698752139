import React from "react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import axios from "axios";
import styles from "./ResultPage.module.css";
import MyDocument1 from "../../routes/ReactPdf/ReactPdf";
import MyDocument2 from "../../routes/ReactPdf/ReactPdfNonI";
import { PDFDownloadLink } from "@react-pdf/renderer";
import Download from "../../assets/download.svg";

const ResultPage = ({  ayur,dietChart, exercise }) => {
  const navigate = useNavigate()
  const [prakritiSpaced, setPrakritiSpaced] = useState("");
  const [prakritiType, setPrakritiType] = useState("");
  const [resData, setResData] = useState(null);
  const [profile, setProfile] = useState(null);
  const [vitals, setVitas] = useState(null);
  const [screenshot, setScreenshot] = useState(null);
  const [enduser, setEnduser] = useState(true)
  
  const location = useLocation();
  const { iterveResult, ayurResult, profileData, infla, capturedImage, token } = location.state || {}; 
  // console.log(location.state);

  const email = localStorage.getItem("email");
  // const handleClick = () => {
  //   navigate('/pdf', {
  //     state: {
  //       // vitals: {/* your vitals data */},
  //       // profileData: {/* your profile data */},
  //       // email: 'example@example.com',
  //       // ayur: {/* your ayur data */},
  //       // prakriti: {/* your prakriti data */},
  //       // dietChart: {/* your diet chart data */},
  //       // exercise: {/* your exercise data */}
  //         vitals,
  //         profileData,
  //         email:email,
  //         ayurResult,
  //         prakritiSpaced,
  //         data,
  //         pakritiexercise,
  //     },
  //   });
  // };

  useEffect(() => {
    if (prakritiType === "VataPitta") {
      setPrakritiSpaced("Vata Pitta");
    }
    if (prakritiType === "VataKapha") {
      setPrakritiSpaced("Vata Kapha");
    }
    if (prakritiType === "PittaVata") {
      setPrakritiSpaced("Pitta Vata");
    }
    if (prakritiType === "PittaKapha") {
      setPrakritiSpaced("Pitta Kapha");
    }
    if (prakritiType === "KaphaVata") {
      setPrakritiSpaced("Kapha Vata");
    }
    if (prakritiType === "KaphaPitta") {
      setPrakritiSpaced("Kapha Pitta");
    }
  }, [prakritiType]);

  useEffect(() => {
    setPrakritiType(ayurResult["category"]);
    setResData(ayurResult);
    setProfile(profileData);
    setVitas(iterveResult);
    setScreenshot(capturedImage);
  }, [ayurResult])

  const [profileImage, setProfileImage] = useState("");

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const User = await axios.get("https://aivotayur.com/api/loginDetails", {
          params: { email }, // Pass the email as a query parameter
        });
        //   console.log("UserDetails response: ",User.data.LoginData);
        setProfileImage(User.data.LoginData.profileImage || "");
      } catch (error) {
        console.error("Error fetching user:", error);
      }
    };

    fetchUser();
  }, [email]);

    //for deleting the token if it is enduser

    useEffect(()=> {
      const deleteToken = async() => {
        try {
          const res = await axios.delete("https://aivotayur.com/api/token", {params: {token}});
          console.log("token deleted successfully",res);
        } catch (error) {
          console.log("error in deleting the token", error?.message)
        }
      }
      
      if(token != "") {
        deleteToken();
      }
    },[])

  // console.log(iterveResult.heart_rate)
  // console.log(vitals.heart_rate)
  let prakriti = "";
  if (
    prakritiType === "PittaKapha" ||
    prakritiType === "PittaVata" ||
    prakritiType === "KaphaPitta"
  ) {
    prakriti = "a";
  } else if (
    prakritiType === "VataPitta" ||
    prakritiType === "VataKapha" ||
    prakritiType === "KaphaVata"
  ) {
    prakriti = "b";
  }
  const [currentSlide, setCurrentSlide] = useState(0);
  const [currentSlideinflamatory, setCurrentSlideinflamatory] = useState(0);
  const [currentSlideinflamatory2, setCurrentSlideinflamatory2] = useState(0);
  const [currentSlideinflamatory3, setCurrentSlideinflamatory3] = useState(0);

  const inflammatoryslides = [
    {
      time: "Morning (8 AM)",
      details:
        "Soak 30 grams of split green gram for 3 hours. Add 180 ml of water and boil until the green gram becomes soft. Mash it, then add a pinch of salt, cumin (jeera), and pepper powder. Stir well and drink.",
    },
    {
      time: "Mid-Day (12 PM)",
      details:
        "Take one cup of little millet (Kutki) and soak it. Add 6 cups of water and boil until the millet becomes soft. Mash it, then add a little salt, cumin (jeera), and pepper. Stir well and drink.",
    },
    {
      time: "Evening (5 PM)",
      details:
        "Take one cup of semolina (Rava) and fry it in ghee. Add six cups of water and a bit of jaggery, then stir well and drink.",
    },
    {
      time: "Night (8 PM)",
      details:
        "Take one cup of Sabu-dana (tapioca pearls) and add six cups of water. Boil it until it reaches a soupy consistency. Add salt and red chili according to taste, then boil it again before drinking.",
    },
    {
      time: "Alternative (Mid-Day / Night)",
      details:
        "Wheat Gruel (Dalia): Start by rinsing 1/2 cup of broken wheat and dry roasting it in a pan until it turns golden. In a separate pot, bring 5 cups of water to a boil, then add the roasted wheat and stir well. Let it simmer on low heat for 15-20 minutes, stirring occasionally until the wheat becomes soft and fully cooked. Once done, season with a pinch of salt and mix everything together. Serve the gruel.",
    },
  ];
  const inflammatoryslides2 = [
    {
      time: "Morning (8 AM)",
      details:
        "To make oil-free upma, dry roast semolina until golden. In a heated pot, sauté mustard seeds, cumin, curry leaves, green chilies, and onions until softened, then add vegetables. Pour 2 cups of water, add turmeric and salt, bring to a boil, and gradually stir in the roasted semolina. Simmer for 3-4 minutes, garnish with coriander and lemon juice, and serve hot.",
    },
    {
      time: "Mid-Day (12 PM)",
      details:
        "Rinse and soak 1/2 cup of rice and 1/2 cup of yellow moong dal for 10-15 minutes. In a pot or pressure cooker, boil 4 cups of water and add rice, dal, turmeric, salt, and optional cumin seeds. Add vegetables like carrots, peas, or beans if desired, and cook until soft. Adjust the consistency with water if needed and serve hot.",
    },
    {
      time: "Evening (5 PM)",
      details:
        "Rinse 1 cup of rice and cook with 2 cups of water and salt for about 15 minutes. For the dal, rinse 1 cup of lentils and cook in 3 cups of water until soft. In a pan, sauté cumin seeds, onions, ginger-garlic paste, tomatoes, and green chilies, then add turmeric and salt. Combine with the cooked lentils, simmer for 5-10 minutes, garnish with coriander, and serve with rice.",
    },
  ];
  const inflammatoryslides3 = [
    {
      time: "Morning (8 AM)",
      details: `To make oil-free upma, dry roast semolina until golden. In a heated pot, sauté mustard seeds, cumin, curry leaves, green chilies, and onions until softened, then add vegetables. Pour 2 cups of water, add turmeric and salt, bring to a boil, and gradually stir in the roasted semolina. Simmer for 3-4 minutes, garnish with coriander and lemon juice, and serve hot.`,
    },
    {
      time: "Mid-Day (12 PM)",
      details: `Roti and simple sabji (any vegetable except brinjal and potato).`,
    },
    {
      time: "Evening (5 PM)",
      details:
        "Rinse 1 cup of rice and cook with 2 cups of water and salt for about 15 minutes. For the dal, rinse 1 cup of lent",
    },
  ];

  const slides = [
    {
      time: "Breakfast (8:30-9 am)",
      content:
        prakriti === "a"
          ? "Poha, methi paratha, Besan ka chhilka, idli and sambhar, upma, paneer paratha"
          : "Oats porridge, sprout salad, boiled eggs, fruit smoothie",
    },
    {
      time: "Lunch (1-2 pm)",
      content:
        prakriti === "a"
          ? "Jowar ki roti, Wheat flour ki roti, Nachni ki roti, Bhindi sabji, Chole sabji, Rajma sabji, Palak paneer, Beans, Mixed vegetable curry, Chicken, Fish"
          : "Wheat roti, jowar roti, rice roti, beans, capsicum, cauliflower, soy chunks, Matki dal, fish",
    },
    {
      time: "Dinner (7-7:30 pm)",
      content:
        prakriti === "a"
          ? "Millet Upma, Vegetable Khichdi, Daliya (Cracked Wheat), Broccoli Soup, Sprouts (Moong), Nachni Soup (Ragi Soup) with Vegetables"
          : "Ragi ambali, cooked vegetables, beetroot-carrot tikkis, rice and dal, hummus and rice roti, rice toor dal",
    },
  ];
  const foodItems = [
    "Rasas (Taste)",
    "Cereals (Grains)",
    "Pulses (Legumes)",
    "Vegetables",
    "Fruits",
    "Spices",
    "Non-Veg",
    "Milk Products",
    "Oil Products",
    "Nuts/Seeds",
  ];

  const pakritiData = {
    VataPitta: {
      eat: [
        "sweet, bitter",
        "brown rice, wheat",
        "mung beans, black & red lentils",
        "cooked vegetables, asparagus, beets, carrots, cucumber, green beans, okra (cooked), onion (cooked), potatoes (sweet), and zucchini",
        "sweet fruits, avocado, bananas, coconut, figs (fresh), custard apple, sapodilla, lemon, mango, melons (sweet), papaya",
        "coriander, curry leaves, fenugreek, cumin, carom, ginger,asafetida, cardamom",
        "chicken, white meats",
        "all dairy products",
        "olive oil, sunflower oil, flaxseed oil",
        "walnuts, pistachio, sunflower seeds, pumpkin seeds",
      ],
      occasionally: [
        "sour, salty",
        "oats (cooked), rice (basmati), rice (white), wheat,millet",
        " ",
        "sweet & bitter vegetables, cabbage, cauliflower, celery, leafy greens, Salads, Ladyfinger, peas, parsley, peppers (green), potatoes, sprouts(cooked)",
        "orange, lemon (sweet), guava, sweet pineapple,  melons,  grapes, apricots, berries, cherries",
        "fennel, turmeric, fresh ginger,garlic, mustard",
        "Meat of goat, shrimp, river fish, poultry, egg, sea food",
        " ",
        "sesame oil",
        "soaked nuts",
      ],
      avoid: [
        "astringent, pungent",
        "barley, buckwheat, corn, oats (dry), rye",
        "black beans, kidney beans",
        "raw vegetables, broccoli, brussels sprouts, pungent vegetables, eggplant, onions, peppers (hot), radishes, spinach, tomatoes",
        "dried fruits, apples, cranberries, pears, sour fruits, persimmon, pomegranate, watermelon",
        "Dry ginger, chilly, cinnamon, tamarind",
        "Red meat, lamb, rabbit, pork, dark meats, shellfish, tuna",
        "processed cheese",
        "vegetable oil, safflower oil, mustard oil",
        "dry nuts",
      ],
    },
    VataKapha: {
      eat: [
        "Sweet, Sour, salty",
        "oats (cooked), rice, wheat",
        "mung beans, mung dal, chickpeas",
        "cooked vegetables, asparagus, carrots, green beans, okra (cooked),  onion (cooked), potatoes (sweet), radishes, and zucchini",
        "apricots, avocado, berries, cherries, grapes, lemon, mango, melons (sweet), oranges, papaya, pineapple(sweet), plums",
        "curry leaves, fenugreek, turmeric, mustard, cumin, asafetida, cardamom",
        "seafood, white meats, eggs, shrimp",
        "ghee, butter, butter milk",
        "ground nut oil, sesame oil, mustard oil, olive oil",
        "soaked (skinless) almonds, Brazil nuts, cashew, coconut, macadamia,  pecan, pine, hazelnut, walnuts, pistachio, sesame(tahini), chia, flax seed",
      ],
      occasionally: [
        "bitter, pungent, astringent(weekly once)",
        "barley, corn, millet, oats (dry), rice (small amount – basmati)",
        " ",
        "pungent & bitter vegetables,  beets, sprouts, cauliflower, celery, leafy greens, parsley, peas, potatoes, spinach",
        "apples, peaches, pears, persimmon, pomegranate, prunes, raisins ,figs (fresh)",
        "black pepper, chilly, ginger, fennel seeds, mint, carom , garlic, fennel, coriander, cinnamon",
        "lean fish, chicken, fresh water fish, poultry",
        "goat milk, home made cheese",
        "almond oil, corn oil, sunflower oil(all in moderation)",
        "popcorn, pumpkin seeds, sunflower seeds",
      ],
      avoid: [
        " ",
        "buckwheat, rye, rice (brown)",
        "black beans, kidney beans",
        "sweet & juicy vegetables, cucumber, tomatoes, raw vegetables, broccoli, brussels sprouts, cabbage, eggplant, lettuce, mushrooms",
        "dried fruits, cranberries, pears, watermelon, sour fruits, bananas",
        "excess salt",
        "lamb, rabbit, pork, Mutton, turkey chicken, red meat, seafood, salmon",
        "curd, all other dairy products",
        "all other oils",
        "dry roasted nuts, peanuts, psyllium, sesame seeds, salted nuts",
      ],
    },
    PittaVata: {
      eat: [
        "sweet, bitter",
        "barley, oats (cooked), rice (basmati), rice (white), wheat, millet",
        "all legumes except lentils",
        "sweet & bitter vegetables, asparagus, cabbage, cucumber, cauliflower, green beans, leafy greens, salads, ladyfinger, peppers (green), sprouts",
        "avocado, orange(sweet), lemon (sweet), guava, pineapple(sweet), melons",
        "coriander, cardamom, fennel, turmeric, fresh ginger, cumin,",
        "river fish, poultry, chicken",
        "butter (unsalted), cottage cheese, ghee, milk",
        "coconut oil, olive oil, sunflower oil, soy oil, ground nut oil",
        "almonds (soaked and peeled), coconut, psyllium, popcorn, pumpkin seeds, sunflower seeds, chai, almond butter, cashew",
      ],
      occasionally: [
        "sour, salty, astringent",
        "corn",
        "mung beans, black & red lentils",
        "cooked vegetables,  okra (cooked), onion (cooked), potatoes (sweet), and zucchini",
        "sweet fruits, apricots, bananas, berries, cherries, coconut, figs (fresh), grapes,  mango, peaches, plums, pomegranate, apples",
        "curry leaves, fenugreek, turmeric, carom, asafetida",
        "seafood,  turkey chicken, white meats, eggs, shrimp, meat of goat",
        "curd",
        "sesame oil",
        "Brazil nuts, macadamia, pecan, pine, hazelnut, walnuts, pistachio, sesame(tahini), flax, peanuts",
      ],
      avoid: [
        "pungent",
        "buckwheat, oats (dry), rice (brown), rye",
        "lentils",
        "pungent vegetables, beets, carrots, eggplant, radishes, spinach, tomatoes, raw vegetables, broccoli, brussels sprouts, celery,  mushrooms, onions (raw), parsley, peas, potatoes, spinach, tomatoes. ",
        "dried fruits, cranberries, pears, persimmon, watermelon, sour fruits, lemons, persimmon, plums (sour), oranges (sour), pineapples (sour),papaya",
        "dry ginger, chilly, mustard, garlic, cinnamon, tamarind",
        "dark meat, pork, shellfish ,tuna, Red meat, lamb",
        "buttermilk, cheese, sour cream, all dairy in moderation",
        "almond oil, corn oil, safflower oil, mustard oil",
        "nuts, dry roasted nuts, hemp",
      ],
    },
    PittaKapha: {
      eat: [
        "bitter, astringent ",
        "barley, oats (cooked), rice (basmati), rice (white), wheat",
        "all legumes except lentils",
        "asparagus, cabbage, cucumber, cauliflower, celery, green beans, leafy greens, Salads, ladyfinger, peas, parsley, peppers (green), potatoes, sprouts",
        "apple, avocado, lemon (sweet), guava, sweet pineapple, melons",
        "coriander, cardamom, turmeric, fresh ginger, cumin",
        "river fish, poultry, egg ",
        "ghee, milk",
        "coconut oil, olive oil, ground nut oil, soy oil",
        "almonds (soaked and peeled), coconut, flaxseed, psyllium, popcorn, pumpkin seeds, sunflower seeds",
      ],
      occasionally: [
        "pungent, sweet",
        "corn, millet, oats (dry)",
        "kidney beans, soy beans, black lentils, mung beans, lentils",
        "pungent & bitter vegetables,  spinach.",
        "apricots, berries, cherries, cranberries, figs (dry), mango, peaches, pears, persimmon, pomegranate, prunes, raisins",
        "black pepper, chilly (red), carom seed, fennel, mint, asafetida, small amount of cinnamon",
        "lean fish, chicken, white meat, fresh water fish, shrimp",
        "goat milk, butter, cottage cheese",
        "almond oil, corn oil, sunflower oil (all in moderation)",
        " ",
      ],
      avoid: [
        "sour, salty",
        "buckwheat, rice (brown)",
        " ",
        "beets, carrots, eggplant, onions, peppers (hot), radishes, sweet & juicy vegetables, potatoes (sweet), tomatoes.",
        "sour fruits, bananas, grapefruit, lemons, plums (sour),  papaya, pineapples (sour), sweet & sour fruits, coconut,  figs (fresh),  oranges",
        "dry ginger, mustard, garlic,  tamarind, salt",
        "dark meats, pork, sea food , shellfish, tuna, mutton, turkey  chicken, red meat, seafood, salmon",
        "buttermilk, cheese, sour cream, yogurt",
        "sesame oil",
        "tahini/sesame seeds, most nuts, pecans, peanuts, macadamia, cashews, walnuts, chia seeds, sesame seeds, nuts",
      ],
    },
    KaphaVata: {
      eat: [
        "bitter, astringent",
        "barley, corn, millet, oats (cooked), rice (small amount – basmati).",
        "all legumes except as listed",
        "pungent & bitter vegetables, asparagus, beets, sprouts, carrots, celery, leafy greens, Salads, Ladyfinger, parsley, peas, peppers",
        "apples, berries, cherries, cranberries, figs (dry), persimmon, pomegranate, prunes, raisins",
        "black pepper, chilly, ginger, turmeric,  mint, fennel, coriander, cinnamon, asafetida, curry leaves",
        "lean fish, chicken, fresh water fish, poultry, ",
        "ghee, goat milk, milk",
        "sesame oil, ground nut oil, mustard oil",
        "soaked (skinless) almonds,  coconut, popcorn, pumpkin seeds, sunflower seeds",
      ],
      occasionally: [
        "Sweet, Sour, salty",
        "",
        "mung beans, black & red lentils",
        "cooked vegetables,  cucumber, green beans, okra (cooked),  onion (cooked), potatoes (sweet), radishes, and zucchini",
        "sweet fruits, avocado, bananas, coconut, figs (fresh), grapefruit, lemon, mango, melons (sweet), oranges, papaya, plums",
        "fenugreek, mustard, cumin, carom, garlic, cardamom",
        "turkey chicken, white meats ,eggs, shrimp, goat meat",
        "butter, cheese, butter milk",
        "coconut oil",
        "Brazil nuts, cashew, macadamia, pecan, pine, hazelnut, walnuts, pistachio, sesame(tahini), flax, hemp",
      ],
      avoid: [
        "pungent",
        "oats (dry), rice (brown), rice (white), wheat, buckwheat, rye",
        "kidney beans, soy beans, black lentils",
        "raw vegetables, broccoli, brussels sprouts, cabbage, cauliflower, eggplant,  lettuce, mushrooms, onions (raw), parsley, potatoes, spinach, sprouts, tomatoes, sweet & juicy vegetables, cucumber.",
        "sour fruits, figs (fresh) ,  pineapple, plums, dried fruits, pears, watermelon",
        "salt (moderate)",
        "lamb, rabbit, pork, Mutton, red meat, seafood, salmon",
        "curd",
        " ",
        "dry roasted nuts, peanuts, psyllium, chia seeds, nuts",
      ],
    },
    KaphaPitta: {
      eat: [
        "bitter, pungent ,astringent",
        "barley, corn, millet, oats (dry), rice (small amount – basmati).",
        "all legumes ",
        "pungent & bitter vegetables, asparagus, beets, sprouts, cabbage, carrots, cauliflower, celery, leafy greens, Salads, Ladyfinger, parsley, peas ",
        "apples, apricots, berries, cherries, cranberries, figs (dry),peaches, pears, prunes, raisins",
        "Black pepper, green chilli , turmic, cumin, carom seed, fennel, mint, coriander, asafetida",
        "lean fish, chicken, white meat, fresh water fish, shrimp",
        "ghee, goat milk",
        "sunflower oil (all in moderation)",
        "coconut, flaxseed, pumpkin seeds, sunflower seeds",
      ],
      occasionally: [
        "sweet",
        "oats (cooked), wheat",
        "kidney beans ",
        "eggplant, onions, peppers (hot), radishes, spinach, tomatoes",
        "orange(sweet), lemon (sweet), guava, papaya, sweet pineapple, pomegranate, melons",
        "cinnamon, dry ginger",
        "poultry, egg white",
        "butter (unsalted), cottage cheese, milk",
        "coconut oil, olive oil,  soy oil, mustard oil,corn oil",
        "almonds (soaked and peeled), psyllium, popcorn,  chai seeds",
      ],
      avoid: [
        "sour, salty",
        "rice (brown), rice (white), buckwheat",
        "horse gram",
        "sweet & juicy vegetables, cucumber, potatoes (sweet),potato",
        "sweet & sour fruits, avocado, bananas, coconut,  figs (fresh) , grapes, lemons, oranges, papaya, plums, sour fruits, lemons, persimmon, plums (sour), oranges (sour), pineapples (sour), custard apple, sapota",
        "salt, mustard, garlic, ginger, tamarind, red chilli",
        "mutton, red meat, turkey  chicken, salmon,fish, dark meats, pork, sea food , shellfish, tuna, meat of goat",
        "buttermilk, cheese, sour cream, yogurt",
        "almond oil, corn oil, safflower oil, sesame oil",
        "tahini/sesame seeds, most nuts, pecans, peanuts, macadamia, cashews, walnuts",
      ],
    },
    VataPittaKapha: {
      eat: {
        "Rasa(taste)": [],
        "Cereals(grains)": ["Barley", "Oats"],
        "Pulses(legumes)": ["Dal", "Gram"],
        Vegetables: ["Cauliflower"],
        Fruits: ["Apple", "Pomegranate"],
        Spices: ["Basil", "Pepper"],
        "Non-Veg": ["Chicken", "Fish"],
        "Milk Products": ["Milk", "Ghee"],
        "Oil products": ["Olive Oil"],
        "Nuts/seeds": ["Almonds", "Raisins"],
      },

      occasionally: {
        "Rasa(taste)": [],
        "Cereals(grains)": [],
        "Pulses(legumes)": [],
        Vegetables: [],
        Fruits: [],
        Spices: [],
        "Non-Veg": [],
        "Milk products": [],
        "Oil products": [],
        "Nuts/seeds": [],
      },

      avoid: {
        "Rasa(taste)": [],
        "Cereals(grains)": ["Corn", "Muesli"],
        "Pulses(legumes)": ["Black Dal", "Beans"],
        Vegetables: ["Okra", "Onion"],
        Fruits: ["Banana", "Mango"],
        Spices: ["Mint"],
        "Non-Veg": ["Pork", "Beef"],
        "Milk Products": ["Cheese", "Yogurt"],
        "Oil products": ["Mustard Oil"],
        "Nuts/seeds": ["Cashews", "Pistachios"],
      },
    },
  };

  const data = pakritiData[prakritiType];

  const pakritidata = {
    VataPitta: {
      intensity: "Low to medium intensity exercises are preferred.",
      focus:
        "Focus on body- and mind-balancing asanas. Pranayama followed by meditation should be a primary focus.",
      caution:
        "Regularly assess your physical strength and avoid overly challenging activities like marathon running.",
      meditation: "Practice 15-30 minutes of meditation.",
      asanas: [
        "Sukshm Vyayama (Subtle Exercises), Mild Walking",
        "Surya Namaskara (Sun Salutation): 6-10 rounds",
        "Vrikshasana (Tree Pose)",
        "Veerabhadrasana II (Warrior Pose II)",
        "Marjariasana (Cat-Cow Pose)",
        "Tadasana (Mountain Pose)",
        "Trikonasana Series (Triangle Pose)",
        "Pavanmuktasana (Wind-Relieving Pose)",
      ],
      pranayama: [
        "Breath Awareness",
        "Agnisara Kriya",
        "Nadi Shodhana",
        "Bhramari Pranayama (Bee Breath)",
      ],
    },
    PittaKapha: {
      intensity:
        "Moderate to high intensity, adjusting exercise mode based on the season (lower intensity in summer).",
      focus: " Keep your body well-hydrated. ",
      caution: " Regular exercise is key to maintaining weight.",
      meditation: "Practice 15 minutes of meditation.",
      asanas: [
        "Sukshma Vyayama (Subtle Exercises)",
        "Surya Namaskara: 10-20 rounds ",
        "Jogging, Skipping (within personal capacity) ",
        "Ardha Kati Chakrasana (Half Waist Rotation Pose) ",
        "Ustrasana (Camel Pose)",
        "Paschimottanasana (Seated Forward Bend)",
        "Bhujangasana (Cobra Pose) ",
      ],
      pranayama: [
        "Kapalabhati (30 strokes * 3 sets) ",
        "Nadi Shodhana ",
        "Agnisara Kriya",
        "Agni Mudra Pranayama",
      ],
    },
    KaphaVata: {
      intensity:
        "Moderate to high intensity, followed by necessary relaxation.",
      focus: "Maintaining consistency is essential for managing weight. ",
      caution:
        " Running, jogging, and yoga are highly effective in overcoming laziness, a common trait for this prakriti.",
      meditation: "Practice 30 minutes of meditation.",
      asanas: [
        "Jogging, Skipping",
        "Tabata (10 minutes)",
        "Surya Namaskara: 15 rounds",
        "Veerabhadrasana I, II, III (Warrior Poses)",
        "Trikonasana (Triangle Pose)",
        "Parivritta Trikonasana (Revolved Triangle Pose)",
        "Lolasana (Pendant Pose) and Bhujangasana (Cobra Pose) as alternatives ",
        "Urdhva Mukha Svanasana (Upward-Facing Dog Pose) as an alternative",
      ],
      pranayama: ["Kapalabhati", "Bhastrika Pranayama", "Nadi Shodhana "],
    },
    PittaVata: {
      intensity: "Low to medium intensity exercises are preferred.",
      focus:
        "Focus on body- and mind-balancing asanas. Practices that maintain body heat are particularly beneficial",
      caution:
        "Regularly assess your physical strength and avoid overly challenging activities like marathon running.",
      meditation: "Practice 10-20 minutes of meditation.",
      asanas: [
        "Sukshma Vyayama (Subtle Exercises)",
        "Chandra Namaskara (Moon Salutation)",
        "Bhujangasana (Cobra Pose) ",
        "Trikonasana and Parivritta Trikonasana ",
        "Vrikshasana (Tree Pose)",
        "Pavanmuktasana (Wind-Relieving Pose)",
      ],
      pranayama: [
        "Breath Awareness",
        "Nadi Shodhana with Kumbhaka (Retention)",
        "Sheetali Pranayama (Cooling Breath)",
        "Vrikshasana (Tree Pose) ",
        "Pavanmuktasana (Wind-Relieving Pose) ",
      ],
    },
    KaphaPitta: {
      intensity:
        "Moderate to high intensity, adjusting exercise mode based on the season (lower intensity in summer).",
      focus: "Hydration: Keep your body well-hydrated. ",
      caution: "Consistency: Regular exercise is key to maintaining weight. ",
      meditation: "Practice 15-20 minutes of meditation.",
      asanas: [
        "Sukshma Vyayama (Subtle Exercises)",
        "Ashtanga Vinyasa (Beginner Flow)",
        "Cardio exercises (10 minutes)",
        "Chandrasana",
      ],
      pranayama: [
        "Kapalabhati",
        "Bhastrika",
        "Coherent Breathing",
        "Agni Mudra Pranayam",
        "Agnisara Kriya",
        "Bhramari Pranayam",
      ],
    },
    VataKapha: {
      intensity:
        "Moderate to high intensity, followed by necessary relaxation.",
      focus: "Maintaining consistency is essential for managing weight.",
      caution:
        " Running, jogging, and yoga are highly effective in overcoming laziness, a common trait for this prakriti.",
      meditation: "Practice 10-15 minutes of meditation. ",
      asanas: [
        "Sukshma Vyayama (Subtle Exercises)",
        "Surya Namaskara: 10 rounds ",
        "Vrikshasana (Tree Pose) ",
        "Veerabhadrasana II (Warrior Pose II)",
        "Parivritta Trikonasana (Revolved Triangle Pose) ",
        "Parighasana (Gate Pose)",
        "Pavanmuktasana (Wind-Relieving Pose)",
      ],
      pranayama: [
        "Nadi Shodhana ",
        "Agnisara Kriya",
        "Surya Bhedana (Right Nostril Breathing) ",
      ],
    },
  };
  const pakritiexercise = pakritidata[prakritiType];
  // console.log(prakritiType)
  // console.log('type: ', prakritiType);

  // console.log('data: ', pakritidata[prakritiType]);
  // console.log(pakritiexercise);
  const handlePrev = () => {
    setCurrentSlideinflamatory((prev) =>
      prev === 0 ? inflammatoryslides.length - 1 : prev - 1
    );
  };

  const handleNext = () => {
    setCurrentSlideinflamatory((prev) =>
      prev === inflammatoryslides.length - 1 ? 0 : prev + 1
    );
  };
  const handlePrev2 = () => {
    setCurrentSlideinflamatory2((prev) =>
      prev === 0 ? inflammatoryslides2.length - 1 : prev - 1
    );
  };

  const handleNext2 = () => {
    setCurrentSlideinflamatory2((prev) =>
      prev === inflammatoryslides2.length - 1 ? 0 : prev + 1
    );
  };
  const handlePrev3 = () => {
    setCurrentSlideinflamatory3((prev) =>
      prev === 0 ? inflammatoryslides3.length - 1 : prev - 1
    );
  };

  const handleNext3 = () => {
    setCurrentSlideinflamatory3((prev) =>
      prev === inflammatoryslides3.length - 1 ? 0 : prev + 1
    );
  };
  return (
    data && <div className={styles.container}>
      {/* {!enduser ? (<Header />) : null} */}
      <Header />
      <div className="m-0 pt-[15vh]">
        <div className={`${styles.heading} felx flex-row relative items-center pb-3`}>
          Result
          <div className="items-center">
            <div className="">
              {infla===1 ? (
                <PDFDownloadLink document={
                  <MyDocument1
                    vitals={vitals}
                    profileData={profileData}
                    email={email}
                    ayur={ayurResult}
                    prakriti={prakritiSpaced}
                    dietChart={data}
                    exercise={pakritiexercise}
                  />
                }
                fileName="AivotAyur_Health_Report.pdf"
                className={`${styles.downloadLink}`}
              >
              {({ loading }) => (
                <div className="flex flex-row justify-between items-center gap-5 px-5 py-2 font-normal text-2xl shadow-md hover:scale-95 transition-all duration-300 ease-in-out bg-white rounded-md uppercase">
                  <svg width="22" height="20" viewBox="0 0 20 20" fill="none" stroke="black" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.665 13.0204L10.665 0.938721M10.665 13.0204C9.81896 13.0204 8.23838 10.611 7.64453 10M10.665 13.0204C11.511 13.0204 13.0915 10.611 13.6854 10" stroke="#282626" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M20.3307 15.4368C20.3307 18.4354 19.7049 19.0613 16.7062 19.0613H4.62451C1.62583 19.0613 1 18.4354 1 15.4368" stroke="#282626" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                  <button className="">  {loading ? "Preparing..." : "Download"}</button>
                </div>
                
              )}
             
              </PDFDownloadLink>
              ) : (
                <PDFDownloadLink document={
                    <MyDocument1
                      vitals={vitals}
                      profileData={profileData}
                      email={email}
                      ayur={ayurResult}
                      prakriti={prakritiSpaced}
                      dietChart={data}
                      exercise={pakritiexercise}
                    />
                  }
                  fileName="AivotAyur_Health_Report.pdf"
                  className={`${styles.downloadLink}`}
                >
                {({ loading }) => (
                  
                <div className="flex flex-row justify-between items-center gap-5 px-5 py-2 font-normal text-2xl shadow-md hover:scale-95 transition-all duration-300 ease-in-out bg-white rounded-md uppercase">
                    <svg width="22" height="20" viewBox="0 0 20 20" fill="none" stroke="black" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.665 13.0204L10.665 0.938721M10.665 13.0204C9.81896 13.0204 8.23838 10.611 7.64453 10M10.665 13.0204C11.511 13.0204 13.0915 10.611 13.6854 10" stroke="#282626" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                      <path d="M20.3307 15.4368C20.3307 18.4354 19.7049 19.0613 16.7062 19.0613H4.62451C1.62583 19.0613 1 18.4354 1 15.4368" stroke="#282626" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                    <button className="">  {loading ? "Preparing..." : "Download"}
                    </button>
                  </div>
                )}
                </PDFDownloadLink>
              )}
            </div>
            {/* <button onClick={handleClick}>pdf</button> */}
          </div>
        </div>
        <div className={styles.profileCard}>
          <div className={styles.profileInfo}>
            {profileImage && (
              <div
                style={{
                  backgroundImage: `url(${profileImage})`,
                }}
                className="h-16 w-16 bg-cover bg-center bg-no-repeat rounded-full"
              >
                {/* <img src={screenshot} alt="User Profile" className={styles.profileImage} /> */}
              </div>
            )}
            <div className={styles.profile}>
              <div className={styles.profileh1}>{profile.name}</div>
              <div className={styles.profileh}>{profile.age} Years</div>
              <div className={styles.profileh}>{profile.gender}</div>
            </div>
          </div>
          <div className={styles.resdetails}>
            <div className="flex flex-col text-left">
              <div className={styles.profileh1}>Tested On</div>
              <div className={styles.profileh2}>{`${new Date().getDate()}/${new Date().getMonth() + 1}/${new Date().getFullYear()}`}</div>
            </div>
            <div className="flex flex-col text-left">
              <div className={styles.profileh1}>Email</div>
              <div className={styles.profileh2}>{email}</div>
            </div>
            <div className="flex flex-col text-left">
              <div className={styles.profileh1}>Weight</div>
              <div className={styles.profileh2}>{profile.weight} Kg</div>
            </div>
            <div className="flex flex-col text-left">
              <div className={styles.profileh1}>Height</div>
              <div className={styles.profileh2}>{profile.height} m</div>
            </div>
          </div>
        </div>
        <div className={styles.profileContainerMobile}>
      <div className={styles.profileHeaderMobile}>
      {profileImage && (
              <div
                style={{
                  backgroundImage: `url(${profileImage})`,
                }}
                className="h-16 w-16 bg-cover bg-center bg-no-repeat rounded-full"
              >
                {/* <img src={screenshot} alt="User Profile" className={styles.profileImage} /> */}
              </div>
            )}    
                <div className={styles.userInfoMobile}>
          <div className={styles.profile}>
                <div className={styles.profileh1}>{profile.name}</div>
                <div className={styles.profileh}>{profile.age} Years</div>
                <div className={styles.profileh} style={{marginTop:"-3%"}}>{profile.gender}</div>
              </div>
        
          <div className={styles.detailsItemMobile} >
          <div className={styles.profileh1}>Tested on</div>
          <div className={styles.profileh2}>{`${new Date().getDate()}/${
                  new Date().getMonth() + 1
                }/${new Date().getFullYear()}`}</div>
                </div>
        </div>
      </div>
      <div className={styles.resdetails}>
             
              <div className="flex flex-col text-left">
                <div className={styles.profileh1}>Email</div>
                <div className={styles.profileh2}>{email}</div>
              </div>
              <div className="flex flex-col text-left">
                <div className={styles.profileh1}>Weight</div>
                <div className={styles.profileh2}>{profile.weight} Kg</div>
              </div>
              <div className="flex flex-col text-left">
                <div className={styles.profileh1}>Height</div>
                <div className={styles.profileh2}>{profile.height} m</div>
              </div>
            </div>
    </div> 
        <div className={styles.table}>
          <div className={styles.tableHeader}>
            <div style={{ textAlign: "left", paddingLeft: "10%" }}>Vitals</div>
            <div>Values</div>
            <div>Units</div>
            <div>Reference Ranges</div>
          </div>
          <div
            style={{ borderTop: "1px solid rgba(204, 190, 190, 0.30)" }}
            className={styles.tableRow}
          >
            <div className={styles.thead}>Heart Rate</div>
            <div>{vitals.heart_rate}</div>
            <div>bpm</div>
            <div>60 - 100</div>
          </div>
          <div className={styles.tableRow}>
            <div className={styles.thead}>Stroke Volume</div>
            <div>{vitals.stroke_volume}</div>
            <div>ml</div>
            <div>60 - 100</div>
          </div>
          <div className={styles.tableRow}>
            <div className={styles.thead}>MAP</div>
            <div>{vitals.mean_atrial_pressure}</div>
            <div>mmHg</div>
            <div>70 - 100</div>
          </div>
          <div className={styles.tableRow}>
            <div className={styles.thead}>SBP</div>
            <div>{vitals.sbp}</div>
            <div>mmHg</div>
            <div>90 - 120</div>
          </div>
          <div className={styles.tableRow}>
            <div className={styles.thead}>DBP</div>
            <div>{vitals.dbp}</div>
            <div>mmHg</div>
            <div>60 - 80</div>
          </div>
          <div className={styles.tableRow}>
            <div className={styles.thead}>RMSSD</div>
            <div>{vitals.rmssd}</div>
            <div>ms</div>
            <div>80 - 200</div>
          </div>
          <div className={styles.tableRow}>
            <div className={styles.thead}>SDNN</div>
            <div>{vitals.sdnn}</div>
            <div>L/min</div>
            <div>80 - 200</div>
          </div>
          <div className={styles.tableRow}>
            <div className={styles.thead}>IBI</div>
            <div>{vitals.ibi}</div>
            <div>ms</div>
            <div>600 - 1200</div>
          </div>
          <div className={styles.tableRow}>
            <div className={styles.thead}>SD1</div>
            <div>{vitals.sd1}</div>
            <div>ms</div>
            <div>60 - 140</div>
          </div>
          <div className={styles.tableRow}>
            <div className={styles.thead}>SD2</div>
            <div>{vitals.sd2}</div>
            <div>ms</div>
            <div>70 - 180</div>
          </div>{" "}
          <div className={styles.tableRow}>
            <div className={styles.thead}>Fatty liver index</div>
            <div>{vitals.fattyLiverIndex}</div>
            <div>-</div>
            <div>&lt;40
            </div>
          </div>{" "}
          <div className={styles.tableRow}>
            <div className={styles.thead}>URIC ACID</div>
            <div>{vitals.uricAcid}</div>
            <div>mg/dl</div>
            <div>3.5 - 8.5</div>
          </div>{" "}
          <div className={styles.tableRow}>
            <div className={styles.thead}>STRESS INDEX</div>
            <div>{vitals.stress_index}</div>
            <div>-</div>
            <div>&lt;27</div>
          </div>{" "}
          <div
            style={{
              borderBottom: "2px solid rgba(204, 190, 190, 0.30)",
              boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
            }}
            className={styles.tableRow}
          >
            <div className={styles.thead}>OXYGEN SATURATION</div>
            <div>{vitals.oxygen_saturation}</div>
            <div>%</div>
            <div>95 - 100</div>
          </div>
          {/* Add other rows similarly */}
        </div>
        <div className="flex flex-col gap-1 px-[5%] py-2 pb-10 text-lg text-[#282626]">
          <span className="text-xl py-2 font-semibold">These parameters relate to heartbeat deviations and reflect autonomic nervous system activity:<span/></span>
          <span><b>SDNN:</b> Measures overall heartbeat variability (standard deviation of NN intervals).</span>
          <span><b>RMSSD:</b> Reflects short-term heartbeat changes (root mean square of successive differences).</span>
          <span><b>SD1:</b> Represents short-term heartbeat variability (Poincaré plot, perpendicular to identity line).</span>
          <span><b>SD2:</b> Indicates long-term heartbeat variability (Poincaré plot, along the identity line).</span>
          <span><b>IBI:</b> Time interval between consecutive heartbeats (in milliseconds).</span>
          <span><b>Stroke Volume (SV):</b> The amount of blood ejected by the heart in a single heartbeat, indicating heart strength.</span>
          <span className="text-xl pt-2 font-semibold">These metrics assess cardiac health and autonomic balance.</span>
        </div>
        <div className={styles.resulthead}>
          Ayurveda results based on Questionaire & AI
        </div>
        <div
          style={{
            marginLeft: "10%",
            marginRight: "10%",
            display: "grid", // Add this for grid layout
            gridTemplateColumns: "50% 50%",
            marginTop: "2%",
            padding: "1vw",
            // gap: "2vw",
          }}
          className={styles.tableHeader}
        >
          <div style={{ paddingLeft: "15vw", textAlign: "left", }}>Dosha</div>
        </div>
        <table className={styles.resultTable}>
          <tbody>
            <tr>
              <td style={{ paddingRight: 0, paddingLeft: "16vw" }} className="tracking-widest w-[50%] font-[550] text-[1rem] uppercase text-[#256D9D]">Vata</td>
              <td></td>
              <td className="text-[1rem] font-[550]">{parseFloat(resData['VataValue']).toFixed(2)} %</td>
            </tr>
            <tr>
              <td style={{ paddingRight: 0, paddingLeft: "16vw" }} className="tracking-widest w-[50%] font-[550] text-[1rem] uppercase text-[#DD472A]">Pitta</td>
              <td></td>
              <td className="text-[1rem] font-[550]">{parseFloat(resData['PitaValue']).toFixed(2)} %</td>
            </tr>
            <tr>
              <td style={{ paddingRight: 0, paddingLeft: "16vw" }} className="tracking-widest w-[50%] font-[550] text-[1rem] uppercase text-[#085946]">Kapha</td>
              <td></td>
              <td className="text-[1rem] font-[550]">{parseFloat(resData['KaphaValue']).toFixed(2)} %</td>
            </tr>
          </tbody>
        </table>
        {/* <div className="w-full h-fit px-[10%] text-left  "><b>Note:</b> These values are for individuals age 13 or more.</div> */}
        <div className={styles.resulthead}>Prakriti & Vikruti category</div>
        <div
          style={{
            marginLeft: "10%",
            marginRight: "10%",
            display: "grid", // Add this for grid layout
            gridTemplateColumns: "45% 55%",
            justifyContent: "space-between",
            marginTop: "2%",
            padding: "1% 3%",
          }}
          className={`${styles.tableHeader} uppercase`}
        >
          {" "}
          <div>Prakriti</div>
          <div>Vikruti</div>
        </div>
        <div className={`${styles.table3} mx-[10%]`}
          style={{
            marginLeft: "10%",
            marginRight: "10%",
            display: "grid", // Add this for grid layout
            gridTemplateColumns: "45% 55%",
            justifyContent: "space-between",
            marginTop: "2%",
            padding: "1% 3%",
          }}
        >
          <div className="text-center font-[550] text-[1rem] tracking-widest uppercase text">{prakritiSpaced}</div>
          <div className="text-center font-[550] text-[1rem] tracking-wider">Vata {resData['Vata']}, Pitta {resData['Pitta']}, Kapha {resData['Kapha']}</div>
        </div>
        
        <div className={styles.guidelines}>
          {infla===1 && (
            <>
              <h3 className="guidelinesh"
            style={{
              textAlign: "center",
              textTransform: "capitalize",
              fontWeight: "600",
              marginTop: "2%",
              color:"#000",
              fontSize:"1.3rem",
              lineHeight:"24px"
            }}
          >
            Based on your Prakriti and Vikruti analysis, your body is currently in a state of inflammation (AMA). To help reduce the inflammation, It is recommended to follow this 11-day diet plan.
          </h3>
          <p className={styles.subHeading}>
            From Day 1 to Day 5: Liquids Diet Along With Detox Water
          </p>
          <div className={styles.guidelinesContent}>
            {/* First Group */}
            <div className={styles.guidelinesGroup}>
              <div className={styles.time}>Morning (8 AM)</div>
              <div className={styles.details}>
                Soak 30 grams of split green gram for 3 hours. Add 180 ml of water
                and boil until the green gram becomes soft. Mash it, then add a
                pinch of salt, cumin (jeera), and pepper powder. Stir well and
                drink
              </div>
            </div>

                  <div className={styles.guidelinesGroup}>
                    <div className={styles.time}>Mid-Day (12 PM)</div>
                    <div className={styles.details}>
                      Take one cup of little millet (Kutki) and soak it. Add 6
                      cups of water and boil until the millet becomes soft. Mash
                      it, then add a little salt, cumin (jeera), and pepper.
                      stir well and drink.{" "}
                    </div>
                  </div>

                  {/* Second Group */}
                  <div className={styles.guidelinesGroup}>
                    <div className={styles.time}>Evening (5 PM)</div>
                    <div className={styles.details}>
                      Take one cup of semolina (Rava) and fry it in ghee. Add
                      six cups of water and a bit of jaggery, then stir well and
                      drink.{" "}
                    </div>
                  </div>

                  <div className={styles.guidelinesGroup}>
                    <div className={styles.time}> Night (8 PM)</div>
                    <div className={styles.details}>
                      Take one cup of Sabu-dana (tapioca pearls) and add six
                      cups of water. Boil it until it reaches a soupy
                      consistency. Add salt and red chili according to taste,
                      then boil it again before drinking.{" "}
                    </div>
                  </div>
                  <div className={styles.guidelinesGroup}>
                    <div className={styles.time}>
                      Alternative (Mid-Day / Night)
                    </div>
                    <div className={styles.details}>
                      Wheat Gruel (Dalia), start by rinsing 1/2 cup of broken
                      wheat and dry roasting it in a pan until it turns golden.
                      In a separate pot, bring 5 cups of water to a boil, then
                      add the roasted wheat and stir well. Let it simmer on low
                      heat for 15-20 minutes, stirring occasionally until the
                      wheat becomes soft and fully cooked. Once done, season
                      with a pinch of salt and mix everything together. Serve
                      the gruel.{" "}
                    </div>
                  </div>
                  <div className={styles.guidelinesGroup}>
                    <div className={styles.time}>
                      Detox water (Drink in place of Tea and coffee)
                    </div>
                    <div className={styles.details}>
                      To make this detox water, take three liters of water and
                      add two pinches of ginger powder, six pinches of coriander
                      powder, and two pinches of cumin (jeera) powder. Stir the
                      mixture well and bring it to a boil. Let it simmer for 5
                      minutes, then remove from heat. After cooling slightly,
                      store the detox water in a steel bottle and drink it
                      throughout the day to stay refreshed and hydrated.{" "}
                    </div>
                  </div>
                </div>
                <div className={styles.mobileSliderContainer}>
                  <div className={styles.mobileGuidelinesContent}>
                    <div className={styles.mobileGuidelinesGroup}>
                      <div className={styles.mobileTime}>
                        {inflammatoryslides[currentSlideinflamatory].time}
                      </div>
                      <div className={styles.mobileDetails}>
                        {inflammatoryslides[currentSlideinflamatory].details}
                      </div>
                    </div>
                  </div>

                  {/* Navigation Buttons */}
                  <div className={styles.mobileNavigationButtons}>
                    <button
                      onClick={handlePrev}
                      className={styles.mobileButton}
                    >
                      ◀ Prev
                    </button>
                    <button
                      onClick={handleNext}
                      className={styles.mobileButton}
                    >
                      Next ▶
                    </button>
                  </div>
                </div>
                <p className={styles.subHeading}>
                  For Day 6 to day 8: Semi liquid diet along with detox water
                </p>
                <div className={styles.guidelinesContent}>
                  {/* First Group */}
                  <div className={styles.guidelinesGroup}>
                    <div className={styles.time}>Morning (8 AM)</div>
                    <div className={styles.details}>
                      To make oil-free upma, dry roast semolina until golden. In
                      a heated pot, sauté mustard seeds, cumin, curry leaves,
                      green chilies, and onions until softened, then add
                      vegetables. Pour 2 cups of water, add turmeric and salt,
                      bring to a boil, and gradually stir in the roasted
                      semolina. Simmer for 3-4 minutes, garnish with coriander
                      and lemon juice, and serve hot.
                    </div>
                  </div>

                  <div className={styles.guidelinesGroup}>
                    <div className={styles.time}>Mid-Day (12 PM)</div>
                    <div className={styles.details}>
                      Rinse and soak 1/2 cup of rice and 1/2 cup of yellow moong
                      dal for 10-15 minutes. In a pot or pressure cooker, boil 4
                      cups of water and add rice, dal, turmeric, salt, and
                      optional cumin seeds. Add vegetables like carrots, peas,
                      or beans if desired, and cook until soft. Adjust the
                      consistency with water if needed and serve hot
                    </div>
                  </div>

                  {/* Second Group */}
                  <div className={styles.guidelinesGroup}>
                    <div className={styles.time}>Evening (5 PM)</div>
                    <div className={styles.details}>
                      Rinse 1 cup of rice and cook with 2 cups of water and salt
                      for about 15 minutes. For the dal, rinse 1 cup of lentils
                      and cook in 3 cups of water until soft. In a pan, sauté
                      cumin seeds, onions, ginger-garlic paste, tomatoes, and
                      green chilies, then add turmeric and salt. Combine with
                      the cooked lentils, simmer for 5-10 minutes, garnish with
                      coriander, and serve with rice
                    </div>
                  </div>
                </div>
                <div className={styles.mobileSliderContainer}>
                  <div className={styles.mobileGuidelinesContent}>
                    <div className={styles.mobileGuidelinesGroup}>
                      <div className={styles.mobileTime}>
                        {inflammatoryslides2[currentSlideinflamatory2].time}
                      </div>
                      <div className={styles.mobileDetails}>
                        {inflammatoryslides2[currentSlideinflamatory2].details}
                      </div>
                    </div>
                  </div>

                  {/* Navigation Buttons */}
                  <div className={styles.mobileNavigationButtons}>
                    <button
                      onClick={handlePrev2}
                      className={styles.mobileButton}
                    >
                      ◀ Prev
                    </button>
                    <button
                      onClick={handleNext2}
                      className={styles.mobileButton}
                    >
                      Next ▶
                    </button>
                  </div>
                </div>
                <p className={styles.subHeading}>
                  For Day 9 to Dat 11: Semi solid diet along with detox water
                </p>
                <div className={styles.guidelinesContent}>
                  {/* First Group */}
                  <div className={styles.guidelinesGroup}>
                    <div className={styles.time}>Morning (8 AM)</div>
                    <div className={styles.details}>
                      To make oil-free upma, dry roast semolina until golden. In
                      a heated pot, sauté mustard seeds, cumin, curry leaves,
                      green chilies, and onions until softened, then add
                      vegetables. Pour 2 cups of water, add turmeric and salt,
                      bring to a boil, and gradually stir in the roasted
                      semolina. Simmer for 3-4 minutes, garnish with coriander
                      and lemon juice, and serve hot.
                    </div>
                  </div>

                  <div className={styles.guidelinesGroup}>
                    <div className={styles.time}>Mid-Day (12 PM)</div>
                    <div className={styles.details}>
                      Roti and simple sabji (any vegetable expect brinjal and
                      potato)
                    </div>
                  </div>

                  {/* Second Group */}
                  <div className={styles.guidelinesGroup}>
                    <div className={styles.time}>Evening (5 PM)</div>
                    <div className={styles.details}>
                      Rinse 1 cup of rice and cook with 2 cups of water and salt
                      for about 15 minutes. For the dal, rinse 1 cup of lentils
                      and cook in 3 cups of water until soft. In a pan, sauté
                      cumin seeds, onions, ginger-garlic paste, tomatoes, and
                      green chilies, then add turmeric and salt. Combine with
                      the cooked lentils, simmer for 5-10 minutes, garnish with
                      coriander, and serve with rice
                    </div>
                  </div>
                </div>
                <div className={styles.mobileSliderContainer}>
                  <div className={styles.mobileGuidelinesContent}>
                    <div className={styles.mobileGuidelinesGroup}>
                      <div className={styles.mobileTime}>
                        {inflammatoryslides3[currentSlideinflamatory3].time}
                      </div>
                      <div className={styles.mobileDetails}>
                        {inflammatoryslides3[currentSlideinflamatory3].details}
                      </div>
                    </div>
                  </div>

                  {/* Navigation Buttons */}
                  <div className={styles.mobileNavigationButtons}>
                    <button
                      onClick={handlePrev3}
                      className={styles.mobileButton}
                    >
                      ◀ Prev
                    </button>
                    <button
                      onClick={handleNext3}
                      className={styles.mobileButton}
                    >
                      Next ▶
                    </button>
                  </div>
                </div>
              </>
            )}
            <div className={styles.dietrecomendation}>
              <div className={styles.diet}>DIET RECOMMENDATIONS</div>
              <p className={styles.recline2}>
  {infla === 1 
    ? "You can follow this diet after the initial 11 days to continue supporting better health."
    : "According to Prakriti this is your diet recommendation."
  }
</p>
              <div className={styles.guidelinesContent}>
                {/* First Group */}
                <div className={styles.guidelinesGroup}>
                  <div className={styles.time}>Breakfast (8:30-9 am) </div>
                  <div className={styles.details}>
                    {prakriti === "a" ? (
                      <>
                        Poha, methi paratha, Besan ka chhilka, idli and sambhar,
                        upma, paneer paratha
                      </>
                    ) : (
                      <>
                        Oats porridge, sprout salad, boiled eggs, fruit smoothie
                      </>
                    )}
                  </div>
                </div>

                <div className={styles.guidelinesGroup}>
                  <div className={styles.time}>Lunch (1-2 pm)</div>
                  <div className={styles.details}>
                    {prakriti === "a" ? (
                      <>
                        Jowar ki roti, Wheat flour ki roti, Nachni ki
                        roti,Bhindi sabji ,Chole sabji ,Rajma sabji ,Palak
                        paneer, Beans, Mixed vegetable curry, Chicken, Fish{" "}
                      </>
                    ) : (
                      <>
                        Wheat roti, jowar roti, rice roti, beans, capsicum,
                        cauliflower, soy chunks, Matki dal, fish{" "}
                      </>
                    )}{" "}
                  </div>
                </div>

                {/* Second Group */}
                <div className={styles.guidelinesGroup}>
                  <div className={styles.time}>Dinner (7-7:30 pm) </div>
                  <div className={styles.details}>
                    {prakriti === "a" ? (
                      <>
                        Millet Upma, Vegetable Khichdi, Daliya (Cracked Wheat),
                        Broccoli Soup, Sprouts (Moong), Nachni Soup (Ragi Soup)
                        with Vegetables
                      </>
                    ) : (
                      <>
                        Ragi ambali, cooked vegetables, beetroot -carrot tikkis,
                        rice and dal, hummus and rice roti,rice toor dal{" "}
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className={styles.mobileSliderContainer}>
                <div className={styles.mobileGuidelinesContent}>
                  <div className={styles.mobileGuidelinesGroup}>
                    <div className={styles.mobileTime}>
                      {slides[currentSlide].time}
                    </div>
                    <div className={styles.mobileDetails}>
                      {slides[currentSlide].content}
                    </div>
                  </div>
                </div>

                {/* Navigation Buttons */}
                <div className={styles.mobileNavigationButtons}>
                  <button
                    onClick={() => setCurrentSlide((prev) => prev - 1)}
                    disabled={currentSlide === 0}
                    className={styles.mobileButton}
                  >
                    ◀ Prev
                  </button>
                  <button
                    onClick={() => setCurrentSlide((prev) => prev + 1)}
                    disabled={currentSlide === slides.length - 1}
                    className={styles.mobileButton}
                  >
                    Next ▶
                  </button>
                </div>
              </div>
              {/* <div className={styles.detoxhead}>In place of Tea & Coffee take Detox Water</div> */}
              <div className={styles.detox}>
              <div className={styles.detoxhead}>In place of Tea & Coffee take Detox Water</div>

                <strong>Detox water:</strong>{" "}
                {prakriti === "a" ? (
                  <>
                    To make this detox water, take six liters of water and add two pinches of fennel seeds powder, six pinches of coriander powder, and two pinches of cumin (jeera) powder. Stir the mixture well and bring it to a boil. Let it simmer for 5 minutes, then remove from heat. After cooling slightly, store the detox water in a steel bottle and drink it throughout the day to stay refreshed and hydrated.
                  </>
                ) : (
                  <>
                    To make this detox water, take six liters of water and add
                    two pinches of cinnamon, six pinches of coriander powder,
                    and two pinches of cumin (jeera) powder. Stir the mixture
                    well and bring it to a boil. Let it simmer for 5 minutes,
                    then remove from heat. After cooling slightly, store the
                    detox water in a steel bottle and drink it throughout the
                    day to stay refreshed and hydrated.
                  </>
                )}
              </div>
            </div>
            <p className={styles.recline}>
              You can follow the food items listed below to support the above
              Prakriti diet.
            </p>
            {/* <div className={styles.selector}>
          {Object.keys(pakritiData).map((pakriti) => (
              <div 
              key={pakriti} 
              className={styles.pakritiName}
            >
            </div>
          ))}
        </div>

        {/* Food Items Table */}
<div className={styles.card} style={{ padding: "0%",marginTop:"2%" }}>
<table className={styles.pakrititable}>
            <thead>
              <tr>
                <th className="text-left">FOOD ITEMS</th>
                <th className="text-center">EAT</th>
                <th className="text-center">OCCASIONALLY<br/>
                (MONTHLY EAT 3 OR 4 TIMES).</th>
                <th className="text-center">AVOID</th>
              </tr>
            </thead>
            <tbody>
              {foodItems.map((item, index) => (
                <tr key={index}>
                  <td className={`${styles.food} text-left w-[18%]`}>{item}</td>
                  <td className="w-[25%] text-center">{data.eat[index]}</td>
                  <td className="text-center">{data.occasionally[index]}</td>
                  <td className="w-[25%] text-center">{data.avoid[index]}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className={styles.containerMobile}>
              <h2 className={styles.headingMobile}>Rasas (Taste)</h2>

              <div className={styles.tableMobile}>
                <div className={styles.rowMobilehead}>
                  <div className={styles.headerMobile}>EAT</div>
                  <div className={styles.headerMobile}><div>OCCASIONALLY</div><div style={{fontSize:"0.7rem"}}>(MONTHLY EAT 3 OR 4 TIMES).</div></div>
                  <div className={styles.headerMobile}>AVOID</div>
                </div>
                <div className={styles.rowMobile}>
                  <div className={styles.cellMobile}>{data.eat[0]}</div>
                  <div className={styles.cellMobile}>
                    {data.occasionally[0]}
                  </div>
                  <div className={styles.cellMobile}>{data.avoid[0]}</div>
                </div>
              </div>
              <h2 className={styles.headingMobile}>Cereals (grains)</h2>

              <div className={styles.tableMobile}>
                <div className={styles.rowMobilehead}>
                  <div className={styles.headerMobile}>EAT</div>
                  <div className={styles.headerMobile}><div>OCCASIONALLY</div><div style={{fontSize:"0.7rem"}}>(MONTHLY EAT 3 OR 4 TIMES).</div></div>
                  <div className={styles.headerMobile}>AVOID</div>
                </div>
                <div className={styles.rowMobile}>
                  <div className={styles.cellMobile}>{data.eat[1]}</div>
                  <div className={styles.cellMobile}>
                    {data.occasionally[1]}
                  </div>
                  <div className={styles.cellMobile}>{data.avoid[1]}</div>
                </div>
              </div>
              <h2 className={styles.headingMobile}>Pulses (legumes)</h2>

              <div className={styles.tableMobile}>
                <div className={styles.rowMobilehead}>
                  <div className={styles.headerMobile}>EAT</div>
                  <div className={styles.headerMobile}><div>OCCASIONALLY</div><div style={{fontSize:"0.7rem"}}>(MONTHLY EAT 3 OR 4 TIMES).</div></div>
                  <div className={styles.headerMobile}>AVOID</div>
                </div>
                <div className={styles.rowMobile}>
                  <div className={styles.cellMobile}>{data.eat[2]}</div>
                  <div className={styles.cellMobile}>
                    {data.occasionally[2]}
                  </div>
                  <div className={styles.cellMobile}>{data.avoid[2]}</div>
                </div>
              </div>
              <h2 className={styles.headingMobile}>Vegetables</h2>

              <div className={styles.tableMobile}>
                <div className={styles.rowMobilehead}>
                  <div className={styles.headerMobile}>EAT</div>
                  <div className={styles.headerMobile}><div>OCCASIONALLY</div><div style={{fontSize:"0.7rem"}}>(MONTHLY EAT 3 OR 4 TIMES).</div></div>
                  <div className={styles.headerMobile}>AVOID</div>
                </div>
                <div className={styles.rowMobile}>
                  <div className={styles.cellMobile}>{data.eat[3]}</div>
                  <div className={styles.cellMobile}>
                    {data.occasionally[3]}
                  </div>
                  <div className={styles.cellMobile}>{data.avoid[3]}</div>
                </div>
              </div>
              <h2 className={styles.headingMobile}>Fruits</h2>
              <div className={styles.tableMobile}>
                <div className={styles.rowMobilehead}>
                  <div className={styles.headerMobile}>EAT</div>
                  <div className={styles.headerMobile}><div>OCCASIONALLY</div><div style={{fontSize:"0.7rem"}}>(MONTHLY EAT 3 OR 4 TIMES).</div></div>
                  <div className={styles.headerMobile}>AVOID</div>
                </div>
                <div className={styles.rowMobile}>
                  <div className={styles.cellMobile}>{data.eat[4]}</div>
                  <div className={styles.cellMobile}>
                    {data.occasionally[4]}
                  </div>
                  <div className={styles.cellMobile}>{data.avoid[4]}</div>
                </div>
              </div>
              <h2 className={styles.headingMobile}>Spices</h2>
              <div className={styles.tableMobile}>
                <div className={styles.rowMobilehead}>
                  <div className={styles.headerMobile}>EAT</div>
                  <div className={styles.headerMobile}><div>OCCASIONALLY</div><div style={{fontSize:"0.7rem"}}>(MONTHLY EAT 3 OR 4 TIMES).</div></div>
                  <div className={styles.headerMobile}>AVOID</div>
                </div>
                <div className={styles.rowMobile}>
                  <div className={styles.cellMobile}>{data.eat[5]}</div>
                  <div className={styles.cellMobile}>
                    {data.occasionally[5]}
                  </div>
                  <div className={styles.cellMobile}>{data.avoid[5]}</div>
                </div>
              </div>
              <h2 className={styles.headingMobile}>Non-Veg</h2>
              <div className={styles.tableMobile}>
                <div className={styles.rowMobilehead}>
                  <div className={styles.headerMobile}>EAT</div>
                  <div className={styles.headerMobile}><div>OCCASIONALLY</div><div style={{fontSize:"0.7rem"}}>(MONTHLY EAT 3 OR 4 TIMES).</div></div>
                  <div className={styles.headerMobile}>AVOID</div>
                </div>
                <div className={styles.rowMobile}>
                  <div className={styles.cellMobile}>{data.eat[6]}</div>
                  <div className={styles.cellMobile}>
                    {data.occasionally[6]}
                  </div>
                  <div className={styles.cellMobile}>{data.avoid[6]}</div>
                </div>
              </div>
              <h2 className={styles.headingMobile}>Milk Products</h2>
              <div className={styles.tableMobile}>
                <div className={styles.rowMobilehead}>
                  <div className={styles.headerMobile}>EAT</div>
                  <div className={styles.headerMobile}><div>OCCASIONALLY</div><div style={{fontSize:"0.7rem"}}>(MONTHLY EAT 3 OR 4 TIMES).</div></div>
                  <div className={styles.headerMobile}>AVOID</div>
                </div>
                <div className={styles.rowMobile}>
                  <div className={styles.cellMobile}>{data.eat[7]}</div>
                  <div className={styles.cellMobile}>
                    {data.occasionally[7]}
                  </div>
                  <div className={styles.cellMobile}>{data.avoid[7]}</div>
                </div>
              </div>
              <h2 className={styles.headingMobile}>Oil Products</h2>
              <div className={styles.tableMobile}>
                <div className={styles.rowMobilehead}>
                  <div className={styles.headerMobile}>EAT</div>
                  <div className={styles.headerMobile}><div>OCCASIONALLY</div><div style={{fontSize:"0.7rem"}}>(MONTHLY EAT 3 OR 4 TIMES).</div></div>
                  <div className={styles.headerMobile}>AVOID</div>
                </div>
                <div className={styles.rowMobile}>
                  <div className={styles.cellMobile}>{data.eat[8]}</div>
                  <div className={styles.cellMobile}>
                    {data.occasionally[8]}
                  </div>
                  <div className={styles.cellMobile}>{data.avoid[8]}</div>
                </div>
              </div>
              <h2 className={styles.headingMobile}>Nuts/Seeds</h2>
              <div className={styles.tableMobile}>
                <div className={styles.rowMobilehead}>
                  <div className={styles.headerMobile}>EAT</div>
                  <div className={styles.headerMobile}><div>OCCASIONALLY</div><div style={{fontSize:"0.7rem"}}>(MONTHLY EAT 3 OR 4 TIMES).</div></div>
                  <div className={styles.headerMobile}>AVOID</div>
                </div>
                <div className={styles.rowMobile}>
                  <div className={styles.cellMobile}>{data.eat[9]}</div>
                  <div className={styles.cellMobile}>
                    {data.occasionally[9]}
                  </div>
                  <div className={styles.cellMobile}>{data.avoid[9]}</div>
                </div>
              </div>
            </div>
        <div><strong>Note: </strong>The amount of food depends on your regular dietary intake.</div>
        <div className={`${styles.lasthead}`}>Suggested Physical Activities, Asanas and Pranayam for Balance and Wellness</div>
          {/* Dynamic Card */}
          <div style={{marginTop:"2%", padding: "3%"}} className={styles.card}>
          {/* Safeguard: Ensure pakriti has data before accessing properties */}
          {pakritiexercise.intensity && (
            <p className="pb-2">
              <b>Exercise Intensity:</b> {pakritiexercise.intensity}
            </p>
          )}
          {pakritiexercise.focus && (
            <p className="pb-2">
              <b>Focus:</b> {pakritiexercise.focus}
            </p>
          )}
          {pakritiexercise.caution && (
            <p className="pb-2">
              <b>Physical Caution:</b> {pakritiexercise.caution}
            </p>
          )}
          
          <hr className="py-2"></hr>
          <div className="text-black font-bold py-2">Recommended Asanas</div>
          {pakritiexercise.asanas && pakritiexercise.asanas.length > 0 && (
            <div className="">
              {pakritiexercise.asanas.map((asana, index) => (
                <div key={index} className="">{asana}</div>
              ))}
            </div>
          )}
          <div className="text-black font-bold py-2 pt-5">Recommended Pranayamas</div>
          {pakritiexercise.pranayama && pakritiexercise.pranayama.length > 0 && (
            <div className="">
              {pakritiexercise.pranayama.map((pranayama, index) => (
                <div key={index} className="">{pranayama}</div>
              ))}
            </div>
          )}
          {pakritiexercise.meditation && (
            <p className="pb-2 pt-5">
              <b>Meditation:</b> {pakritiexercise.meditation}
            </p>
          )}
          
        </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ResultPage;
