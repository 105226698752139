import React, { createContext, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [authData, setAuthData] = useState({
        token: localStorage.getItem("authToken"),
    });
    const navigate = useNavigate();
    const storeAuthDataInLS = (serverToken,email) => {
        localStorage.setItem("authToken", serverToken);
        localStorage.setItem("email", email);
        setAuthData({
            token: serverToken,
        });
        // navigate("/dashboard");
    };

    const signOutUser = () => {
        localStorage.removeItem("authToken");
        localStorage.removeItem("email");
       
        setAuthData({
            token: null,
        });
        navigate("/");
    };

    const isLoggedIn = !!authData.token;

    return (
        <AuthContext.Provider
            value={{
                ...authData,
                isLoggedIn,
                storeAuthDataInLS,
                signOutUser,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const authContextValue = useContext(AuthContext);
    if (!authContextValue) {
        throw new Error("useAuth must be used within an AuthProvider");
    }
    return authContextValue;
};
