import React, { useState, useEffect } from "react";
import "./ForgotPassword.css";
import { useNavigate } from "react-router-dom";
import googlelogo from "../../assets/google.svg";
import axios from "axios";
import logo from "../../assets/logoayur.png";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [currentSlide, setCurrentSlide] = useState(0);
  const insights = [
    {
      description:
        "Unlock lifestyle and diet guidance tailored to your unique body type and wellness goals.",
    },
    {
      description:
        "Experience a unique blend of Ayurveda and AI to reveal your body's natural balance.",
    },
    {
      description:
        "Understand your Dosha and receive recommendations crafted for your mind-body balance.",
    },
  ];
  // Automatically change slides every 3 seconds
  useEffect(() => {
    const slideInterval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % insights.length);
    }, 3000);

    return () => clearInterval(slideInterval);
  }, [insights.length]);

  const handleSlideClick = (index) => {
    setCurrentSlide(index);
  };
  const handleSendOTP = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await axios.post(
        "https://aivotayur.com/api/forgot-password",
        { email }
      );

      toast.success(response.data.message, {
        position: "top-center",
        autoClose: 1000,
      });
      setTimeout(() => {
        navigate("/change-password", { state: { email } });
      }, 1000);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        // Error 404 for email not registered
        // alert("Email not registered. Please check your email or sign up.");
        toast.error(
          "Email not registered. Please check your email or sign up.",
          {
            position: "top-center",
            autoClose: 2000,
          }
        );
      } else if (error.response && error.response.status === 500) {
        // alert("OTP sending failed. Please try again later.");
        toast.error("OTP sending failed. Please try again later.", {
          position: "top-center",
          autoClose: 2000,
        });
      } else {
        // Generic error
        // alert("Failed to send OTP. Please try again.");
        toast.error("Failed to send OTP. Please try again..", {
          position: "top-center",
          autoClose: 2000,
        });
      }
    }
  };

  return (
    <div className="ForgotContainer">
      <div className="ayur-forgot-container">
        <div className="form-section">
          <div className="welcome-text"> Welcome to Aivotayur</div>
          {/* <div className="Getting-Started">
            <p className="Getting-Started-text">Getting started is easy</p>
            <div className="social-signin">
              <div className="googlelayout">
                <div className="googlewrap">
                  <img src={googlelogo} alt="Logo" className="googlelogo" />
                  <p className="googletext flex items-center">Google</p>
                </div>
              </div>
            </div>
          </div>
          <p className="my-4">Or continue with</p> */}
          <form className="ayur-forgot-form" onSubmit={handleSendOTP}>
            <div className="forgot-input-group">
              <label> Forgot Password</label>
              <p
                style={{
                  color: "var(--Neutral-700, #4B4B4B)",
                  fontSize: "0.7em",
                }}
              >
                Enter your email and we’ll send you a link and OTP to reset your
                password
              </p>
              <input
                type="email"
                placeholder="Ram@gmail.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>

            <button type="submit" className="send-button " disabled={isLoading}>
              {isLoading ? "Sending OTP..." : "SEND OTP"}
            </button>
          </form>
        </div>
      </div>
      <div className="outerInsightmob">
        <div className="logo">
          <img src={logo} />
        </div>
        <div className="Forgot-Insights-container">
          <h2 style={{ color: "var(--Gray-0, #FFF)" }}>
            Discover the Power of Ayurvedic Insights
          </h2>
          <p style={{ color: "var(--Gray-0, #FFF)" }}>
            {insights[currentSlide].description}
          </p>

          <div className="slide-dots">
            {insights.map((_, index) => (
              <span
                key={index}
                className={`dot ${index === currentSlide ? "active" : ""}`}
                onClick={() => handleSlideClick(index)}
              />
            ))}
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
export default ForgotPassword;
