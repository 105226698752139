import React from 'react'
import { PDFViewer } from '@react-pdf/renderer'
import MyDocument1 from './ReactPdf/ReactPdf'
import MyDocument2 from './ReactPdf/ReactPdfNonI'
import { useLocation } from 'react-router-dom'

function Pdf() {
  const location = useLocation();
  const state = location.state;
  console.log("state",state)
  return (
    <div style={{
        height:"100vh",
        width:"100vw",
        margin:0,
    }}>
      <PDFViewer width="100%" height="100%">
        <MyDocument2
          vitals={state.vitals}
          profileData={state.profileData}
          email={state.email}
          ayur={state.ayurResult}
          prakriti={state.prakritiSpaced}
          dietChart={state.data}
          exercise={state.pakritiexercise}
        />
      </PDFViewer>
    </div>
  )
}

export default Pdf