import React, { useState, useEffect } from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import boardingImg from "../../assets/Learn-more-aurveda-pic.png";
import Filter from "../../assets/Funnel.svg";
import DownArrow from "../../assets/dropdown.svg";
import Download from "../../assets/Download.png";
import "./ReportsPage.css";
import { useNavigate } from "react-router-dom";
import CopyIcon from "../../assets/copy_icon.svg";

function ReportsPage() {
  const [email, setEmail] = useState(null);
  const [resultData, setResultData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const emailAvailable = localStorage.getItem("email");
    setEmail(emailAvailable);
    if (emailAvailable) {
      fetchResultDetails(emailAvailable);
    }
  }, []);
  const copyToClipboard = () => {
    const content = document.getElementById("contentToCopy").innerText;
    navigator.clipboard.writeText(content).then(
      () => {
        alert("Content copied to clipboard!");
      },
      (err) => {
        console.error("Failed to copy: ", err);
      }
    );
  };
  const toResult = async (params) => {
    navigate("/result", {
      state: {
        iterveResult: params.vitals || {},
        ayurResult: params.aivotayur,
        profileData: {
          name: params.fullName,
          age: params.age,
          weight: params.weight,
          height: params.height,
          gender: params.sex,
        },
        infla: params.inflamation,
        capturedImage: "",
        token: "",
      },
    });
  };

  const fetchResultDetails = async (userEmail) => {
    try {
      const response = await fetch(
        `https://aivotayur.com/api/resultDetails?email=${userEmail}`
      );
      if (!response.ok) {
        const errorResponse = await response.json();
        setError(errorResponse.message || "Failed to fetch data");
        setLoading(false);
        return;
      }
      const data = await response.json();
      console.log(data);
      setResultData(data.resultData);
      setLoading(false);
    } catch (err) {
      setError("An error occurred while fetching data");
      setLoading(false);
    }
  };

  const headers = ["Report ID", "Name", "Date", "Time", "Action"];

  const tableData = [
    {
      id: "#ABC1234",
      name: "Ram Shah",
      date: "11th October, 2024",
      time: "4:56 PM",
      action: "Download",
    },
  ];

  return (
    <div className="Reports-page-container">
      <Header />
      <div className="Reports-page-boarding">
        <img src={boardingImg} className="Reports-boarding-img" />
        <div className="Reports-page-text">Reports Page </div>
      </div>
      <div className="Reports-Wrap">
        <div className="Reports-page-text2">
          Access your Ayurveda-based health assessment reports below to review
          insights from your previous submissions. These reports offer a
          comprehensive analysis of your well-being, body constitution
          (Prakriti), and current state (Vikruti), along with personalized
          recommendations to guide you toward a balanced and healthier
          lifestyle.
        </div>
        <div className="FDT-wrap">
          <div className="Filter-wrap">
            <div className="Filter-text">Filters</div>
            <img src={Filter} alt="Funnel" className="Funnel" />
          </div>
          <div className="Date-wrap">
            <div className="Date-text"> Date</div>
            <img src={DownArrow} alt="Dropdown" className="Dropdown" />
          </div>
          <div className="Time-wrap">
            <div className="Time-text">Time</div>
            <img src={DownArrow} alt="Dropdown" className="Dropdown" />
          </div>
        </div>
        <div className="Report_wrap">
          <div className="table-container">
            <table className="custom-table">
              <thead>
                <tr>
                  {headers.map((header, index) => (
                    <th key={index}>{header}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {resultData.map((row, index) => (
                  <tr
                    key={index}
                    style={{
                      marginBottom: "1vw",
                      boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                    }}
                    className="rounded-b-lg"
                  >
                    <td>{row._id.slice(0, 10)}</td>
                    <td>{row.fullName}</td>
                    <td>
                      {new Date(row.createdAt).toLocaleDateString("en-GB", {
                        day: "numeric",
                        month: "long",
                        year: "numeric",
                      })}
                    </td>
                    <td>
                      {new Date(row.createdAt).toLocaleTimeString("en-US", {
                        hour: "2-digit",
                        minute: "2-digit",
                        hour12: true,
                      })}
                    </td>
                    <td>
                      <div className="Download-wrap">
                        <div
                          className="download-text cursor-pointer hover:text-blue-700 hover:underline"
                          onClick={() => toResult(row)}
                        >
                          View Report
                        </div>
                       
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="mobile-view">
    {resultData.map((row, index) => (
      <div key={index} className="mobile-card">
        <div className="mobile-card-header">
          <div>Report ID</div>
          <div className="id_icon" id="contentToCopy">
            {row._id.slice(0, 10)}
            <img
              src={CopyIcon} // Replace with your copy icon if needed
              className="copy-icon"
              alt="Copy"
              onClick={copyToClipboard}
            />
          </div>
        </div>
        <div className="mobile-body">        <div className="mobile-card-body">
          <p>{row.fullName}</p>
          <p>
            {new Date(row.createdAt).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "long",
              year: "numeric",
            })}
          </p>
          <p>
            {new Date(row.createdAt).toLocaleTimeString("en-US", {
              hour: "2-digit",
              minute: "2-digit",
              hour12: true,
            })}
          </p>
        </div>
        <div className="mobile-card-footer">
          <button
            className="download-text"
            onClick={() => toResult(row)}
          >
            View Report
          </button>
          <button
    className="eye-button"
    onClick={() => toResult(row)}
  >
    👁
  </button>
          {/* <img
            src={Download}
            className="Download"
            alt=""
            onClick={() => toResult(row)}
          /> */}
        </div></div>

      </div>
    ))}
  </div>
      <Footer />
    </div>
  );
}

export default ReportsPage;
