import React, { useState, useEffect } from "react";
import "./ChangePassword.css";
import googlelogo from "../../assets/google.svg";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import logo from "../../assets/logoayur.png";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import eyeopen from "../../assets/icon_eye_empty.svg"

function ChangePassword() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = useState(false);
  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };
  const toggleConfirmPasswordVisibility = () => {
    setIsConfirmPasswordVisible(!isConfirmPasswordVisible);
  };
  const email = location.state?.email;
  const insights = [
    {
      description:
        "Unlock lifestyle and diet guidance tailored to your unique body type and wellness goals.",
    },
    {
      description:
        "Experience a unique blend of Ayurveda and AI to reveal your body's natural balance.",
    },
    {
      description:
        "Understand your Dosha and receive recommendations crafted for your mind-body balance.",
    },
  ];
  // Automatically change slides every 3 seconds
  useEffect(() => {
    const slideInterval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % insights.length);
    }, 3000);

    return () => clearInterval(slideInterval);
  }, [insights.length]);

  const handleSlideClick = (index) => {
    setCurrentSlide(index);
  };
  const handlePasswordReset = async (e) => {
    e.preventDefault();
    if (!otp || !newPassword || !confirmPassword) {
      // alert("Please fill out all fields.");
      toast.error("Please fill out all fields.", {
        position: "top-center",
        autoClose: 2000
      });
      return;
    }
    if (newPassword !== confirmPassword) {
      // alert("Passwords do not match!");
      toast.error("Passwords do not match!", {
        position: "top-center",
        autoClose: 2000
      });
      return;
    }
    try {
      const response = await axios.post(
        "https://aivotayur.com/api/reset-password",
        { email, otp, newPassword }
      );
      if (response.status === 200) {
        // Check for 200 status
        //  console.log("entering the function");
        //  console.log("Password reset successful:", response.data.message);
        alert(response.data.message);
        navigate("/login", { replace: true }); // Navigate to login page upon success
      }
    } catch (error) {
      // alert("Failed to reset password. Please try again.");
      toast.error("Failed to reset password. Please try again.", {
        position: "top-center",
        autoClose: 2000
      });
    }
  };

  return (
    <div className="ChangeContainer">
      <div className="ayur-change-container">
        <div className="form-section">
          <div className="welcome-text"> Welcome to Aivotayur</div>
          {/* <div className="Getting-Started">
            <p className="Getting-Started-text">Getting started is easy</p>
            <div className="social-signin">
              <div className="googlelayout">
                <div className="googlewrap">
                  <img src={googlelogo} alt="Logo" className="googlelogo" />
                  <p className="googletext flex items-center">Google</p>
                </div>
              </div>
            </div>
          </div>
          <p className="my-4">Or continue with</p> */}
          <form className="ayur-change-form" onSubmit={handlePasswordReset}>
            <div className="change-input-group">
              <label> Change Password</label>
              <div className="input-box">
              <div className="relative">
                <input
                  type={isPasswordVisible ? "text" : "password"}
                  placeholder="Enter New Password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  required
                />
                 <span
                    onClick={togglePasswordVisibility}
                    className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
                  >
                    {isPasswordVisible ? (
                      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="21" viewBox="0 0 25 21" fill="none">
                        <path d="M1.12 1.64539L23.12 19.6046" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M10.2866 9.30518C9.90629 9.65688 9.67554 10.119 9.67554 10.6251C9.67554 11.7271 10.7699 12.6205 12.12 12.6205C12.7399 12.6205 13.3059 12.4322 13.7369 12.1217" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M6.45114 6.19604C4.39557 7.37195 2.68314 9.04726 1.12 10.6249C3.42824 13.6091 7.57541 16.6113 12.12 16.6113C14.0143 16.6113 15.8397 16.0897 17.4915 15.2651" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M12.12 4.63855C17.0192 4.63855 20.3107 7.78959 23.12 10.6249C22.7307 11.1281 22.2893 11.6319 21.8024 12.1215" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                    ) : (
                      // <svg
                      //   xmlns="http://www.w3.org/2000/svg"
                      //   width="24"
                      //   height="24"
                      //   viewBox="0 0 24 24"
                      //   fill="none"
                      //   stroke="currentColor"
                      //   strokeWidth="2"
                      //   strokeLinecap="round"
                      //   strokeLinejoin="round"
                      // >
                      //   <path d="M12 6c-4.41 0-8 3.59-8 8s3.59 8 8 8 8-3.59 8-8-3.59-8-8-8zM12 2c5.52 0 10 4.48 10 10s-4.48 10-10 10S2 17.52 2 12s4.48-10 10-10z" />
                      // </svg>
                      <img src= {eyeopen} className="eyeopen"/>
                    )}
                  </span>
                </div>
                <div className="relative">
                <input
                  type={isConfirmPasswordVisible ? "text" : "password"}
                  placeholder="Confirm New Password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                />
                <span
                    onClick={toggleConfirmPasswordVisibility}
                    className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
                  >
                    {isConfirmPasswordVisible ? (
                      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="21" viewBox="0 0 25 21" fill="none">
                        <path d="M1.12 1.64539L23.12 19.6046" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M10.2866 9.30518C9.90629 9.65688 9.67554 10.119 9.67554 10.6251C9.67554 11.7271 10.7699 12.6205 12.12 12.6205C12.7399 12.6205 13.3059 12.4322 13.7369 12.1217" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M6.45114 6.19604C4.39557 7.37195 2.68314 9.04726 1.12 10.6249C3.42824 13.6091 7.57541 16.6113 12.12 16.6113C14.0143 16.6113 15.8397 16.0897 17.4915 15.2651" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M12.12 4.63855C17.0192 4.63855 20.3107 7.78959 23.12 10.6249C22.7307 11.1281 22.2893 11.6319 21.8024 12.1215" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                    ) : (
                      // <svg
                      //   xmlns="http://www.w3.org/2000/svg"
                      //   width="24"
                      //   height="24"
                      //   viewBox="0 0 24 24"
                      //   fill="none"
                      //   stroke="currentColor"
                      //   strokeWidth="2"
                      //   strokeLinecap="round"
                      //   strokeLinejoin="round"
                      // >
                      //   <path d="M12 6c-4.41 0-8 3.59-8 8s3.59 8 8 8 8-3.59 8-8-3.59-8-8-8zM12 2c5.52 0 10 4.48 10 10s-4.48 10-10 10S2 17.52 2 12s4.48-10 10-10z" />
                      // </svg>
                      <img src= {eyeopen} className="eyeopen"/>
                    )}
                  </span>
                </div>
                <input
                  type="text"
                  placeholder="Enter OTP"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                  required
                />
              </div>
            </div>
            <p
              style={{
                color: "var(--Neutral-700, #4B4B4B)",
                fontSize: "0.8em",
              }}
              className="mb-2"
            >
              (Enter the OTP sent on your registered email ID)
            </p>

            <button type="submit" className="Submit-button ">
              SUBMIT
            </button>
          </form>
        </div>
      </div>
      <div className="outerInsightmob">
      <div className="logo"><img src={logo}/></div>
      <div className="Change-Insights-container">
        <h2 style={{ color: "var(--Gray-0, #FFF)" }}>
          Discover the Power of Ayurvedic Insights
        </h2>
        <p style={{ color: "var(--Gray-0, #FFF)" }}>
          {insights[currentSlide].description}
        </p>

        <div className="slide-dots">
          {insights.map((_, index) => (
            <span
              key={index}
              className={`dot ${index === currentSlide ? "active" : ""}`}
              onClick={() => handleSlideClick(index)}
            />
          ))}
        </div>
      </div>
      </div>
      <ToastContainer/>
    </div>
  );
}
export default ChangePassword;
