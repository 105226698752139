import React from "react";
import {
  PDFDownloadLink,
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import AivotBG from "../../assets/Aivotlogobackground.jpg";
import Logo from "../../assets/Care_Vedic.png";

// Define styles
const styles = StyleSheet.create({
  page: {
    // position:"relative",
    // flexDirection:"row",
  },
  backgroundImage: {
    position: "absolute",
    top: -270,
    left: -380,
    display: "block",
    width: "230%",
    height: "175%",
    opacity: 0.25, // Watermark effect
    zIndex: 1,
  },
  topLogo: {
    width: "100px",
    // height: "33px",
    margin: "20px 0",
  },
  bottomText: {
    fontSize: "1.9vw",
    width: "88vw",
    textAlign: "center",
    marginBottom: "2vw",
    lineHeight: "2vw",
    marginTop: "auto",
  },
  container: {
    display: "flex",
    padding: "0vw 6vw",
    flexDirection: "column",
    justifyContent: "start",
    alignItems: "center",
    position: "relative",
  },
  header: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "1vw",
    borderBottomColor: "#ccc",
  },
  Logo: {
    height: "50%",
  },
  userDetailsContainer: {
    width: "100%",
    justifyContent: "space-between",
  },
  userDetailsRow: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    borderBottom: "1px solid black",
    borderLeft: "1px solid black",
  },
  labelText: {
    fontSize: 12,
    fontWeight: "bold",
    textAlign: "center",
    width: "20%",
    padding: "1vw 0",
    borderRight: "1px solid black",
  },
  valueText: {
    fontSize: 10,
    textAlign: "center",
    width: "20%",
    padding: "1vw 0",
    borderRight: "1px solid black",
  },
  IText: {
    color: "#000",
    fontSize: "2.3vw",
    fontWeight: "black",
    // marginBottom:"1vw",
  },

  sectionHeader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 15,
    fontWeight: "bold",
    marginVertical: "3vw",
    textAlign: "center",
    backgroundColor: "#FFF",
    // color: "#4B4B4B",
    padding: "1vw 0",
    width: "88vw",
    borderRadius: "15%",
  },
  table: {
    display: "table",
    width: "100%",
    marginBottom: 10,
  },
  tableRowheading: {
    flexDirection: "row",
    fontSize: "1.75vw",
    backgroundColor: "#F3F1F1",
    borderBottom: "1px solid  #085946",
    marginBottom: "20px",
    padding: "0.5vw 3vw",
  },
  tableRowHRV:{
    flexDirection: "row",
    fontSize: "2.2vw",
    padding: "0.2vw 2.6vw",
    borderBottom: "1px solid #e3dcdc",
    width: "88vw",

  },
  tableRow: {
    flexDirection: "row",
    fontSize: "2.5vw",
    padding: "0.5vw 3vw",
    borderBottom: "1px solid #e3dcdc",
    width: "88vw",
  },
  tableRowDiet:{
    flexDirection: "row",
    fontSize: "2.5vw",
    padding: "2vw 3.5vw",
    borderBottom: "1px solid #e3dcdc",
    width: "88vw",
  },
  rowscontainer: {
    border: "2px solid #e3dcdc",
    borderTopWidth: "1px",
    backgroundColor: "rgba(255, 255, 255, 0.80)",
    borderBottom: "2px solid grey",
    borderRadius: "0.5vw",
  },
  tableCol1: {
    width: "25%",
    padding: "1vw",
    width: "40%",
    textAlign: "left",
    fontSize: "10px",
  },
  tableCol2: {
    width: "25%",
    padding: "1vw",
    width: "15%",
    fontSize: "10px",
    textAlign: "center",
  },
  tableCol3: {
    width: "25%",
    padding: "1vw",
    width: "15%",
    fontSize: "10px",
    textAlign: "center",
  },
  tableCol4: {
    width: "25%",
    padding: "1vw",
    width: "30%",
    fontSize: "10px",
    textAlign: "center",
  },
  tableCol1H: {
    width: "25%",
    padding: "1vw",
    width: "40%",
    textAlign: "left",
  },
  tableCol2H: {
    width: "25%",
    padding: "1vw",
    width: "15%",
    textAlign: "center",
  },
  tableCol3H: {
    width: "25%",
    padding: "1vw",
    width: "15%",
    textAlign: "center",
  },
  tableCol4H: {
    width: "25%",
    padding: "1vw",
    width: "30%",
    textAlign: "center",
  },
  itervetextheading: {
    lineHeight: "3.5vw",
    fontSize: "2.5vw",
    textAlign: "left",
    color: "#000",
    fontWeight: "bold",
  },
  itervetext: {
    lineHeight: "2.5vw",
    fontSize: "2vw",
    textAlign: "left",
    color: "#000",
    fontWeight: "600",
    width: "88vw",
  },
  itervetextContainer: {
    display: "flex",
    justifyContent: "flex-start",
    width: "100%",
  },
  tableColWide1: {
    width: "44vw",
    padding: "1vw 0",
    paddingLeft: "15vw",
    fontSize: "2vw",
    textAlign: "left",
    alignItems: "center",
  },
  tableColWide2: {
    width: "44vw",
    padding: "1vw 0",
    fontSize: "2vw",
    textAlign: "center",
    alignItems: "center",
  },
  tableColWide1H: {
    width: "44vw",
    fontSize: "2vw",
    padding: 5,
    textAlign: "center",
    marginBottom: "5px",
    borderBottom: "2px solid grey",
    backgroundColor: "#F3F1F1",
  },
  tableColWide2H: {
    width: "44vw",
    fontSize: "2vw",
    padding: 5,
    marginBottom: "5px",
    borderBottom: "2px solid grey",
    backgroundColor: "#F3F1F1",
  },
  vatatext: {
    fontSize: "2vw",
    textAlign: "left",
    color: "#000",
    lineHeight: "3.2vw",
    fontWeight: "600",
    width: "88vw",
  },
  vataHtext: {
    fontSize: "2.8vw",
    lineHeight: "3.2vw",
    color: "#085946",
    fontWeight:"800",
  },

  contentcontainer: {
    display: "flex",
    flexDirection: "column",
    padding: "0vw 5vw ",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
  Logo1: {
    width: "92.561px",
    height: "33px",
    marginTop: "12px",
  },
  sectionHeadertext: {
    fontSize: 15,
    marginVertical: 10,
    textAlign: "center",
    backgroundColor: "#FFF",
    width: "100%",
    color: "#4B4B4B",
  },
  tableColtext1: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "30%",
    padding: 5,
    fontSize: "12px",
    textAlign: "center",
    color: "#000",
  },
  tableColtext2: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "70%",
    padding: 5,
    fontSize: "10px",
    textAlign: "left",
    color: "rgba(110, 111, 113, 0.91)",
  },
  text: {
    color: "#085946",
    paddingTop: "10px",
    fontSize: "2.5vw",
  },
  text2: {
    color: "#4B4B4B",
    paddingTop: "10px",
    fontSize: "2.5vw",
    marginBottom: "3.5vw",
  },
  Logo2: {
    width: "92.561px",
    height: "33px",
    marginTop: "12px",
    marginBottom: "12px",
  },
  TCtext:{
    color: "#000",
    fontSize: "2.5vw",
    paddingTop:"2vw",
    alignItems:"left",
    width:'100%',
    textDecoration:'underline',
  },
  Dtext: {
    color: "#4B4B4B",
    fontSize: "1.8vw",
    paddingTop: "5px",
    paddingBottom: "5px",
  },
  Detox: {
    color: "#000",
    fontSize: "2.3vw",
  },
  Dtext1: {
    color: "#4B4B4B",
    fontSize: "2.5vw",
    // paddingTop: "2px",
    paddingBottom: "7px",
  },
  text3: {
    color: "#085946",
    paddingTop: "15px",
    fontSize: "2.5vw",
    textAlign: "center",
    paddingBottom: "25px",
  },
  tableColtable1heading: {
    width: "20%",
    padding: "1vw",
    fontSize: "1.9vw",
    textAlign: "left",
  },
  tableColtable2heading: {
    width: "23%",
    padding: "1vw",
    fontSize: "1.9vw",
    textAlign: "center",
  },
  tableColtable3heading: {
    width: "34%",
    padding: "1vw",
    fontSize: "1.9vw",
    textAlign: "center",
  },
  tableColtable4heading: {
    width: "23%",
    padding: "1vw",
    fontSize: "1.9vw",
    textAlign: "center",
  },
  tableColfoodtext1: {
    width: "20%",
    padding: "2vw 1vw",
    fontSize: "1.8vw",
    textAlign: "left",
    color: "#000",
    borderRight: "1px solid #e3dcdc",
  },
  tableColfoodtext2: {
    width: "23%",
    padding: "2vw 1vw",
    fontSize: "1.8vw",
    textAlign: "left",
    color: "#4B4B4B",
    borderRight: "1px solid #e3dcdc",
  },
  tableColfoodtext3: {
    width: "34%",
    padding: "2vw 1vw",
    fontSize: "1.8vw",
    textAlign: "left",
    color: "#4B4B4B",
    borderRight: "1px solid #e3dcdc",
  },
  tableColfoodtext4: {
    width: "23%",
    padding: "2vw 1vw",
    fontSize: "1.8vw",
    textAlign: "left",
    color: "#4B4B4B",
  },
  tableColfoodtext1Last: {
    width: "23%",
    padding: 5,
    fontSize: "1.8vw",
    textAlign: "center",
    color: "#4B4B4B",
  },
  tableColfoodtext2Last: {
    width: "23%",
    padding: 5,
    fontSize: "1.8vw",
    textAlign: "center",
    color: "#4B4B4B",
  },
  tableColfoodtext3Last: {
    width: "31%",
    padding: 5,
    fontSize: "1.8vw",
    textAlign: "center",
    color: "#4B4B4B",
  },
  tableColfoodtext4Last: {
    width: "23%",
    padding: 5,
    fontSize: "1.8vw",
    textAlign: "center",
    color: "#4B4B4B",

    // borderRight: "1px solid black",
  },

  Lifestyle: {
    paddingTop: "15px",
    paddingBottom: "25px",
    fontSize: "2.75vw",
    textAlign: "center",
  },
  info_section: {
    display: "flex",
    width: "88vw",
    padding: "25px 18px 24px 18px",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    backgroundColor: "white",
    borderRadius: "4px",
    border: "2px solid #cfc4c4",
    borderTop: "0px solid #cfc4c4",
  },
  info_section_text_1: {
    marginBottom: "2vw",
    color: "#4B4B4B",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "3vw",
  },
  info_section_text_h1: {
    // marginTop: "13px",
    color: "#000",

    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "35px",
    letterSpacing: "0.2px",
  },
  info_section_text_2: {
    color: "#4B4B4B",

    fontSize: "11px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "35px",
  },
  info_section_text_h2: {
    color: "#000",

    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "35px",
  },
});

const MyDocument2 = ({
  vitals,
  profileData,
  email,
  ayur,
  prakriti,
  dietChart,
  exercise,
}) => {
  console.log(
    "from pdf",
    vitals,
    profileData,
    email,
    ayur,
    prakriti,
    dietChart,
    exercise
  );

  const vitalsMap = [
    {
      desc: "Heart Rate",
      vital: vitals.heart_rate,
      unit: "bpm",
      ref: "60 - 100",
    },
    {
      desc: "Stroke Volume",
      vital: vitals.stroke_volume,
      unit: "ml",
      ref: "60 - 100",
    },
    {
      desc: "MAP",
      vital: vitals.mean_atrial_pressure,
      unit: "mmHg",
      ref: "70 - 100",
    },
    { desc: "SBP", vital: vitals.sbp, unit: "mmHg", ref: "90 - 120" },
    { desc: "DBP", vital: vitals.dbp, unit: "mmHg", ref: "60 - 80" },
    { desc: "RMSSD", vital: vitals.rmssd, unit: "ms", ref: "80 - 200" },
    { desc: "SDNN", vital: vitals.sdnn, unit: "L/min", ref: "80 - 200" },
    { desc: "IBI", vital: vitals.ibi, unit: "ms", ref: "600-1200" },
    { desc: "SD1", vital: vitals.sd1, unit: "ms", ref: "60 - 140" },
    { desc: "SD2", vital: vitals.sd2, unit: "ms", ref: "70 - 180" },
    {
      desc: "Fatty liver index",
      vital: vitals.fattyLiverIndex,
      unit: "-",
      ref: "<40",
    },
    {
      desc: "URIC ACID",
      vital: vitals.uricAcid,
      unit: "mg/dl",
      ref: "3.5-8.5",
    },
    { desc: "STRESS INDEX", vital: vitals.stress_index, unit: "-", ref: "<27" },
    {
      desc: "OXYGEN SATURATION",
      vital: vitals.oxygen_saturation,
      unit: "%",
      ref: "95-100",
    },
  ];
  const dietch = [
    {
      item: "Rasas(Taste)",
      eat: dietChart.eat,
      ocon: dietChart.occasionally,
      avd: dietChart.avoid,
    },
    {
      item: "Cereals (grains)",
      eat: dietChart.eat,
      ocon: dietChart.occasionally,
      avd: dietChart.avoid,
    },
    {
      item: "Pulses(legumes)",
      eat: dietChart.eat,
      ocon: dietChart.occasionally,
      avd: dietChart.avoid,
    },
    {
      item: "Vegetables",
      eat: dietChart.eat,
      ocon: dietChart.occasionally,
      avd: dietChart.avoid,
    },
    {
      item: "Fruits",
      eat: dietChart.eat,
      ocon: dietChart.occasionally,
      avd: dietChart.avoid,
    },
    {
      item: "Spices",
      eat: dietChart.eat,
      ocon: dietChart.occasionally,
      avd: dietChart.avoid,
    },
    {
      item: "Non-Veg",
      eat: dietChart.eat,
      ocon: dietChart.occasionally,
      avd: dietChart.avoid,
    },
    {
      item: "Milk products",
      eat: dietChart.eat,
      ocon: dietChart.occasionally,
      avd: dietChart.avoid,
    },
    {
      item: "Oil products",
      eat: dietChart.eat,
      ocon: dietChart.occasionally,
      avd: dietChart.avoid,
    },
    {
      item: "Nuts/seeds",
      eat: dietChart.eat,
      ocon: dietChart.occasionally,
      avd: dietChart.avoid,
    },
  ];
  
  const pexercise2 = [
    { pranayama: exercise.pranayama },
    { pranayama: exercise.pranayama },
    { pranayama: exercise.pranayama },
    { pranayama: exercise.pranayama },
    { pranayama: exercise.pranayama },
    { pranayama: exercise.pranayama },
  ];
  const aexercise = [
    { asanas: exercise.asanas },
    { asanas: exercise.asanas },
    { asanas: exercise.asanas },
    { asanas: exercise.asanas },
    { asanas: exercise.asanas },
    { asanas: exercise.asanas },
    { asanas: exercise.asanas },
    { asanas: exercise.asanas },
    { asanas: exercise.asanas },
    { asanas: exercise.asanas },
  ];
  const aexercise2 = [
    { asanas: exercise.asanas },
    { asanas: exercise.asanas },
    { asanas: exercise.asanas },
    { asanas: exercise.asanas },
    { asanas: exercise.asanas },
    { asanas: exercise.asanas },
    { asanas: exercise.asanas },
    { asanas: exercise.asanas },
  ];

  return (
    <Document>
      <Page size="A4">
        <View style={{ position: "relative" }}>
          <View
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Image src={Logo} style={styles.topLogo} />
            <View style={[styles.container, { height: "100%" }]}>
              <View style={styles.header}>
                <View style={styles.userDetailsContainer}>
                  {/* Row for Labels */}
                  <View
                    style={[
                      styles.userDetailsRow,
                      { borderTop: "1px solid black" },
                    ]}
                  >
                    <Text style={styles.labelText}>NAME</Text>
                    <Text style={styles.labelText}>AGE</Text>
                    <Text style={styles.labelText}>CONTACT</Text>
                    <Text style={styles.labelText}>HEIGHT</Text>
                    <Text style={styles.labelText}>WEIGHT</Text>
                  </View>

                  {/* Row for Data */}
                  <View style={styles.userDetailsRow}>
                    <Text style={styles.valueText}>{profileData.name}</Text>
                    <Text style={styles.valueText}>{profileData.age}</Text>
                    <Text style={styles.valueText}>{email}</Text>
                    <Text style={styles.valueText}>{profileData.height}</Text>
                    <Text style={styles.valueText}>{profileData.weight}</Text>
                  </View>
                </View>
              </View>
              {/* HRV Parameters Section */}
              <Text style={styles.sectionHeader}>HRV Parameters</Text>
              <View style={styles.table}>
                <View style={styles.tablecont}>
                  <View style={styles.tableRowheading}>
                    <Text style={styles.tableCol1H}>VITALS</Text>
                    <Text style={styles.tableCol2H}>VALUES</Text>
                    <Text style={styles.tableCol3H}>UNITS</Text>
                    <Text style={styles.tableCol4H}>REFERENCE RANGES</Text>
                  </View>
                </View>
                <View style={styles.rowscontainer}>
                  {vitalsMap.map((param, index) => (
                    <View style={styles.tableRowHRV} key={index}>
                      <Text style={styles.tableCol1}>{param.desc}</Text>
                      <Text style={styles.tableCol2}>{param.vital}</Text>
                      <Text style={styles.tableCol3}>{param.unit}</Text>
                      <Text style={styles.tableCol4}>{param.ref}</Text>
                    </View>
                  ))}
                </View>
              </View>
              <Text style={styles.itervetextheading}>
                These parameters relate to heartbeat deviations and reflect
                autonomic nervous system activity:
              </Text>
              <Text style={styles.itervetext}>
                SDNN: Measures overall heartbeat variability (standard deviation
                of NN intervals).
              </Text>
              <Text style={styles.itervetext}>
                RMSSD: Reflects short-term heartbeat changes (root mean square
                of successive differences).
              </Text>
              <Text style={styles.itervetext}>
                SD1: Represents short-term heartbeat variability (Poincaré plot,
                perpendicular to identity line).
              </Text>
              <Text style={styles.itervetext}>
                SD2: Indicates long-term heartbeat variability (Poincaré plot,
                along the identity line).
              </Text>
              <Text style={styles.itervetext}>
                IBI: Time interval between consecutive heartbeats (in
                milliseconds).
              </Text>
              <Text style={styles.itervetext}>
                Stroke Volume (SV): The amount of blood ejected by the heart in
                a single heartbeat, indicating heart strength.
              </Text>
              <Text style={styles.bottomText}>
                NOTE- Please select your items based on your daily preference,
                whether vegetarian or non-vegetarian.
                {"\n"}
                {"(01/05)"}
              </Text>
            </View>
          </View>
          <Image src={AivotBG} style={styles.backgroundImage} />
        </View>
      </Page>
      <Page size="A4">
        <View style={[styles.container, { height: "100%" }]}>
          <Image src={Logo} style={styles.Logo1} />

          <Text style={styles.sectionHeader}>
            Ayurveda Results Based on Questionnaire & AI
          </Text>
          <View style={styles.table}>
            <View style={[styles.tableRow, { padding: "0" }]}>
              <Text style={styles.tableColWide1H}>DOSHA</Text>
              <Text style={styles.tableColWide2H}></Text>
            </View>
            <View style={styles.rowscontainer}>
              <View style={styles.tableRow}>
                <Text style={[styles.tableColWide1, { color: "#256D9D" }]}>
                  VATA
                </Text>
                <Text style={styles.tableColWide2}>
                  {parseFloat(ayur["VataValue"].toFixed(2))} %
                </Text>
              </View>{" "}
              <View style={styles.tableRow}>
                <Text style={[styles.tableColWide1, { color: "#DD472A" }]}>
                  PITA
                </Text>
                <Text style={styles.tableColWide2}>
                  {parseFloat(ayur["PitaValue"].toFixed(2))} %
                </Text>
              </View>{" "}
              <View style={styles.tableRow}>
                <Text style={[styles.tableColWide1, { color: "#085946" }]}>
                  KAPHA
                </Text>
                <Text style={styles.tableColWide2}>
                  {parseFloat(ayur["KaphaValue"].toFixed(2))} %
                </Text>
              </View>
            </View>
            <Text style={styles.sectionHeader}>
              Prakriti & Vikruti Category
            </Text>
            <View style={styles.table}>
              <View style={[styles.tableRow, { padding: "0" }]}>
                <Text style={[styles.tableColWide1H, { width: "34vw" }]}>
                  PRAKRITI
                </Text>
                <Text
                  style={[
                    styles.tableColWide2H,
                    { textAlign: "center", width: "54vw" },
                  ]}
                >
                  VIKRUTI
                </Text>
              </View>
              <View style={styles.rowscontainer}>
                <View style={styles.tableRow}>
                  <Text
                    style={[
                      styles.tableColWide1,
                      { width: "34vw", textAlign: "left", paddingLeft: "9vw" },
                    ]}
                  >
                    {prakriti}
                  </Text>
                  <Text style={[styles.tableColWide2, { width: "54vw" }]}>
                    Vata {ayur["Vata"]}, Pitta {ayur["Pitta"]}, Kapha{" "}
                    {ayur["Kapha"]}
                  </Text>
                </View>
              </View>
            </View>
            {/* Footer */}
          </View>
          <Text style={styles.vatatext}>
            <Text style={styles.vataHtext}>Vata -</Text>Vata governs movement,
            creativity, and communication. When balanced, it promotes energy and
            adaptability. An imbalanced Vata may lead to restlessness and
            dryness. Nourish with warm, grounding foods and maintain a regular
            routine.
          </Text>
          <Text style={styles.vatatext}>
            <Text style={styles.vataHtext}>Pitta -</Text>Pitta controls
            metabolism, digestion, and transformation. Balanced Pitta fosters .
            intelligence and determination. Imbalances can cause irritability or
            overheating. Cool and soothing foods help to balance Pitta, along
            with staying calm and relaxed
          </Text>
          <Text style={styles.vatatext}>
            <Text style={styles.vataHtext}>Kapha -</Text> Kapha provides
            structure, stability, and strength. When in balance, it promotes
            calmness and endurance. An imbalance may cause sluggishness or
            heaviness. Light, warm foods and regular exercise help maintain
            Kapha balance.
          </Text>

          <Text style={[styles.bottomText, { marginTop: "43vw" }]}>
            NOTE- Please select your items based on your daily preference,
            whether vegetarian or non-vegetarian.
            {"\n"}
            {"(02/05)"}
          </Text>
        </View>
        <Image src={AivotBG} style={styles.backgroundImage} />
      </Page>
      {/* <Page size="A4">
        <View style={[styles.contentcontainer, { height: "100%" }]}>
          <Image src={Logo} style={[styles.Logo1, { marginBottom: "3vw" }]} />
          <Text style={styles.IText}>
            Based on your Prakriti and Vikruti analysis, your body is currently
            in a state of inflammation (AMA). To help reduce the inflammation,
            It is recommended to follow this 11-day diet plan.{" "}
          </Text>
          <Text style={styles.sectionHeader}>
            For Day 1 to day 5: Semi liquid diet along with detox water
          </Text>
          <View style={styles.table}>
            <View style={styles.rowscontainer}>
              <View style={styles.tableRow}>
                <Text style={styles.tableColtext1}>Morning (8 am)</Text>
                <Text style={styles.tableColtext2}>
                  Soak 30 grams of split green gram for 3 hours. Add 180 ml of
                  water and boil until the green gram becomes soft. Mash it,
                  then add a pinch of salt, cumin (jeera), and pepper powder.
                  Stir well and drink
                </Text>
              </View>
              <View style={styles.tableRow}>
                <Text style={styles.tableColtext1}>Mid-day (12 PM)</Text>
                <Text style={styles.tableColtext2}>
                  Take one cup of little millet (Kutki) and soak it. Add 6 cups
                  of water and boil until the millet becomes soft. Mash it, then
                  add a little salt, cumin (jeera), and pepper. stir well and
                  drink.
                </Text>
              </View>
              <View style={styles.tableRow}>
                <Text style={styles.tableColtext1}>Evening (5 PM)</Text>
                <Text style={styles.tableColtext2}>
                  Take one cup of Sabu-dana (tapioca pearls) and add six cups of
                  water. Boil it until it reaches a soupy consistency. Add salt
                  and red chili according to taste, then boil it again before
                  drinking.
                </Text>
              </View>
              <View style={styles.tableRow}>
                <Text style={styles.tableColtext1}>Night (8 PM)</Text>
                <Text style={styles.tableColtext2}>
                  Take one cup of Sabu-dana (tapioca pearls) and add six cups of
                  water. Boil it until it reaches a soupy consistency. Add salt
                  and red chili according to taste, then boil it again before
                  drinking.
                </Text>
              </View>
            
      
              <View style={styles.tableRow}>
                <Text style={styles.tableColtext1}>
                  Alternative{"\n"}(mid-day or night)
                </Text>
                <Text style={styles.tableColtext2}>
                  Wheat Gruel (Dalia), start by rinsing 1/2 cup of broken wheat
                  and dry roasting it in a pan until it turns golden. In a
                  separate pot, bring 5 cups of water to a boil, then add the
                  roasted wheat and stir well. Let it simmer on low heat for
                  15-20 minutes, stirring occasionally until the wheat becomes
                  soft and fully cooked. Once done, season with a pinch of salt
                  and mix everything together. Serve the gruel.
                </Text>
              </View>
              <View style={styles.tableRow}>
                <Text style={styles.tableColtext1}>
                  Detox water{"\n"}(Drink in place of Tea and coffee)
                </Text>
                <Text style={styles.tableColtext2}>
                  To make this detox water, take three liters of water and add
                  two pinches of ginger powder, six pinches of coriander powder,
                  and two pinches of cumin (jeera) powder. Stir the mixture well
                  and bring it to a boil. Let it simmer for 5 minutes, then
                  remove from heat. After cooling slightly, store the detox
                  water in a steel bottle and drink it throughout the day to
                  stay refreshed and hydrated.
                </Text>
              </View>
              </View>
              </View>
            
         
          <Text style={styles.sectionHeader}>
            For Day 6 to day 8: Semi liquid diet along with detox water
          </Text>
          <View style={styles.table}>
            <View style={styles.rowscontainer}>
              <View style={styles.tableRow}>
                <Text style={styles.tableColtext1}>Morning (8 am)</Text>
                <Text style={styles.tableColtext2}>
                  To make oil-free upma, dry roast semolina until golden. In a
                  heated pot, sauté mustard seeds, cumin, curry leaves, green
                  chilies, and onions until softened, then add vegetables. Pour
                  2 cups of water, add turmeric and salt, bring to a boil, and
                  gradually stir in the roasted semolina. Simmer for 3-4
                  minutes, garnish with coriander and lemon juice, and serve
                  hot.
                </Text>
              </View>
              <View style={styles.tableRow}>
                <Text style={styles.tableColtext1}>Mid-day (12 PM)</Text>
                <Text style={styles.tableColtext2}>
                  Rinse and soak 1/2 cup of rice and 1/2 cup of yellow moong dal
                  for 10-15 minutes. In a pot or pressure cooker, boil 4 cups of
                  water and add rice, dal, turmeric, salt, and optional cumin
                  seeds. Add vegetables like carrots, peas, or beans if desired,
                  and cook until soft. Adjust the consistency with water if
                  needed and serve hot
                </Text>
              </View>
            </View>
          </View>

          <Text style={[styles.bottomText, { marginTop: "auto" }]}>
            NOTE- Please select your items based on your daily preference,
            whether vegetarian or non-vegetarian.
            {"\n"}
            {"(03/07)"}
          </Text>
        </View>

        <Image src={AivotBG} style={styles.backgroundImage} />
      </Page> */}
      {/* <Page size="A4">
        <View style={[styles.contentcontainer, { height: "100%" }]}>
          <Image src={Logo} style={styles.Logo2} />
          <View style={styles.rowscontainer}>
            <View style={styles.tableRow}>
              <Text style={styles.tableColtext1}>Evening (5 PM)</Text>
              <Text style={styles.tableColtext2}>
                Rinse 1 cup of rice and cook with 2 cups of water and salt for
                about 15 minutes. For the dal, rinse 1 cup of lentils and cook
                in 3 cups of water until soft. In a pan, sauté cumin seeds,
                onions, ginger-garlic paste, tomatoes, and green chilies, then
                add turmeric and salt. Combine with the cooked lentils, simmer
                for 5-10 minutes, garnish with coriander, and serve with rice
              </Text>
            </View>
          </View>
          <Text style={styles.sectionHeader}>
            For Day 9 to Day 11: Semi solid diet along with detox water
          </Text>
          <View style={styles.table}>
            <View style={styles.rowscontainer}>
              <View style={styles.tableRow}>
                <Text style={styles.tableColtext1}>Morning (8 am)</Text>
                <Text style={styles.tableColtext2}>
                  To make oil-free upma, dry roast semolina until golden. In a
                  heated pot, sauté mustard seeds, cumin, curry leaves, green
                  chilies, and onions until softened, then add vegetables. Pour
                  2 cups of water, add turmeric and salt, bring to a boil, and
                  gradually stir in the roasted semolina. Simmer for 3-4
                  minutes, garnish with coriander and lemon juice, and serve
                  hot.
                </Text>
              </View>
          
              <View style={styles.tableRow}>
                <Text style={styles.tableColtext1}>Mid-day (12 PM)</Text>
                <Text style={styles.tableColtext2}>
                  Roti and simple sabji (any vegetable expect brinjal and
                  potato)
                </Text>
              </View>
              <View style={styles.tableRow}>
                <Text style={styles.tableColtext1}>Evening (5 PM)</Text>
                <Text style={styles.tableColtext2}>
                  Rinse 1 cup of rice and cook with 2 cups of water and salt for
                  about 15 minutes. For the dal, rinse 1 cup of lentils and cook
                  in 3 cups of water until soft. In a pan, sauté cumin seeds,
                  onions, ginger-garlic paste, tomatoes, and green chilies, then
                  add turmeric and salt. Combine with the cooked lentils, simmer
                  for 5-10 minutes, garnish with coriander, and serve with rice
                </Text>
              </View>
            </View>
          </View>
         
          <Text style={[styles.bottomText, { marginTop: "auto" }]}>
            NOTE- Please select your items based on your daily preference,
            whether vegetarian or non-vegetarian.
            {"\n"}
            {"(04/07)"}
          </Text>
        </View>

        <Image src={AivotBG} style={styles.backgroundImage} />
      </Page> */}
      <Page size="A4">
        <View style={[styles.contentcontainer, { height: "100%" }]}>
          <Image src={Logo} style={styles.Logo2} />
          <Text style={styles.text}>DIET RECOMMENDATIONS</Text>
          <Text style={styles.text2}>
           According to Prakriti this is your diet recommendations.
          </Text>
          <View style={styles.table}>
            <View style={styles.rowscontainer}>
              <View style={styles.tableRowDiet}>
                <Text style={styles.tableColtext1}>Breakfast (8-9 am )</Text>
                {prakriti === "a" ? (
                  <Text style={styles.tableColtext2}>
                    Poha, methi paratha, Bael ka chhilka, idli and sambhar,
                    upma, paneer paratha
                  </Text>
                ) : (
                  <Text style={styles.tableColtext2}>
                    Oats porridge, sprout salad, boiled eggs, fruit smoothie
                  </Text>
                )}
              </View>
              <View style={styles.tableRowDiet}>
                <Text style={styles.tableColtext1}>Lunch ( 1-2 pm )</Text>
                {prakriti === "a" ? (
                  <Text style={styles.tableColtext2}>
                    Jowar ki roti, Wheat flour ki roti, Nachni ki roti,Bhindi
                    sabji ,Chole sabji ,Rajma sabji ,Palak paneer, Beans, Mixed
                    vegetable curry, Chicken, Fish{" "}
                  </Text>
                ) : (
                  <Text style={styles.tableColtext2}>
                    Wheat roti, jowar roti, rice roti, beans, capsicum,
                    cauliflower, soy chunks, Matki dal, fish{" "}
                  </Text>
                )}
              </View>
              <View style={styles.tableRowDiet}>
                <Text style={styles.tableColtext1}>Dinner (7-8 PM)</Text>
                {prakriti === "a" ? (
                  <Text style={styles.tableColtext2}>
                    Millet Upma, Vegetable Khichdi, Daliya (Cracked Wheat),
                    Broccoli Soup, Sprouts (Moong), Nachni Soup (Ragi Soup) with
                    Vegetables
                  </Text>
                ) : (
                  <Text style={styles.tableColtext2}>
                    Ragi ambali, cooked vegetables, beetroot -carrot tikkis,
                    rice and dal, hummus and rice roti,rice toor dal{" "}
                  </Text>
                )}
              </View>
            </View>
          </View>
          <Text style={styles.TCtext}>In place of Tea & Coffee take Detox Water</Text>
          <Text style={styles.Dtext}>
            <Text style={styles.Detox}>Detox water:</Text> To make this detox
            water, take six litres of water and add two pinches of cinnamon, six
            pinches of coriander powder, and two pinches of cumin (jeera)
            powder. Stir the mixture well and bring it to a boil. Let it simmer
            for 5 minutes, then remove from heat. After cooling slightly, store
            the detox water in a steel bottle and drink it throughout the day to
            stay refreshed and hydrated.
          </Text>
         

          <Text style={[styles.bottomText, { marginTop: "auto" }]}>
            NOTE- Please select your items based on your daily preference,
            whether vegetarian or non-vegetarian.
            {"\n"}
            {"(03/05)"}
          </Text>
        </View>

        <Image src={AivotBG} style={styles.backgroundImage} />
      </Page>
      <Page size="A4">
        <View style={[styles.contentcontainer, { height: "100%" }]}>
          <Image src={Logo} style={styles.Logo2} />
          <Text style={styles.text3}>
            You can follow the food items listed below to support the above
            Prakriti diet.
          </Text>
          <View style={[styles.table, { padding: "0 0" }]}>
            <View
              style={[styles.rowscontainer, { padding: "0 0", margin: "0 0" }]}
            >
              <View
                style={[
                  styles.tableRow,
                  { backgroundColor: "#F3F1F1", width: "100%", padding: "0 0" },
                ]}
              >
                <Text style={styles.tableColtable1heading}>FOOD ITEMS</Text>
                <Text style={styles.tableColtable2heading}>EAT</Text>
                <Text style={styles.tableColtable3heading}>
                  OCCASIONALLY{"\n"}(MONTHLY EAT 3 OR 4 TIMES)
                </Text>
                <Text style={styles.tableColtable4heading}>AVOID</Text>
              </View>
              {dietch.map((param, index) => (
                <View style={[styles.tableRow, { padding: "0 0" }]} key={index}>
                  <Text style={styles.tableColfoodtext1}>{param.item}</Text>
                  <Text style={styles.tableColfoodtext2}>
                    {param.eat?.[index]}
                  </Text>
                  <Text style={styles.tableColfoodtext3}>
                    {param.ocon?.[index]}
                  </Text>
                  <Text style={styles.tableColfoodtext4}>
                    {param.avd?.[index]}
                  </Text>
                </View>
              ))}
            </View>
          </View>

          {/* <View style={[styles.table, { padding: "0" }]}>
            <View style={[styles.rowscontainer, { padding: "0" }]}>
              {dietch2.map((param, index) => (
                <View style={[styles.tableRow, { padding: "0" }]} key={index}>
                  <Text style={styles.tableColfoodtext1}>{param.item}</Text>
                  <Text style={styles.tableColfoodtext2}>
                    {param.eat?.[index]}
                  </Text>
                  <Text style={styles.tableColfoodtext3}>
                    {param.ocon?.[index]}
                  </Text>
                  <Text style={styles.tableColfoodtext4}>
                    {param.avd?.[index]}
                  </Text>
                </View>
              ))}
            </View>
          </View> */}
          <Text style={[styles.Dtext1, { width: "88vw"}]}>
            <Text style={styles.Detox}>Note:</Text> The amount of food depends
            on your regular dietary intake.{" "}
          </Text>
         
          <Text style={[styles.bottomText, { marginTop: "2vw" }]}>
            NOTE- Please select your items based on your daily preference,
            whether vegetarian or non-vegetarian.
            {"\n"}
            {"(04/05)"}
          </Text>
        </View>

        <Image src={AivotBG} style={styles.backgroundImage} />
      </Page>
      <Page size="A4">
        <View style={[styles.contentcontainer, { height: "100%" }]}>
          <Image src={Logo} style={styles.Logo2} />
          <Text style={styles.Lifestyle}>
            Suggested Physical Activities, Asanas and Pranayam for Balance and
            Wellness
          </Text>
          <View style={styles.info_section}>
            <Text style={styles.info_section_text_1}>
              <Text style={{ color: "#000", fontWeight: 600 }}>
                Exercise Intensity:{" "}
              </Text>
              <Text style={{ fontWeight: 400 }}>{exercise.intensity}</Text>
            </Text>

            <Text style={styles.info_section_text_1}>
              <Text style={{ color: "#000", fontWeight: 600 }}>
                Asanas & Yogic Practices:{" "}
              </Text>
              <Text style={{ fontWeight: 400 }}>{exercise.focus}</Text>
            </Text>

            <Text style={styles.info_section_text_1}>
              <Text style={{ color: "#000", fontWeight: 600 }}>
                Physical Caution:{" "}
              </Text>
              <Text style={{ fontWeight: 400 }}>{exercise.caution}</Text>
            </Text>

            <Text style={styles.info_section_text_1}>
              <Text style={{ color: "#000", fontWeight: 600 }}>
                Breathwork & Meditation:{" "}
              </Text>
              <Text style={{ fontWeight: 400 }}>{exercise.meditation} </Text>
            </Text>
            {/* Start of second Section */}
            <Text style={styles.info_section_text_h1}>Recommended Asanas</Text>
            {aexercise.map((param, index) => (
              <Text
                style={styles.info_section_text_2}
                key={`aexercise-${index}`}
              >
                {param.asanas[index]}
              </Text>
            ))}
            <Text style={styles.info_section_text_h1}>Pranayama</Text>
            {pexercise2.map((param, index) => (
              <Text style={styles.info_section_text_2} key={index}>
                {param.pranayama[index]}
              </Text>
            ))}

            {/* Last section  */}
            <Text style={styles.info_section_text_h2}>
              Meditation: Practice 15-30 minutes of meditation.
            </Text>
          </View>

          <Text style={[styles.bottomText, { marginTop: "auto" }]}>
            NOTE- Please select your items based on your daily preference,
            whether vegetarian or non-vegetarian.
            {"\n"}
            {"(05/05)"}
          </Text>
        </View>

        <Image src={AivotBG} style={styles.backgroundImage} />
      </Page>
    </Document>
  );
};

export default MyDocument2;






// import React from "react";
// import {
//   PDFDownloadLink,
//   Document,
//   Page,
//   Text,
//   View,
//   StyleSheet,
//   Image,
// } from "@react-pdf/renderer";
// import AivotBG from "../../assets/Aivotlogobackground.jpg";
// import Logo from "../../assets/Care_Vedic.png";


// // Define styles
// const styles = StyleSheet.create({
//   page: {
//     // position:"relative",
//     // flexDirection:"row",
//   },
//   backgroundImage: {
//     position: "absolute",
//     top: -270,
//     left: -380,
//     display: "block",
//     width: "230%",
//     height: "175%",
//     opacity: 0.25, // Watermark effect
//     zIndex: 1,
//   },
//   topLogo: {
//     width: "100px",
//     // height: "33px",
//     margin: "3vw 0",
//   },
//   bottomText: {
//     fontSize: "1.9vw",
//     width: "88vw",
//     textAlign: "center",
//     marginBottom: "2vw",
//     lineHeight: "3vw",
//     marginTop: "auto"
//   },
//   container: {
//     display: "flex",
//     padding: "0vw 6vw",
//     flexDirection: "column",
//     justifyContent: "start",
//     alignItems: "center",
//     position: "relative",
//   },
//   header: {
//     width: "100%",
//     display: "flex",
//     flexDirection: "row",
//     alignItems: "center",
//     justifyContent: "space-between",
//     marginBottom: "1vw",
//     borderBottomColor: "#ccc",
//   },
//   Logo: {
//     height: "50%",
//   },
//   userDetailsContainer: {
//     width: "100%",
//     justifyContent: "space-between",
//   },
//   userDetailsRow: {
//     width: "100%",
//     flexDirection: "row",
//     justifyContent: "space-between",
//     borderBottom: "1px solid black",
//     borderLeft: "1px solid black",
//   },
//   labelText: {
//     fontSize: 12,
//     fontWeight: "bold",
//     textAlign: "center",
//     width: "20%",
//     padding: "1vw 0",
//     borderRight: "1px solid black",
//   },
//   valueText: {
//     fontSize: 10,
//     textAlign: "center",
//     width: "20%",
//     padding: "1vw 0",
//     borderRight: "1px solid black",
//   },

//   sectionHeader: {
//     display: "flex",
//     justifyContent: "center",
//     alignItems: "center",
//     fontSize: 15,
//     fontWeight: "bold",
//     marginVertical: "3vw",
//     textAlign: "center",
//     backgroundColor: "#FFF",
//     // color: "#4B4B4B",
//     padding: "1vw 0",
//     width: "88vw",
//     borderRadius: "15%",
//   },
//   table: {
//     display: "table",
//     width: "100%",
//   },
//   tableRowheading: {
//     flexDirection: "row",
//     fontSize: "1.75vw",
//     backgroundColor:"#F3F1F1",
//     borderBottom: "1px solid  #085946",
//     marginBottom:"20px",
//     padding: "0.5vw 3vw",
//   },
//   tableRow: {
//     flexDirection: "row",
//     fontSize: "2.5vw",
//     padding: "0.5vw 3vw",
//     borderBottom: "1px solid #e3dcdc",
//     width: "88vw",
//   },
//   rowscontainer:{
//     border: "2px solid #e3dcdc",
//     borderTopWidth: "2px",
//     backgroundColor:"rgba(255, 255, 255, 0.80)",
//     borderBottom: "2px solid grey",
//     borderRadius: "0.5vw",
//   },
//   tableCol1: {
//     width: "25%",
//     padding: "1vw",
//     width: "40%",
//     textAlign: "left",
//     fontSize:"10px",
//   },
//   tableCol2: {
//     width: "25%",
//     padding: "1vw",
//     width: "15%",
//     fontSize:"10px",
//     textAlign: "center",
//   },
//   tableCol3: {
//     width: "25%",
//     padding: "1vw",
//     width: "15%",
//     fontSize:"10px",
//     textAlign: "center",
//   },
//   tableCol4: {
//     width: "25%",
//     padding: "1vw",
//     width: "30%",
//     fontSize:"10px",
//     textAlign: "center",
//   },
//   tableCol1H: {
//     width: "25%",
//     padding: "1vw",
//     width: "40%",
//     textAlign: "left",
   
//   },
//   tableCol2H: {
//     width: "25%",
//     padding: "1vw",
//     width: "15%",
//     textAlign: "center",
//   },
//   tableCol3H: {
//     width: "25%",
//     padding: "1vw",
//     width: "15%",
//     textAlign: "center",
//   },
//   tableCol4H: {
//     width: "25%",
//     padding: "1vw",
//     width: "30%",
//     textAlign: "center",
//   },
//   itervetextheading: {
//     marginTop: "1vw",
//     lineHeight: "4vw",
//     fontSize: "2.5vw",
//     textAlign: "left",
//     color: "#000",
//     fontWeight: "bold",
//   },
//   itervetext: {
//     fontSize: "2vw",
//     lineHeight: "4vw",
//     textAlign: "left",
//     color: "#000",
//     fontWeight: "600",
//     width: "88vw",
//   },
//   itervetextContainer: {
//     display: "flex",
//     justifyContent: "flex-start",
//     width: "100%",
//   },
//   tableColWide1: {
//     width: "44vw",
//     padding: "1vw 0",
//     paddingLeft: "15vw",
//     fontSize: "2vw",
//     textAlign: "left",
//     alignItems: "center",
//   },
//   tableColWide2: {
//     width: "44vw",
//     padding: "1vw 0",
//     fontSize: "2vw",
//     textAlign: "center",
//     alignItems: "center",
//   },
//   tableColWide1H: {
//     width: "44vw",
//     fontSize: "2vw",
//     padding: 5,
//     textAlign: "center",
//     marginBottom:"5px",
//     borderBottom: "2px solid grey",
//     backgroundColor:"#F3F1F1",
//   },
//   tableColWide2H: {
//     width: "44vw",
//     fontSize: "2vw",
//     padding: 5,
//     marginBottom:"5px",
//     borderBottom: "2px solid grey",
//     backgroundColor:"#F3F1F1",
//   },
 
//   contentcontainer: {
//     display: "flex",
//     flexDirection: "column",
//     padding: "0vw 6vw ",
//     justifyContent: "center",
//     alignItems: "center",
//     position: "relative",
//   },
//   Logo1: {
//     width: "92.561px",
//     height: "33px",
//     marginTop: "12px",
//   },
//   sectionHeadertext: {
//     fontSize: 15,
//     marginVertical: 10,
//     textAlign: "center",
//     backgroundColor: "#FFF",
//     width: "100%",
//     color: "#4B4B4B",
//   },
//   tableColtext1: {
//     height: "100%",
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",
//     width: "30%",
//     padding: 5,
//     fontSize: "12px",
//     textAlign: "center",
//     color: "#000",
//   },
//   tableColtext2: {
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",
//     width: "70%",
//     padding: 5,
//     fontSize: "10px",
//     textAlign: "left",
//     color: "rgba(110, 111, 113, 0.91)",
//   },
//   text: {
//     color: "#085946",
//     paddingTop: "10px",
//     fontSize: "2.5vw",
//     // paddingBottom:"10px",
//   },
//   text2: {
//     color: "#4B4B4B",
//     paddingTop: "10px",
//     fontSize: "2.5vw",
//     marginBottom: "2.5vw",
//   },
//   Logo2: {
//     width: "92.561px",
//     height: "33px",
//     marginTop: "12px",
//     marginBottom: "12px",
//   },
//   Dtext: {
//     color: "#4B4B4B",
//     fontSize: "2vw",
//     paddingTop: "5px",
//     paddingBottom: "5px",
//   },
//   Detox: {
//     color: "#000",
//     fontSize: "2vw",
//   },
//   text3: {
//     color: "#085946",
//     paddingTop: "15px",
//     fontSize: "2.5vw",
//     textAlign:"center",
//     paddingBottom: "25px",
//   },
//   tableColtable1heading: {
//     width: "20%",
//     padding: "1vw",
//     fontSize: "1.9vw",
//     textAlign: "left",
//   },
//   tableColtable2heading: {
//     width: "23%",
//     padding: "1vw",
//     fontSize: "1.9vw",
//     textAlign: "center",

//   },
//   tableColtable3heading: {
//     width: "34%",
//     padding: "1vw",
//     fontSize: "1.9vw",
//     textAlign: "center",
    
//   },
//   tableColtable4heading: {
//     width: "23%",
//     padding: "1vw",
//     fontSize: "1.9vw",
//     textAlign: "center",
//   },
//   tableColfoodtext1: {
//     width: "20%",
//     padding: "3vw 1vw",
//     fontSize: "1.8vw",
//     textAlign: "left",
//     color: "#000",
//     borderRight: "1px solid #e3dcdc"
//   },
//   tableColfoodtext2: {
//     width: "23%",
//     padding: "3vw 1vw",
//     fontSize: "1.8vw",
//     textAlign: "left",
//     color: "#4B4B4B",
//     borderRight: "1px solid #e3dcdc"
//   },
//   tableColfoodtext3: {
//     width: "34%",
//     padding: "3vw 1vw",
//     fontSize: "1.8vw",
//     textAlign: "left",
//     color: "#4B4B4B",
//     borderRight: "1px solid #e3dcdc"
//   },
//   tableColfoodtext4: {
//     width: "23%",
//     padding: "3vw 1vw",
//     fontSize: "1.8vw",
//     textAlign: "left",
//     color: "#4B4B4B",
//   },
//   tableColfoodtext1Last: {
//     width: "23%",
//     padding: 5,
//     fontSize: "1.8vw",
//     textAlign: "center",
//     color: "#4B4B4B",
//   },
//   tableColfoodtext2Last: {
//     width: "23%",
//     padding: 5,
//     fontSize: "1.8vw",
//     textAlign: "center",
//     color: "#4B4B4B",
 
//   },
//   tableColfoodtext3Last: {
//     width: "31%",
//     padding: 5,
//     fontSize: "1.8vw",
//     textAlign: "center",
//     color: "#4B4B4B",
   
//   },
//   tableColfoodtext4Last: {
//     width: "23%",
//     padding: 5,
//     fontSize: "1.8vw",
//     textAlign: "center",
//     color: "#4B4B4B",
   

//     // borderRight: "1px solid black",
//   },
 
//   Lifestyle:{
//     paddingTop: "15px",
//     paddingBottom:"10px",
//     fontSize:"2.75vw",
//     textAlign: "center",
//   },
//   info_section: {
//     display: "flex",
//     width: "88vw",
//     padding: "10px",
//     flexDirection: "column",
//     justifyContent: "center",
//     alignItems: "flex-start",
//     backgroundColor: "white",
//     borderRadius: "4px",
//     border: "2px solid #cfc4c4",
//     borderTop: "0px solid #cfc4c4"
//   },
//   info_section_text_1: {
//     marginBottom:"2vw",
//     color: "#4B4B4B",
//     fontSize: "12px",
//     fontStyle: "normal",
//     fontWeight: 500,
//     lineHeight: "3vw",
//   },
//   info_section_text_h1: {
//     // marginTop: "13px",
//     color: "#000",
//     fontSize: "15px",
//     fontStyle: "normal",
//     fontWeight: 700,
//     lineHeight: "35px",
//     letterSpacing: "0.2px",
//   },
//   info_section_text_2: {
//     color: "#4B4B4B",
//     fontSize: "11px",
//     fontStyle: "normal",
//     fontWeight: 500,
//     lineHeight: "35px",
//   },
//   info_section_text_h2: {
//     color: "#000",
//     fontSize: "13px",
//     fontStyle: "normal",
//     fontWeight: 600,
//     lineHeight: "35px",
//   },
// });

// const MyDocument2 = ({ vitals, profileData, email, ayur, prakriti, dietChart, exercise}) => {

//   console.log(dietChart)

//   const vitalsMap = [
//     {desc: "Heart Rate", vital: vitals.heart_rate, unit: "bpm", ref: "60 - 100"},
//     {desc: "Stroke Volume", vital: vitals.stroke_volume, unit: "ml", ref: "60 - 100"},
//     {desc: "MAP", vital: vitals.mean_atrial_pressure, unit: "mmHg", ref: "70 - 100"},
//     {desc: "SBP", vital: vitals.sbp, unit: "mmHg", ref: "90 - 120"},
//     {desc: "DBP", vital: vitals.dbp, unit: "mmHg", ref: "60 - 80"},
//     {desc: "RMSSD", vital: vitals.rmssd, unit: "ms", ref: "80 - 200"},
//     {desc: "SDNN", vital: vitals.sdnn, unit: "L/min", ref: "80 - 200"},
//     {desc: "IBI", vital: vitals.ibi, unit: "ms", ref: "600-1200"}, 
//     {desc: "SD1", vital: vitals.sd1, unit: "ms", ref: "60 - 140"},
//     {desc: "SD2", vital: vitals.sd2, unit: "ms", ref: "70 - 180"}, 
//     {desc: "Fatty liver index", vital: vitals.fattyLiverIndex, unit: "-", ref: "<40"}, 
//     {desc: "URIC ACID", vital: vitals.uricAcid, unit: "mg/dl", ref: "3.5-8.5"}, 
//     {desc: "STRESS INDEX", vital: vitals.stress_index, unit: "-", ref: "<27"}, 
//     {desc: "OXYGEN SATURATION", vital: vitals.oxygen_saturation, unit: "%", ref: "95-100"}, 
//   ]
//   const dietch = [
//     { item: "Rasas(Taste)", eat: dietChart.eat, ocon: dietChart.occasionally, avd: dietChart.avoid },
//     { item: "Cereals (grains)", eat: dietChart.eat, ocon: dietChart.occasionally, avd: dietChart.avoid },
//     { item: "Pulses(legumes)", eat: dietChart.eat, ocon: dietChart.occasionally, avd: dietChart.avoid },
//     { item: "Vegetables", eat: dietChart.eat, ocon: dietChart.occasionally, avd: dietChart.avoid },
//     { item: "Fruits", eat: dietChart.eat, ocon: dietChart.occasionally, avd: dietChart.avoid },
//   ]
//   const dietch2=[
//     { item: "Spices", eat: dietChart.eat, ocon: dietChart.occasionally, avd: dietChart.avoid },
//     { item: "Non-Veg", eat: dietChart.eat, ocon: dietChart.occasionally, avd: dietChart.avoid },
//     { item: "Milk products", eat: dietChart.eat, ocon: dietChart.occasionally, avd: dietChart.avoid },
//     { item: "Oil products", eat: dietChart.eat, ocon: dietChart.occasionally, avd: dietChart.avoid },
//     { item: "Nuts/seeds", eat: dietChart.eat, ocon: dietChart.occasionally, avd: dietChart.avoid },
//   ]
//   const pexercise2=[
//     {pranayama:exercise.pranayama},
//     {pranayama:exercise.pranayama},
//     {pranayama:exercise.pranayama},
//     {pranayama:exercise.pranayama},
//     {pranayama:exercise.pranayama},
//     {pranayama:exercise.pranayama},
    
//   ]
//   const aexercise=[
//     {asanas:exercise.asanas},
//     {asanas:exercise.asanas},
//     {asanas:exercise.asanas},
//     {asanas:exercise.asanas},
//     {asanas:exercise.asanas},
//     {asanas:exercise.asanas},
//     {asanas:exercise.asanas},
//     {asanas:exercise.asanas},
//     {asanas:exercise.asanas},
//     {asanas:exercise.asanas},
//   ]

//   return (
//     <Document>
//       <Page size="A4">
//         <View style={{ position: "relative" }}>
//           <View style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "start", height: "100%" }}>
//             <Image src={Logo} style={styles.topLogo} />
//             <View style={[styles.container, { height: "100%" }]}>
//               <View style={styles.header}>
//                 <View style={styles.userDetailsContainer}>
//                   {/* Row for Labels */}
//                   <View style={[styles.userDetailsRow, { borderTop: "1px solid black" }]}>
//                     <Text style={styles.labelText}>NAME</Text>
//                     <Text style={styles.labelText}>AGE</Text>
//                     <Text style={styles.labelText}>CONTACT</Text>
//                     <Text style={styles.labelText}>HEIGHT</Text>
//                     <Text style={styles.labelText}>WEIGHT</Text>
//                   </View>

//                   {/* Row for Data */}
//                   <View style={styles.userDetailsRow}>
//                     <Text style={styles.valueText}>{profileData.name}</Text>
//                     <Text style={styles.valueText}>{profileData.age}</Text>
//                     <Text style={styles.valueText}>{email}</Text>
//                     <Text style={styles.valueText}>{profileData.height}</Text>
//                     <Text style={styles.valueText}>{profileData.weight}</Text>
//                   </View>
//                 </View>
//               </View>
//               {/* HRV Parameters Section */}
//               <Text style={styles.sectionHeader}>HRV Parameters</Text>
//               <View style={styles.table}>
//                 <View style={styles.tablecont}>
//                   <View style={styles.tableRowheading}>
//                     <Text style={styles.tableCol1H}>VITALS</Text>
//                     <Text style={styles.tableCol2H}>VALUES</Text>
//                     <Text style={styles.tableCol3H}>UNITS</Text>
//                     <Text style={styles.tableCol4H}>REFERENCE RANGES</Text>
//                   </View>
//                 </View>
//                 <View style={styles.rowscontainer}>
//                   {vitalsMap.map((param, index) => (
//                     <View style={styles.tableRow} key={index}>
//                       <Text style={styles.tableCol1}>{param.desc}</Text>
//                       <Text style={styles.tableCol2}>{param.vital}</Text>
//                       <Text style={styles.tableCol3}>{param.unit}</Text>
//                       <Text style={styles.tableCol4}>{param.ref}</Text>
//                     </View>
//                   ))}
//                 </View>
//               </View>
//               <Text style={styles.itervetextheading}>
//                 These parameters relate to heartbeat deviations and reflect
//                 autonomic nervous system activity:
//               </Text>
//               <Text style={styles.itervetext}>
//                 SDNN: Measures overall heartbeat variability (standard deviation
//                 of NN intervals).
//               </Text>
//               <Text style={styles.itervetext}>
//                 RMSSD: Reflects short-term heartbeat changes (root mean square
//                 of successive differences).
//               </Text>
//               <Text style={styles.bottomText}>
//                 NOTE- Please select your items based on your daily preference, whether vegetarian or non-vegetarian.
//                 {"\n"}{"(01/05)"}
//               </Text>
//             </View>
//           </View>
//           <Image src={AivotBG} style={styles.backgroundImage} />
//         </View>
//       </Page>
//       <Page size="A4">
//         <View style={[styles.container, { height: "100%" }]}>
//           <Image src={Logo} style={styles.topLogo} />
//           <Text style={styles.itervetext}>
//             SD1: Represents short-term heartbeat variability (Poincaré plot,
//             perpendicular to identity line).
//           </Text>
//           <Text style={[styles.itervetext, { marginTop: "2vw" }]}>
//             SD2: Indicates long-term heartbeat variability (Poincaré plot,
//             along the identity line).
//           </Text>
//           <Text style={styles.itervetext}>
//             IBI: Time interval between consecutive heartbeats (in
//             milliseconds).
//           </Text>
//           <Text style={styles.itervetext}>
//             Stroke Volume (SV): The amount of blood ejected by the heart in
//             a single heartbeat, indicating heart strength.
//           </Text>
//           <Text style={styles.sectionHeader}>
//             Ayurveda Results Based on Questionnaire & AI
//           </Text>
//           <View style={styles.table}>
//             <View style={[styles.tableRow, {padding: "0"}]}>
//               <Text style={styles.tableColWide1H}>DOSHA</Text>
//               <Text style={styles.tableColWide2H}></Text>
//             </View>
//             <View style={styles.rowscontainer}>
//             <View style={styles.tableRow}>
//               <Text style={[styles.tableColWide1, { color: "#256D9D" }]}>VATA</Text>
//               <Text style={styles.tableColWide2}>{parseFloat(ayur['VataValue'].toFixed(2))} %</Text>
//             </View>{" "}
//             <View style={styles.tableRow}>
//               <Text style={[styles.tableColWide1, { color: "#DD472A" }]}>PITA</Text>
//               <Text style={styles.tableColWide2}>{parseFloat(ayur['PitaValue'].toFixed(2))} %</Text>
//             </View>{" "}
//             <View style={styles.tableRow}>
//               <Text style={[styles.tableColWide1, { color: "#085946" }]}>KAPHA</Text>
//               <Text style={styles.tableColWide2}>{parseFloat(ayur['KaphaValue'].toFixed(2))} %</Text>
//             </View>
//             </View>
//             <Text style={styles.sectionHeader}>Prakriti & Vikruti Category</Text>
//             <View style={styles.table}>
//               <View style={[styles.tableRow, { padding: "0" }]}>
//                 <Text style={[styles.tableColWide1H, { width: "34vw" }]}>PRAKRITI</Text>
//                 <Text style={[styles.tableColWide2H, { textAlign: "center", width: "54vw" }]}>VIKRUTI</Text>
//               </View>
//               <View style={styles.rowscontainer}>
//               <View style={styles.tableRow}>
//                 <Text style={[styles.tableColWide1, { width: "34vw", textAlign: "left", paddingLeft: "9vw" }]}>{prakriti}</Text>
//                 <Text style={[styles.tableColWide2, { width: "54vw" }]}>Vata {ayur['Vata']}, Pitta {ayur['Pitta']}, Kapha {ayur['Kapha']}</Text>
//               </View>
//               </View>
//             </View>
//             {/* Footer */}
//           </View>
//           <Text style={styles.text}>DIET RECOMMENDATIONS</Text>
//           <Text style={styles.text2}>
//             You can follow this diet to continue supporting better health.
//           </Text>
//           <View style={styles.table}>
//           <View style={styles.rowscontainer}>
//             <View style={styles.tableRow}>
//               <Text style={styles.tableColtext1}>Breakfast (8-9 am )</Text>
//               {prakriti === "a" ? (
//                   <Text style={styles.tableColtext2}>
//                     Poha, methi paratha, Bael ka chhilka, idli and sambhar,
//                     upma, paneer paratha
//                   </Text>
//                 ) : (
//                   <Text style={styles.tableColtext2}>Oats porridge, sprout salad, boiled eggs, fruit smoothie</Text>
//                 )}
//             </View>
//             <View style={styles.tableRow}>
//               <Text style={styles.tableColtext1}>Lunch ( 1-2 pm )</Text>
//               {prakriti === "a" ? (
//                   <Text style={styles.tableColtext2}>
//                     Jowar ki roti, Wheat flour ki roti, Nachni ki roti,Bhindi
//                     sabji ,Chole sabji ,Rajma sabji ,Palak paneer, Beans, Mixed
//                     vegetable curry, Chicken, Fish{" "}
//                   </Text>
//                 ) : (
//                   <Text style={styles.tableColtext2}>
//                     Wheat roti, jowar roti, rice roti, beans, capsicum,
//                     cauliflower, soy chunks, Matki dal, fish{" "}
//                   </Text>
//                 )}
//             </View>
//             <View style={styles.tableRow}>
//               <Text style={styles.tableColtext1}>Dinner (7-8 PM)</Text>
//               {prakriti === "a" ? (
//                   <Text style={styles.tableColtext2}>
//                     Millet Upma, Vegetable Khichdi, Daliya (Cracked Wheat),
//                     Broccoli Soup, Sprouts (Moong), Nachni Soup (Ragi Soup) with
//                     Vegetables
//                   </Text>
//                 ) : (
//                   <Text style={styles.tableColtext2}>
//                     Ragi ambali, cooked vegetables, beetroot -carrot tikkis,
//                     rice and dal, hummus and rice roti,rice toor dal{" "}
//                   </Text>
//                 )}
//             </View>
//             </View>
//           </View>
          
//           <Text style={[styles.bottomText, { marginTop: "auto" }]}>NOTE- Please select your items based on your daily preference, whether vegetarian or non-vegetarian.
//           {"\n"}{"(02/05)"}
//           </Text>
//         </View>
//         <Image src={AivotBG} style={styles.backgroundImage} />
//       </Page>
//       <Page size="A4">
//         <View style={[styles.contentcontainer, { height: "100%" }]}>
//           <Image src={Logo} style={styles.topLogo} />
//           <Text style={styles.Dtext}>
//             <Text style={styles.Detox}>Detox water:</Text>
//             {prakriti==='a' ? ("To make this detox water, take six liters of water and add two pinches of fennel seeds powder, six pinches of coriander powder, and two pinches of cumin (jeera) powder. Stir the mixture well and bring it to a boil. Let it simmer for 5 minutes, then remove from heat. After cooling slightly, store the detox water in a steel bottle and drink it throughout the day to stay refreshed and hydrated. ") 
//             :
//             ("To make this detox water, take six liters of water and add two pinches of cinnamon, six pinches of coriander powder, and two pinches of cumin (jeera) powder. Stir the mixture well and bring it to a boil. Let it simmer for 5 minutes, then remove from heat. After cooling slightly, store the detox water in a steel bottle and drink it throughout the day to stay refreshed and hydrated.")}
//           </Text>
//           <Text style={styles.text3}>
//             You can follow the food items listed below to support the above
//             Prakriti diet.
//           </Text>
//           <View style={[styles.table, { padding: "0 0" }]}>
//             <View style={[styles.rowscontainer, { padding: "0 0", margin: "0 0" }]}>
//               <View style={[styles.tableRow, { backgroundColor: "#F3F1F1", width: "100%", padding: "0 0" }]}>
//                 <Text style={styles.tableColtable1heading}>FOOD ITEMS</Text>
//                 <Text style={styles.tableColtable2heading}>EAT</Text>
//                 <Text style={styles.tableColtable3heading}>OCCASIONALLY{"\n"}(MONTHLY EAT 3 OR 4 TIMES)</Text>
//                 <Text style={styles.tableColtable4heading}>AVOID</Text>
//               </View>
//               {dietch.map((param, index) => (
//               <View style={[styles.tableRow, { padding: "0 0" }]} key={index}>
//                 <Text style={styles.tableColfoodtext1}>{param.item}</Text>
//                 <Text style={styles.tableColfoodtext2}>{param.eat?.[index]}</Text>
//                 <Text style={styles.tableColfoodtext3}>{param.ocon?.[index]}</Text>
//                 <Text style={styles.tableColfoodtext4}>{param.avd?.[index]}</Text>
//               </View>
//               ))}
//             </View>
//           </View>
//           <Text style={[styles.bottomText, { marginTop: "auto" }]}>NOTE- Please select your items based on your daily preference, whether vegetarian or non-vegetarian.
//             {"\n"}{"(03/05)"}</Text>
//         </View>

//         <Image src={AivotBG} style={styles.backgroundImage} />
//       </Page>
//       <Page size="A4">
//         <View style={[styles.contentcontainer, { height: "100%" }]}>
//           <Image src={Logo} style={styles.topLogo} />
//           <View style={[styles.table, { padding: "0 0" }]}>
//             <View style={[styles.rowscontainer, { padding: "0 0", margin: "0 0" }]}>
//               <View style={[styles.tableRow, { backgroundColor: "#F3F1F1", width: "100%", padding: "0 0" }]}>
//                 <Text style={styles.tableColtable1heading}>FOOD ITEMS</Text>
//                 <Text style={styles.tableColtable2heading}>EAT</Text>
//                 <Text style={styles.tableColtable3heading}>OCCASIONALLY{"\n"}(MONTHLY EAT 3 OR 4 TIMES)</Text>
//                 <Text style={styles.tableColtable4heading}>AVOID</Text>
//               </View>
//               {dietch2.map((param, index) => (
//               <View style={[styles.tableRow, { padding: "0 0" }]} key={index}>
//                 <Text style={styles.tableColfoodtext1}>{param.item}</Text>
//                 <Text style={styles.tableColfoodtext2}>{param.eat?.[index]}</Text>
//                 <Text style={styles.tableColfoodtext3}>{param.ocon?.[index]}</Text>
//                 <Text style={styles.tableColfoodtext4}>{param.avd?.[index]}</Text>
//               </View>
//               ))}
//             </View>
//           </View>
//           <Text style={[styles.bottomText, { marginTop: "auto" }]}>NOTE- Please select your items based on your daily preference, whether vegetarian or non-vegetarian.
//             {"\n"}{"(04/05)"}</Text>
//         </View>

//         <Image src={AivotBG} style={styles.backgroundImage} />
//       </Page>
//       <Page size="A4">
//         <View style={[styles.contentcontainer, { height: "100%" }]}>
//           <Image src={Logo} style={styles.topLogo} />
//           <Text style={styles.Lifestyle}>
//             Suggested Physical Activities, Asanas and Pranayam for Balance and Wellness
//           </Text>
//           <View style={styles.info_section}>
//             <Text style={styles.info_section_text_1}>
//               <Text style={{ color: "#000", fontWeight: 600 }}>
//                 Exercise Intensity:{" "}
//               </Text>
//               <Text style={{ fontWeight: 400 }}>
//               {exercise.intensity}
//               </Text>
//             </Text>

//             <Text style={styles.info_section_text_1}>
//               <Text style={{ color: "#000", fontWeight: 600 }}>
//                 Asanas & Yogic Practices:{" "}
//               </Text>
//               <Text style={{ fontWeight: 400 }}>
//                 {exercise.focus}
//               </Text>
//             </Text>

//             <Text style={styles.info_section_text_1}>
//               <Text style={{ color: "#000", fontWeight: 600 }}>
//                 Physical Caution:{" "}
//               </Text>
//               <Text style={{ fontWeight: 400 }}>
//               {exercise.caution}
//               </Text>
//             </Text>

//             <Text style={styles.info_section_text_1}>
//               <Text style={{ color: "#000", fontWeight: 600 }}>
//                 Breathwork & Meditation:{" "}
//               </Text>
//               <Text style={{ fontWeight: 400 }}>
//                 {exercise.meditation}{" "}
//               </Text>
//             </Text>
//             {/* Start of second Section */}
//             <Text style={styles.info_section_text_h1}>Recommended Asanas</Text>
//             {aexercise.map((param,index)=>(
//               <Text style={styles.info_section_text_2} key={`aexercise-${index}`}>
//                 {param.asanas[index]}
//               </Text>
//             ))}
//             <Text style={styles.info_section_text_h1}>Pranayama</Text>
//             {pexercise2.map((param,index)=>(
//             <Text style={styles.info_section_text_2} key={index}>
//               {param.pranayama[index]}
//             </Text>
//             ))}


//             {/* Last section  */}
//             <Text style={styles.info_section_text_h2}>
//               Meditation: Practice 15-30 minutes of meditation.
//             </Text>
//           </View>
          
//           <Text style={[styles.bottomText, { marginTop: "auto" }]}>NOTE- Please select your items based on your daily preference, whether vegetarian or non-vegetarian.
//           {"\n"}{"(05/05)"}
//           </Text>
//         </View>

//         <Image src={AivotBG} style={styles.backgroundImage} />
//       </Page>
//     </Document>
//   )
// }

// export default MyDocument2;