import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// import { useAuth } from "../../Authentication/Auth";
import axios from "axios";
import "./EndUser.css";
import aivotlogo from '../../assets/careVedic.png'
import { toast, ToastContainer } from "react-toastify";
import logo from "../../assets/logoayur.png";

import "react-toastify/dist/ReactToastify.css";

function Test() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({});

  const changeHandler = (e)=> {
    const {name, value} = e.target;
    setFormData({...formData, [name]: value})
  }

  const submitHandler = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const response = await axios.post("https://aivotayur.com/api/token", formData);
      console.log(response.data);
      setIsSubmitting(false);
      setFormData({});
      toast.success("Test link sent successfully!", { position: "top-center" ,autoClose: 1500});
    } catch (error) {
      console.error("Error submitting form data:", error.response?.data || error.message);
    }
  };

  const insights = [
    {
      description:
        "Unlock lifestyle and diet guidance tailored to your unique body type and wellness goals.",
    },
    {
      description:
        "Experience a unique blend of Ayurveda and AI to reveal your body's natural balance.",
    },
    {
      description:
        "Understand your Dosha and receive recommendations crafted for your mind-body balance.",
    },
  ];
  // Automatically change slides every 3 seconds
  useEffect(() => {
    const slideInterval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % insights.length);
    }, 3000);

    return () => clearInterval(slideInterval);
  }, [insights.length]);

  const handleSlideClick = (index) => {
    setCurrentSlide(index);
  };

  return (
    <div>
      <div className="Container1">
        <div className="ayur-login-container1">
          <div className="flex flex-col justify-center items-center w-full h-fit">
            <img src={aivotlogo} alt="logo here" className="h-20"/>
            <div className="welcome-text">Submit Your Details</div>
            <form className="ayur-login-form" onSubmit={submitHandler} action="/">
              <div className="input-group">
                <label>Name</label>
                <input
                  className=""
                  name = "name"
                  value={formData.name || ""}
                  onChange={changeHandler}
                  placeholder="Abcd"
                />
              </div>
              <div className="input-group">
                <label>Email</label>
                <input
                  className=""
                  name="email"
                  value={formData.email || ""}
                  onChange={changeHandler}
                  placeholder="email@gmail.com"
                />
              </div>
              <div className="input-group">
                <label>Phone Number</label>
                <input
                  className=""
                  name="phoneNumber"
                  value={formData.phoneNumber || ""}
                  onChange={changeHandler}
                  placeholder="1234567890"
                />
              </div>
              <button 
                className="login-button"
                // onClick={submitHandler}
                disabled={isSubmitting}
              >
                {isSubmitting ? "Submitting..." : "Submit"}
              </button>
            </form>
          </div>
        </div>
        <div className="outerInsightmob">
      <div className="logo"><img src={logo}/></div>
        <div className="Insightsendcontainer">
          <h2 style={{ color: "var(--Gray-0, #FFF)" }}>
            Discover the Power of Ayurvedic Insights
          </h2>
          <p style={{ color: "var(--Gray-0, #FFF)" }}>
            {insights[currentSlide].description}
          </p>

          <div className="slide-dots">
            {insights.map((_, index) => (
              <span
                key={index}
                className={`dot ${index === currentSlide ? "active" : ""}`}
                onClick={() => handleSlideClick(index)}
              />
            ))}
          </div>
        </div>
        </div>
      </div>
      <ToastContainer/>
    </div>
  );
}

export default Test;
