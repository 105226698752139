import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../Authentication/Auth";
import axios from "axios";
import "./Homepage.css";
import googlelogo from "../../assets/google.svg";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import logo from "../../assets/logoayur.png";
import eyeopen from "../../assets/icon_eye_empty.svg"

function Homepage() {
  const [currentSlide, setCurrentSlide] = useState(0);

  const { storeAuthDataInLS } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const insights = [
    {
      description:
        "Unlock lifestyle and diet guidance tailored to your unique body type and wellness goals.",
    },
    {
      description:
        "Experience a unique blend of Ayurveda and AI to reveal your body's natural balance.",
    },
    {
      description:
        "Understand your Dosha and receive recommendations crafted for your mind-body balance.",
    },
  ];
  // Automatically change slides every 3 seconds
  useEffect(() => {
    const slideInterval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % insights.length);
    }, 3000);

    return () => clearInterval(slideInterval);
  }, [insights.length]);

  const handleSlideClick = (index) => {
    setCurrentSlide(index);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const response = await axios.post("https://aivotayur.com/api/", {
        email,
        password,
      });

      if (response.data.status === 1) {
        // Login successful

        localStorage.setItem("authToken", response.data.token);
        const authToken = response.data.token; // Store JWT token
        localStorage.setItem("userId", response.data.id); // Store user ID
        localStorage.setItem("fullName", response.data.fullName);
        storeAuthDataInLS(authToken, email);
        // Redirect to dashboard
        toast.success("Login successful!", {
          position: "top-center",
          autoClose: 1000,
        });
        setTimeout(() => {
          navigate("/dashboard");
        }, 1000);
      } else if (response.data.status === 0) {
        // Incorrect password
        toast.error("Incorrect password. Please try again.", {
          position: "top-center",
          autoClose: 2000,
        });
      } else if (response.data.status === -1) {
        // User not found or OTP not verified
        toast.error("User not found or email verification incomplete.", {
          position: "top-center",
          autoClose: 2000,
        });
      }
    } catch (error) {
      console.error("Error logging in:", error);
      toast.error("An error occurred. Please try again later.", {
        position: "top-center",
        autoClose: 2000,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div>
      {/* //Desktop */}

      <div className="Container">
        <div className="ayur-login-container">
          <div className="form-section">
            <div className="welcome-text"> Welcome to Aivotayur</div>
            {/* <div className="Getting-Started">
                  <p className="Getting-Started-text">
                    Getting started is easy
                  </p>
                  <div className="social-signin">
                    <div className="googlelayout">
                      <div className="googlewrap">
                        <img
                          src={googlelogo}
                          alt="Logo"
                          className="googlelogo"
                        />
                        <p className="googletext flex items-center">Google</p>
                      </div>
                    </div>
                  </div>
                </div>
                <p>Or continue with</p> */}
            <form className="ayur-login-form" onSubmit={handleLogin}>
              <div className="input-group">
                <label> Enter Email</label>
                <input
                  type="email"
                  placeholder="Ram@gmail.com"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />

                <label>Enter Password</label>
                <div className="relative">
                <input
                  type={isPasswordVisible ? "text" : "password"}
                  placeholder="******"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  className="w-full px-3 py-2 border rounded-md"
                />
                <span
                  onClick={togglePasswordVisibility}
                  className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
                >
                  {isPasswordVisible ? (
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="21" viewBox="0 0 25 21" fill="none">
                    <path d="M1.12 1.64539L23.12 19.6046" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M10.2866 9.30518C9.90629 9.65688 9.67554 10.119 9.67554 10.6251C9.67554 11.7271 10.7699 12.6205 12.12 12.6205C12.7399 12.6205 13.3059 12.4322 13.7369 12.1217" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M6.45114 6.19604C4.39557 7.37195 2.68314 9.04726 1.12 10.6249C3.42824 13.6091 7.57541 16.6113 12.12 16.6113C14.0143 16.6113 15.8397 16.0897 17.4915 15.2651" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M12.12 4.63855C17.0192 4.63855 20.3107 7.78959 23.12 10.6249C22.7307 11.1281 22.2893 11.6319 21.8024 12.1215" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  ) : (
                    // <svg
                    //   xmlns="http://www.w3.org/2000/svg"
                    //   width="24"
                    //   height="24"
                    //   viewBox="0 0 24 24"
                    //   fill="none"
                    //   stroke="currentColor"
                    //   strokeWidth="2"
                    //   strokeLinecap="round"
                    //   strokeLinejoin="round"
                    // >
                    //   <path d="M12 6c-4.41 0-8 3.59-8 8s3.59 8 8 8 8-3.59 8-8-3.59-8-8-8zM12 2c5.52 0 10 4.48 10 10s-4.48 10-10 10S2 17.52 2 12s4.48-10 10-10z" />
                    // </svg>
                    <img src= {eyeopen} className="eyeopen"/>
                  )}
                </span>
                </div>
              </div>
              <div className="wrapswitch" style={{ width: "100%" }}>
                <label className="switch">
                  <input type="checkbox" />
                  <span className="slider round"></span>
                </label>
                <div className="checkboxtext">
                  <span className="text1">Remember me</span>
                  <span className="text2">
                    {" "}
                    <a href="/forgot-password"> Recover Password</a>
                  </span>
                </div>
              </div>
              <button
                type="submit"
                className="login-button "
                disabled={isSubmitting}
              >
                {isSubmitting ? "Logging in..." : "Log In"}
              </button>
            </form>
            <div className="login-prompt-container mt-4">
              <p className="login-text">
                Don’t have an account?{" "}
                <a href="/signup" className="login-link">
                  Sign up!
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className="outerInsightmob">
          <div className="logo">
            <img src={logo} />
          </div>
          <div className="Insightshomecontainer">
            <h2 style={{ color: "var(--Gray-0, #FFF)" }}>
              Discover the Power of Ayurvedic Insights
            </h2>
            <p style={{ color: "var(--Gray-0, #FFF)" }}>
              {insights[currentSlide].description}
            </p>

            <div className="slide-dots">
              {insights.map((_, index) => (
                <span
                  key={index}
                  className={`dot ${index === currentSlide ? "active" : ""}`}
                  onClick={() => handleSlideClick(index)}
                />
              ))}
            </div>
          </div>
        </div>
      </div>

      <ToastContainer />
    </div>
  );
}
export default Homepage;
