import React, { useEffect, useState } from "react";
import QuestionSet from "../TestPage/Questionset.jsx";
import "./Test.css";
import axios from "axios";
import Header from "../../components/Header/Header";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

const Test = () => {
  const navigate = useNavigate();

  const [userDetails, setUserDetails] = useState();
  const [currentStep, setCurrentStep] = useState(1);
  const [currentSet, setCurrentSet] = useState(1);
  const [setSubmitted, setSetSubmitted] = useState(false);
  const [formData, setFormData] = useState({
    fullName: "",
    height: "",
    weight: "",
    age: "",
    contactno: "",
    Activity: "",
    sex: "",
    Health: "",
  });
  const [ans, setAns] = useState({});
  const [video, setVideo] = useState(null);
  const [isAnsAdded, setAnsAdded] = useState(false);
  const email = localStorage.getItem("email");
  const [enduser, setEnduser] = useState(true);

  const url = window.location.pathname;

  const steps = [
    { step: 1, sets: 1, label1: "Step 1", label2: "Basic User Details" },
    { step: 2, sets: 5, label1: "Step 2", label2: "Prakriti Assessment" },
    { step: 3, sets: 6, label1: "Step 3", label2: "Vikruti Assessment" },
    { step: 4, sets: 2, label1: "Step 4", label2: "Inflammatory Health Check" },
    { step: 5, sets: 1, label1: "Step 5", label2: "Video Analysis" },
  ];

  const totalSetsForCurrentStep = steps[currentStep - 1].sets;

  const questionSets = {
    1: [
      {
        header:
          "Provide basic details to help us personalize your health insights",
        fields: [
          {
            label: (
              <>
                Full Name<span style={{ color: "red" }}>*</span>
              </>
            ),
            labelText: "Full Name",
            type: "text",
            name: "fullName",
            placeholder: "Enter your Full Name",
            mandatory: true,
          },
          {
            label: (
              <>
                Height<span style={{ color: "red" }}>*</span>
              </>
            ),
            labelText: "Height",
            type: "number",
            name: "height",
            placeholder: "Enter your Height in Meter",
            mandatory: true,
          },
          {
            label: (
              <>
                Weight<span style={{ color: "red" }}>*</span>
              </>
            ),
            labelText: "Weight",
            type: "number",
            name: "weight",
            placeholder: "Enter your Weight in Kg",
            mandatory: true,
          },
          {
            label: (
              <>
                Age<span style={{ color: "red" }}>*</span>
              </>
            ),
            labelText: "age",
            type: "number",
            name: "age",
            placeholder: "Enter your Age",
            mandatory: true,
          },
          {
            label: (
              <>
                Contact No.<span style={{ color: "red" }}>*</span>
              </>
            ),
            labelText: "Contact No.",
            type: "number",
            name: "contactno",
            placeholder: "Enter your Contact No.",
            mandatory: true,
          },
          {
            label: (
              <>
                Activity Factor<span style={{ color: "red" }}>*</span>
              </>
            ),
            labelText: "Activity Factor",
            type: "select",
            name: "Activity",
            options: [
              "Sedentary",
              "Lightly active",
              "Moderately active",
              "Highly active",
            ],
            mandatory: true,
          },
          {
            label: (
              <>
                Gender<span style={{ color: "red" }}>*</span>
              </>
            ),
            labelText: "Gender",
            type: "select",
            name: "sex",
            options: ["Male", "Female", "Other"],
            mandatory: true,
          },
          {
            label: "Any Specific Health Condition",
            type: "text",
            name: "Health",
            placeholder: "Nothing",
            mandatory: false,
          },
        ],
      },
    ],
    2: [
      {
        header:
          "Select the options that align with your body type and tendencies, using insights from 15 years of data to help us understand your Ayurvedic constitution and create a personalized wellness plan.",
        fields: [
          {
            label: "Pace of Performing Work?",
            type: "radio",
            name: "2a_performance",
            options: [
              "Fast, Always in Hurry ",
              "Medium, Energetic ",
              "Slow, Steady ",
            ],
            mandatory: true,
          },
          {
            label: "Memory?",
            type: "radio",
            name: "2b_memory",
            options: [
              "Quick to understand, quick to forget",
              "Moderate",
              "Slow to understand, slow to forget",
            ],
            mandatory: true,
          },
        ],
      },
      {
        header:
          "Select the options that align with your body type and tendencies, using insights from 15 years of data to help us understand your Ayurvedic constitution and create a personalized wellness plan.",
        fields: [
          {
            label: "Sleep Pattern?",
            type: "radio",
            name: "2c_sleep",
            options: [
              "Less than 6 hours, easily disturbed by small sounds",
              "6–8 hours, deep and sound sleep",
              "More than 8 hours, with a strong desire for extended sleep",
            ],
            mandatory: true,
          },
          {
            label: "Which climate you dislike?",
            type: "radio",
            name: "2d_climate",
            options: ["Winter", "Monsoon", "Summer"],
            mandatory: true,
          },
        ],
      },
      {
        header:
          "Select the options that align with your body type and tendencies, using insights from 15 years of data to help us understand your Ayurvedic constitution and create a personalized wellness plan.",
        fields: [
          {
            label: "Hunger (desire to eat)?",
            type: "radio",
            name: "2e_hunger",
            options: [
              "I don't feel strongly about meals and can easily skip them",
              "I usually have a calm and steady feeling of hunger, but it's not urgent",
              "I experience intense cravings or a strong desire for food at certain times",
            ],
            mandatory: true,
          },
          {
            label: "Body Temperature?",
            type: "radio",
            name: "2f_BodyTemperature",
            options: [
              "Feels neutral and balanced",
              "Feels warmer than usual",
              "Feels cooler than usual",
            ],
            mandatory: true,
          },
        ],
      },
      {
        header:
          "Select the options that align with your body type and tendencies, using insights from 15 years of data to help us understand your Ayurvedic constitution and create a personalized wellness plan.",
        fields: [
          {
            label: "Stamina?",
            type: "radio",
            name: "2g_Stamina",
            options: [
              "Low Energy, gets easily tired ",
              "Moderate Energy",
              "Excellent Energy",
            ],
            mandatory: true,
          },
          {
            label: "Bowel Movements?",
            type: "radio",
            name: "2h_Bowel",
            options: [
              "Variable or occasional issues like loose stools or constipation",
              "Regular and well-formed stools, good digestion",
              "Irregular or difficult bowel movements, inconsistent digestion ",
            ],
            mandatory: true,
          },
        ],
      },
      {
        header:
          "Select the options that align with your body type and tendencies, using insights from 15 years of data to help us understand your Ayurvedic constitution and create a personalized wellness plan.",
        fields: [
          {
            label: "Reaction under adverse situation?",
            type: "radio",
            name: "2i_Reaction",
            options: [
              "Anxiety, worry, Irritability ",
              "Anger, Aggression ",
              "Calm, Revulsive, sometimes Depressive ",
            ],
            mandatory: true,
          },
        ],
      },
    ],
    3: [
      {
        header: "SYMPTOMS CATEGORY 1 ",
        description:
          "Please select the symptoms you're currently experiencing to help us assess your body's present state and provide tailored recommendations for balance.",
        allowSkip: true, // Enable Skip Button for this set
        fields: [
          {
            name: "vikrutiSymptoms1",
            type: "checkbox",
            options: [
              "Lack of strength",
              "Desire for hot things (craving warmth)",
              "Bloating",
              "Emaciation (skinny/sudden weight loss) ",
            ],
          },
          {
            name: "vikrutiSymptoms1.1",
            type: "checkbox",
            options: [
              "Reduction in sleep duration ",
              "Difficulty in breathing",
              "Constipation ",
              "Over talkativeness ",
            ],
          },
          {
            name: "vikrutiSymptoms1.2",
            type: "checkbox",
            options: ["Depression,anxiety", "Body pain", "Roughness in skin"],
          },
        ],
      },
      {
        header: "SYMPTOMS CATEGORY 2 ",
        description:
          "Please select the symptoms you're currently experiencing to help us assess your body's present state and provide tailored recommendations for balance.",
        allowSkip: true, // Enable Skip Button for this set
        fields: [
          {
            name: "vikrutiSymptoms2",
            type: "checkbox",
            options: [
              "Decreased activity (lethargy)",
              "Debility(physical weakness)",
              "Tiredness if after speaking after few words",
            ],
          },
        ],
      },
      {
        header: "SYMPTOMS CATEGORY 3 ",
        description:
          "Please select the symptoms you're currently experiencing to help us assess your body's present state and provide tailored recommendations for balance.",
        allowSkip: true, // Enable Skip Button for this set
        fields: [
          {
            name: "vikrutiSymptoms3.1",
            type: "checkbox",
            options: [
              "Burning sensation",
              "Increased heat in the body",
              "Excessive hunger",
              "Sleep lessness",
            ],
          },
          {
            name: "vikrutiSymptoms3.2",
            type: "checkbox",
            options: [
              "Fainting and exhaustion",
              "Acidic belching(acidic burps)",
              "Reddish discoloration of body",
            ],
          },
          {
            name: "vikrutiSymptoms3.3",
            type: "checkbox",
            options: [
              "Excessive thirst",
              "Increased sweating",
              "Yellowish discoloration of stools, urine , skin and eye ",
            ],
          },
        ],
      },
      {
        header: "SYMPTOMS CATEGORY 4 ",
        description:
          "Please select the symptoms you're currently experiencing to help us assess your body's present state and provide tailored recommendations for balance.",
        allowSkip: true, // Enable Skip Button for this set
        fields: [
          {
            name: "vikrutiSymptoms4",
            type: "checkbox",
            options: [
              "Poor digestion ",
              "Cold feet and hands",
              "Lack of warmth",
              "Dullness",
            ],
          },
        ],
      },
      {
        header: "SYMPTOMS CATEGORY 5",
        description:
          "Please select the symptoms you're currently experiencing to help us assess your body's present state and provide tailored recommendations for balance.",
        allowSkip: true, // Enable Skip Button for this set
        fields: [
          {
            name: "vikrutiSymptoms5.1",
            type: "checkbox",
            options: [
              "Feeling of heaviness in body part",
              "Lethargy laziness",
              "Aversion of food(strong dislike towards a particular food)",
              "Nausea",
            ],
          },
          {
            name: "vikrutiSymptoms5.2",
            type: "checkbox",
            options: [
              "Heaviness in the chest",
              "Excessive fat",
              "Swelling in the body or parts of body",
              "Itching",
            ],
          },
          {
            name: "vikrutiSymptoms5.3",
            type: "checkbox",
            options: [
              "In digestion",
              "Excessive sleep",
              "Breathlessnes",
              "Excessive salivation",
            ],
          },
        ],
      },
      {
        header: "SYMPTOMS CATEGORY 6",
        description:
          "Please select the symptoms you're currently experiencing to help us assess your body's present state and provide tailored recommendations for balance.",
        allowSkip: true, // Enable Skip Button for this set
        fields: [
          {
            name: "vikrutiSymptoms6",
            type: "checkbox",
            options: [
              "Dryness in the skin",
              "Lack of sleep",
              "Tiredness /weakness",
              "Frequent infection",
            ],
          },
        ],
      },
    ],
    4: [
      {
        header:
          "Please select any symptoms you’re currently experiencing that may indicate inflammation in your body Identifying these signs helps us better understand areas that may need attention",
        allowSkip: true,
        fields: [
          {
            name: "inflamation1",
            type: "checkbox",
            options: [
              "Swelling in the body parts",
              "Lack of strength",
              "Low immunity",
              "Poor metabolism",
            ],
          },
          {
            name: "inflamation2",
            type: "checkbox",
            options: [
              "Decreased sweating",
              "Tiredness",
              "Indigestion",
              "The body is producing dirty or unhealthy mucus",
            ],
          },
          {
            name: "inflamation3",
            type: "checkbox",
            options: [
              "Tastelessness",
              "Lethargy(lack of energy and enthusiasm)",
              "Thirst",
            ],
          },
        ],
      },
      {
        header:
          "Please select any symptoms you’re currently experiencing that may indicate non-inflammatory conditions in your body. This helps us tailor recommendations specific to your wellness needs",
        allowSkip: true,
        fields: [
          {
            name: "noninflamation1",
            type: "checkbox",
            options: [
              "Balanced digestion of food",
              "Free and effortless expulsion of stool",
              "Appreciate the taste of food",
              "Absence of tiredness",
            ],
            // mandatory: true,
          },
          {
            name: "noninflamation1.2",
            type: "checkbox",
            options: [
              " Feeling of lightness of the body",
              " Feeling energetic in body",
              "Absence of swelling and stiffness in the body",
            ],
            // mandatory: true,
          },
        ],
      },
    ],
    5: [{ last: "yes", fields: [{ dummy1: "" }] }],
  };

  //For setting the UserName
  // useEffect(() => {
  //   setFormData({ ...formData, []: userDetails })
  // },[userDetails])

  useEffect(() => {
    const isToken = url.includes("token");
    console.log("isToken", isToken);
    if (!isToken) {
      const fetchUser = async () => {
        try {
          const User = await axios.get(
            "https://aivotayur.com/api/loginDetails",
            {
              params: { email }, // Pass the email as a query parameter
            }
          );
          setUserDetails(User.data.LoginData);
          setFormData({
            fullName: User.data.LoginData.fullName || "",
            height: User.data.LoginData.height || "",
            weight: User.data.LoginData.weight || "",
            age: User.data.LoginData.age || "",
            contactno: User.data.LoginData.contactno || "",
            Activity: User.data.LoginData.Activity || "",
            sex: User.data.LoginData.sex || "",
            Health: User.data.LoginData.Health || "",
          });
        } catch (error) {
          console.error("Error fetching user:", error);
        }
      };

      fetchUser();
    } else {
      const token = url.split("=")[1];
      console.log(token, url);
      const verifyToken = async () => {
        try {
          const res = await axios.get("https://aivotayur.com/api/token", {
            params: { token },
          });
          console.log("res of token", res);
          if (res.status !== 200) {
            navigate("/");
          }
        } catch (error) {
          console.log("error in token validation", error.response?.message);
          navigate("/");
        }
      };

      verifyToken();
    }
  }, []);

  useEffect(() => {
    console.log("'user details", userDetails);
  }, [userDetails]);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setAnsAdded(true);
    console.log(name, value, type, checked);

    if (type === "checkbox") {
      const values = formData[name] || [];
      setFormData({
        ...formData,
        [name]: checked
          ? [...values, value]
          : values.filter((val) => val !== value),
      });
    } else if (type === "radio") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: checked ? value : prevData[name],
      }));
    } else if (type === "number") {
      // Prevent negative values
      if (value >= 0 || value === "") {
        setFormData({ ...formData, [name]: value });
      }
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  useEffect(() => {
    // console.log(document.querySelectorAll('input[type="checkbox"]'))
    if (
      Array.from(document.querySelectorAll('input[type="checkbox"]')).some(
        (checkbox) => checkbox.checked
      )
    )
      setAnsAdded(true);
    else setAnsAdded(false);
  }, [currentStep, currentSet]);

  const handleSubmitSet = () => {
    setAns((val) => ({ ...val, ...formData }));

    const currentQuestions =
      questionSets[currentStep]?.[currentSet - 1]?.fields || [];
    let isValid = true;

    currentQuestions.some((field) => {
      if (field.mandatory) {
        if (
          (field.type === "text" || field.type === "select") &&
          (!formData[field.name] || formData[field.name].trim() === "")
        ) {
          isValid = false;

          toast.error(`${field.labelText} is mandatory!`, {
            autoClose: 3000, // Closes the toast after 3 seconds
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            style: {
              backgroundColor: "#FBD9D9",
              color: "#000", // Optional: Set text color for readability
            },
          });
          return true; // Exit the loop
        }
        if (field.name === "height") {
          const heightValue = parseFloat(formData[field.name]);
          if (isNaN(heightValue) || heightValue <= 0 || heightValue > 3) {
            isValid = false;
            toast.error(
              "Height must be a positive number and should not exceed 3 meters!",
              {
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                style: {
                  backgroundColor: "#FBD9D9",
                  color: "#000", // Optional: Set text color for readability
                },
              }
            );
            return true; // Exit the loop
          }
        }

        // Validation for Weight
        if (field.name === "weight") {
          const weightValue = parseInt(formData[field.name], 10);
          if (isNaN(weightValue) || weightValue <= 0 || weightValue > 999) {
            isValid = false;
            toast.error(
              "Weight must be a positive number and should not exceed 999 kg!",
              {
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                style: {
                  backgroundColor: "#FBD9D9",
                  color: "#000", // Optional: Set text color for readability
                },
              }
            );
            return true; // Exit the loop
          }
        }
        if (field.name === "age") {
          const ageValue = parseInt(formData[field.name], 10);

          // const ageValue = formData[field.name] ?.trim()? parseInt(formData[field.name].trim(), 10) : NaN;
          console.log("Parsed Age Value:", ageValue);
          console.log("Validating Age:", ageValue);
          if (isNaN(ageValue) || ageValue < 12) {
            isValid = false;
            toast.error(
              <>
                <b>Age Limit Alert</b>
                <br />
                Age below 12 is not eligible for this Ayurvedic test.
              </>,
              {
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                style: {
                  backgroundColor: "#FBD9D9",
                  color: "#000", // Optional: Set text color for readability
                },
              }
            );
            return true; // Exit the loop
          }
        }

        if (
          field.name === "contactno" &&
          !/^\d{10}$/.test(formData[field.name])
        ) {
          isValid = false;
          toast.error("Contact No. must be a 10-digit number!", {
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            style: {
              backgroundColor: "#FBD9D9",
              color: "#000", // Optional: Set text color for readability
            },
          });
          return true; // Exit the loop
        }
        if (
          field.type === "radio" &&
          (!formData[field.name] || formData[field.name].length === 0)
        ) {
          isValid = false;

          toast.error(`${field.label} is mandatory!`, {
            autoClose: 3000, // Closes the toast after 3 seconds
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            style: {
              backgroundColor: "#FBD9D9",
              color: "#000", // Optional: Set text color for readability
            },
          });
          return true; // Exit the loop
        }
        // if (
        //   field.type === "checkbox" &&
        //   (!formData[field.name] || formData[field.name].length === 0)
        // ) {
        //   isValid = false;
        //   // alert(`${field.header} is mandatory!`);
        //   toast.error(`This question is mandatory!`, {
        //     // position: toast.POSITION.TOP_CENTER,
        //     autoClose: 3000, // Closes the toast after 3 seconds
        //     hideProgressBar: true,
        //     closeOnClick: true,
        //     pauseOnHover: true,
        //     draggable: true, });
        //   return true; // Exit the loop
        // }
        if (field.type === "checkbox") {
          const groupedCheckboxFields = [
            "noninflamation1",
            "noninflamation1.2",
          ]; // Add all related checkbox field names here
          const isAnyChecked = groupedCheckboxFields.some(
            (groupName) => formData[groupName] && formData[groupName].length > 0
          );
          if (!isAnyChecked) {
            isValid = false;
            toast.error(`question is mandatory!`, {
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              style: {
                backgroundColor: "#FBD9D9",
                color: "#000", // Optional: Set text color for readability
              },
            });
            return true; // Exit validation for this field group
          }
        }
      }

      return false; // Continue to the next field
    });

    if (isValid) {
      nextSet();
      console.log("updated ans", ans);
    }
  };

  const nextSet = () => {
    const totalSetsForCurrentStep = steps[currentStep - 1].sets;
    // setFormData({})

    if (currentSet < totalSetsForCurrentStep) {
      setCurrentSet(currentSet + 1);
    } else if (currentStep < steps.length) {
      setCurrentStep(currentStep + 1);
      setCurrentSet(1);
    }
    setAnsAdded(false);
    // console.log("updated ans", ans);
  };

  const prevSet = () => {
    if (currentSet > 1) {
      setCurrentSet(currentSet - 1);
    } else if (currentStep > 1) {
      const previousStepSets = steps[currentStep - 2].sets;
      setCurrentStep(currentStep - 1);
      setCurrentSet(previousStepSets);
    }
  };

  const skipSet = () => {
    const totalSetsForCurrentStep = steps[currentStep - 1].sets;

    if (currentSet < totalSetsForCurrentStep) {
      setCurrentSet(currentSet + 1); // Skip to the next set
    } else if (currentStep < steps.length) {
      setCurrentStep(currentStep + 1); // Skip to the next step
      setCurrentSet(1); // Reset to the first set of the next step
    }
    setAnsAdded(false);
  };

  const calculateProgressForStep = () => {
    const totalSetsForCurrentStep = steps[currentStep - 1].sets;
    return ((currentSet - 1) / totalSetsForCurrentStep) * 100;
  };

  const isStepCompleted = (step) =>
    step < currentStep ||
    (step === currentStep &&
      currentSet === totalSetsForCurrentStep &&
      setSubmitted);

  return (
    <div className="Test-main-Container">
      {/* {!enduser ? (<Header />) : null} */}
      <Header />
      <div className="Test-wrap">
        <div className="dynamic-multi-step-form">
          {/* Steps Header */}
          <div className="steps-container">
            {steps.map((step, index) => (
              <React.Fragment key={step.step}>
                <div
                  className={`step-container ${
                    index === steps.length - 1 ? "w-[8%]" : "w-[22.5%]"
                  } relative h-fit`}
                >
                  <div
                    className={`step ${
                      isStepCompleted(step.step)
                        ? "completed"
                        : currentStep === step.step
                        ? "active"
                        : "pending"
                    }`}
                  >
                    {step.step}
                  </div>
                  {/* Step Description for Desktop */}
                  <div className="step-description desktop-only">
                    {step.label1}
                    <br />
                    <div className="step-text">{step.label2}</div>
                    <div
                      className={`step-status ${
                        isStepCompleted(step.step)
                          ? "completed"
                          : currentStep === step.step
                          ? "active"
                          : "pending"
                      }`}
                    >
                      {isStepCompleted(step.step) ? "Completed" : "Pending"}
                    </div>
                  </div>

                  {index < steps.length - 1 && (
                    <div className="progress-indicator-container absolute hideen md:block">
                      <div
                        className={`h-[10px] w-[10px] rounded-full bg-white z-[30] absolute top-0 transition duration-300 ease-in-out`}
                        style={{
                          border: "2px solid #085946",
                          left:
                            currentStep === step.step
                              ? `${calculateProgressForStep() - 2}%`
                              : isStepCompleted(step.step)
                              ? "105%"
                              : "-5%",
                        }}
                      ></div>
                      <div
                        className="progress-indicator"
                        style={{
                          width:
                            currentStep === step.step
                              ? `${calculateProgressForStep()}%`
                              : isStepCompleted(step.step)
                              ? "100%"
                              : "0%",
                        }}
                      ></div>
                    </div>
                  )}

                  {/* <div className="step-description ">
                    {step.label1}
                    <br></br>
                    <div className="step-text">{step.label2}</div>
                    <div
                      className={`step-status ${
                        isStepCompleted(step.step)
                          ? "completed"
                          : currentStep === step.step
                          ? "active"
                          : "pending"
                      }`}
                    >
                      {isStepCompleted(step.step) ? "Completed" : "Pending"}
                    </div>
                  </div> */}
                </div>
              </React.Fragment>
            ))}
            {/* Mobile Continuous Progress Bar */}
          </div>
          <div className="progress-bar-mobile mt-4 md:hidden">
            <div
              className="progress-bar-fill"
              style={{
                width: `${(currentStep / steps.length) * 100}%`,
              }}
            ></div>
          </div>
          {/* Step Description for Mobile */}
          {/* {steps.map((step)=>
            {currentStep === step.step && !isStepCompleted(step.step) && (
                    <div className="step-description mobile-only">
                      {step.label1}
                      <br />
                      <div className="step-text">{step.label2}</div>
                      <div
                      className={`step-status ${
                        isStepCompleted(step.step)
                          ? "completed"
                          : currentStep === step.step
                          ? "active"
                          : "pending"
                      }`}
                    >
                      {isStepCompleted(step.step) ? "Completed" : "Pending"}
                    </div>
                    </div>
                  )}
                  )} */}
          {steps
            .filter((step) => step.step === currentStep) // Ensure only active step is rendered
            .map((step) => (
              <div
                className="step-description mobile-only"
                key={`mobile-desc-${step.step}`}
              >
                {step.label1}
                <br />
                <div className="step-text">{step.label2}</div>
                <div
                  className={`step-status ${
                    isStepCompleted(step.step)
                      ? "completed"
                      : currentStep === step.step
                      ? "active"
                      : "pending"
                  }`}
                >
                  {isStepCompleted(step.step) ? "Completed" : "Pending"}
                </div>
              </div>
            ))}

          {/* Question Content */}
          <div className="question-content">
            {/* Question Set */}
            {!setSubmitted && (
              <QuestionSet
                qIndex={currentSet - 1}
                setAns={setAns}
                questions={questionSets[currentStep]?.[currentSet - 1]} // Pass current question set
                formData={formData}
                handleInputChange={handleInputChange}
                skipSet={skipSet}
                setVideo={setVideo}
                ans={ans}
              />
            )}
            <div className="navigation-buttons rounded-b-lg">
              {/* <div className="w-full flex justify-end mx-10"> */}
              <div  className={`w-full flex ${currentStep === 1 ? 'justify-center tabPotrait:justify-end' : 'justify-end'} mx-10`}>
              {currentStep > 1 && (
                <button
                  className="hover:scale-[95%] transition-all duration-500 ease-in-out w-40 h-10 bg-transparent border-2 border-cbg2 text-cbg2 hover:bg-cbg2 hover:text-white"
                  onClick={prevSet}
                  disabled={currentStep === 1 && currentSet === 1}
                >
                  Previous
                </button>
              )}
                {!setSubmitted ? (
                  <button
                    className="hover:scale-[95%] transition-all duration-500 ease-in-out w-40 h-10 bg-cbg2 text-white"
                    onClick={handleSubmitSet}
                  >
                    {questionSets[currentStep]?.[currentSet - 1].allowSkip &&
                    !isAnsAdded
                      ? "Skip"
                      : "Next"}
                  </button>
                ) : (
                  <button
                    className="hover:scale-[95%] transition-all duration-500 ease-in-out w-40 h-10 bg-cbg2 text-white"
                    onClick={nextSet}
                  >
                    {currentStep === steps.length &&
                    currentSet === totalSetsForCurrentStep
                      ? "Finish"
                      : "Next"}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};
export default Test;
