import React, { useState, useEffect, useRef } from "react";
import "./Dashboard.css";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header/Header";
import HomeLeft from "../../assets/Homeleft.png";
import HomeRight from "../../assets/phytotherapy-products-arrangement-still-life.png";
import Yoga from "../../assets/yoga.png";
import Restart from "../../assets/restart.png";
import Camera from "../../assets/camera.png";
import Doshas from "../../assets/Ayurveda doshas pic.png";
import DR from "../../assets/Rashmi mam pic.png";
import Prof from "../../assets/Prof Bimay Roy.png";
import Dr from "../../assets/Sandeep Sir pic.png";
import Ceo from "../../assets/Alok Tiwari.png";
import Hr from "../../assets/Nidhi Tiwari.png";
import Md from "../../assets/Pradeep gupta.png";
import Cto from "../../assets/Sarmila roy.png";
import Footer from "../../components/Footer/Footer";
import HomeTop from "../../assets/home_mob_top.png";

import rs_mam from "../../assets/rashmi_mam.png";
import cto_mam from "../../assets/cto_mam.png";
import nidhi_mam from "../../assets/nidhi_mam.png";
import prof from "../../assets/prof_sir.png";
import ceo from "../../assets/alok_sir.png";
import md from "../../assets/md_sir.png";
import sandeep_sir from "../../assets/sandeep_sir.png";

const Dashboard = () => {
  const scrollContainerRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const navigate = useNavigate();

  const [advb, setAdvb] = useState(1);
  const [ldb, setLdb] = useState(1);

  const aNext = () => {
    if (advb === 3) return;
    else {
      setAdvb(advb + 1);
    }
  };

  const aPrev = () => {
    if (advb === 1) return;
    else {
      setAdvb(advb - 1);
    }
  };

  const lNext = () => {
    if (ldb === 4) return;
    else {
      setLdb(ldb + 1);
    }
  };

  const lPrev = () => {
    if (ldb === 1) return;
    else {
      setLdb(ldb - 1);
    }
  };

  const displayedItems = 3;

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - scrollContainerRef.current.offsetLeft);
    setScrollLeft(scrollContainerRef.current.scrollLeft);
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - scrollContainerRef.current.offsetLeft;
    const walk = (x - startX) * 2; // Adjust scrolling speed
    scrollContainerRef.current.scrollLeft = scrollLeft - walk;
  };

  useEffect(() => {
    const handleMouseUp = () => setIsDragging(false);
    window.addEventListener("mouseup", handleMouseUp);

    return () => window.removeEventListener("mouseup", handleMouseUp);
  }, []);

  const handleMouseUp = () => {
    setIsDragging(false);
    const container = scrollContainerRef.current;
    const scrollLeft = container.scrollLeft;
    const childWidth = container.scrollWidth / displayedItems;
    console.log("Scroll Left:", scrollLeft);
    console.log("Child Width:", childWidth);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (!scrollContainerRef.current) return;
      const container = scrollContainerRef.current;
      const scrollLeft = container.scrollLeft;
      const childWidth = container.scrollWidth / displayedItems;

      const newIndex = Math.round(container.scrollLeft / childWidth);
      setCurrentIndex(newIndex);
    };

    const container = scrollContainerRef.current;
    container.addEventListener("scroll", handleScroll);

    return () => {
      container.removeEventListener("scroll", handleScroll);
    };
  }, [displayedItems]);

  const handleAssessmentStart = () => {
    navigate("/test"); // Replace "/test" with the actual path of your test page
  };

  const scrollToIndex = (index) => {
    if (!scrollContainerRef.current) return;
    const childWidth = scrollContainerRef.current.scrollWidth / displayedItems;
    scrollContainerRef.current.scrollTo({
      left: index * childWidth,
      behavior: "smooth",
    });
  };

  return (
    <div style={{ boxSizing: "border-box" }}>
      {/* D E S K T O P */}
      <div className="Main-container xxs:hidden tabPotrait:flex flex-col">
        <Header />
        <div className="flex flex-row mt-[12vh]">
          <div className="Left-container">
            <img src={HomeLeft} alt="Image" className="HomeLeft-Image" />
          </div>
          <div className="Middle-container px-6">
            <div className="flex text-center text-3xl pcs:text-4xl font-bold">
              Aivotayur
            </div>
            <div className="flex text-center text-3xl pcs:text-4xl text-[#393434] font-[450] uppercase tab:mt-5 pcs:mt-12 pc:mt-20">
              REVOLUTIONIZE YOUR HEALTH JOURNEY WITH AYURVEDA & AI
            </div>
            <div className="flex text-center text-xl pcs:text-2xl mt-5 pcs:mt-10">
              Merge traditional Ayurvedic principles with the power of
              artificial intelligence. Discover tailored recommendations that
              nurture your mind, body, and spirit for a vibrant life.
            </div>
            <button
              className="mt-5 tab:mt-8 pcs:mt-10 bg-cbg2 text-white px-12 py-3 rounded-md hover:scale-95 transition-all duration-300 ease-in-out"
              onClick={handleAssessmentStart}
            >
              Start your Journey Today
            </button>
          </div>
          <div className="Right-container">
            <img src={HomeRight} alt="Image" className="HomeRight-Image" />
          </div>
        </div>
        <div className="Aivotayur-why px-20">
          <div className="Aivotayur-why-text">Why Ayurveda & AI?</div>
          <div className="Aivotayur-why-text2">
            Welcome to a unique health solution that bridges the timeless wisdom
            of Ayurveda with the power of AI. Our platform assesses your unique
            body constitution, or ‘Prakriti,’ and current state, or ‘Vikruti,’
            to give you insights on diet, lifestyle, and wellness practices
            tailored just for you. Achieve optimal balance, vitality, and health
            by following an approach that’s customized to your needs.
          </div>
        </div>
        <div className="VP-Wrap">
          <div className="Prakriti">
            <div className="Prakriti-text">Prakriti</div>
            <div className="Prakriti-text2">
              Prakriti is your body’s inherent constitution or natural balance,
              which remains constant throughout your life. It’s based on the
              unique combination of the three doshas (Vata, Pitta, and Kapha)
              that Ayurveda considers the primary forces in every body.
              Understanding one’s Prakriti helps users know their optimal state
              of health.
            </div>
            <button
              className="bg-cbg2 text-white py-2 px-20 mt-5 rounded-md  hover:scale-95 transition-all duration-300 ease-in-out"
              onClick={() => navigate("/learn")}
            >
              {" "}
              Learn More
            </button>
          </div>
          <div className="Vikruti">
            <div className="Vikruti-text">Vikruti</div>
            <div className="Vikruti-text2">
              Vikruti is your current state, which can change with lifestyle,
              diet, environment, and stress. When Vikruti is out of alignment
              with Prakriti, imbalances occur, leading to potential health
              issues. The platform assesses both states to determine any
              discrepancies, giving users practical, everyday guidance to regain
              balance.
            </div>
            <button
              className="bg-cbg2 text-white py-2 px-20 mt-5 rounded-md hover:scale-95 transition-all duration-300 ease-in-out"
              onClick={() => navigate("/learn")}
            >
              {" "}
              Learn More
            </button>
          </div>
        </div>
        <div className="Work-wrap">
          <div className="HowWork mt-8">How it Works?</div>
          <div className="Itworks-wrap">
            <div className="work-box">
              <img src={Yoga} className="yoga" />
              <div className="Text">Discover Your Body Type (Prakriti)</div>
              <div className="para text-xl">
                Answer a quick set of questions to determine your Ayurvedic body
                constitution, or Prakriti, giving us insights into your natural
                strengths and tendencies.
              </div>
            </div>
            <div className="work-box">
              <img src={Restart} className="yoga" />
              <div className="Text">Assess Your Current State (Vikruti)</div>
              <div className="para text-xl">
                {" "}
                Complete a lifestyle and health assessment, covering your
                current habits and any signs of inflammation. This helps us
                identify your Vikruti and health areas to focus on.
              </div>
            </div>
            <div className="work-box">
              <img src={Camera} className="yoga" />
              <div className="Text">Capture Your Health Profile</div>
              <div className="para text-xl">
                Finish with a 60-second video. Our AI will analyze your facial
                features and heart rate to provide a final, tailored health
                profile.
              </div>
            </div>
          </div>
        </div>
        <div className="Ayurveda">
          <div className="Ayur-Benefits">
            Experience the Transformative Benefits of Ayurveda
          </div>
          <div className="Ayur-box">
            <img src={Doshas} className="Doshaspic" />
            <div className="Doshastext-wrap">
              <div className="Doshastext">
                <span className="Doshastextbold">
                  Personalized Diet & Lifestyle Recommendations{" "}
                </span>{" "}
                - Get tailored dietary suggestions and lifestyle tips that work
                for your unique body type and current health state.
              </div>
              <div className="Doshastext">
                <span className="Doshastextbold">Balance Your Body & Mind</span>{" "}
                - Achieve harmony by following Ayurveda’s holistic approach to
                health, which addresses both physical and mental well-being.
              </div>
              <div className="Doshastext">
                {" "}
                <span className="Doshastextbold">
                  Track Your Health Progress
                </span>{" "}
                - Use our periodic assessments to monitor your health journey
                and adjust your recommendations as you improve.
              </div>
              <div className="Doshastext">
                <span className="Doshastextbold">
                  Safe, Natural, and Proven
                </span>{" "}
                - Rely on age-old Ayurvedic principles validated by modern
                technology for a safe and sustainable health journey.
              </div>
            </div>
          </div>
        </div>
        <div className="Advisory-Board">
          <div className="Advisory-text">Advisory Board</div>
          <div className="Advisory-text2 px-20 pb-5">
            Our esteemed Advisory Board comprises industry veterans and thought
            leaders, guiding AIVOT with strategic insights and expertise.Their
            visionary advice shapes our innovation roadmap and ensures we stay
            ahead in the rapidly evolving tech landscape.
          </div>
          <div className="Adviory-photo-box">
            <div className="Photo-box1">
              <img src={Prof} className="photo1 rounded-xl" />
              <div className="caption">
                Prof. Bimal Roy<br></br>Chief Strategic Advisor
              </div>
              <div className="rounded-xl absolute top-0 left-0 h-full w-full z-[1] bg-black opacity-20"></div>
              <div className="advisory-text-overlay rounded-xl">
                <p className="px-4 text-small xl:text-base text-center">
                  He is one of the best cryptologist. Ex Chairman (NSC, India),
                  Ex Director (ISI, Kolkata). Prof Roy was awarded Padma Shree,
                  one of the highest honors bestowed by India on a person,
                  recognizing his accomplishments and contribution to
                  education.​
                </p>
              </div>
            </div>
            <div className="Photo-box1">
              <img src={Dr} className="photo1 rounded-xl" />
              <div className="caption">
                Dr Sandeep Jyot<br></br>Chief Clinical Advisor
              </div>
              <div className="rounded-xl absolute top-0 left-0 h-full w-full z-[1] bg-black opacity-20"></div>
              <div className="advisory-text-overlay rounded-xl">
                <p className="px-4 text-small xl:text-base text-center">
                  He is MBBS along with Fellowship in Diabetes, MMSc in
                  Endocrinology and Diploma in Family Medicine. He served
                  government as IRS officer and founded Sound Healing Institute
                  for peoples wellness and holistic lifestyle.​​
                </p>
              </div>
            </div>
            <div className="Photo-box1">
              <img src={DR} className="photo1dr rounded-xl" />
              <div className="caption">
              Dr. Rashmi Manjunatha Nookala<br></br>Subject Matter Expert
              </div>
              <div className="rounded-xl absolute top-0 left-0 h-full w-full z-[1] bg-black opacity-20"></div>
              <div className="advisory-text-overlay rounded-xl">
                <p className="px-4 text-small xl:text-base text-center">
                  Dr. Rashmi Manjunatha Nookala, founder of Parivartan Ayuryoga Wellness
                  Center, has 20+ years of expertise in Ayurveda and Yoga
                  Therapy. She has treated 10,000+ patients and trained
                  thousands worldwide, specializing in Panchakarma detox and
                  yoga for lifestyle disorders. Her mission: to help you become
                  symptom-free, medicine-free, and empowered through natural
                  healing.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="Leadership-Board">
          <div className="Leadership-text">Our Leadership Team</div>
          <div className="Leadership-text2 px-20 pb-5">
            Our leadership team is composed of highly skilled professionals,
            each bringing a wealth of experience and expertise. Together, they
            guide AIVOT toward innovation and growth across all dimensions.
          </div>
          <div className="Leadership-photo-box">
            <div className="Photo-box2">
              <img src={Md} className="photo2 rounded-xl" />
              <div className="caption">
                Pradeep Gupta<br></br>Managing Director
              </div>
              <div className="rounded-xl absolute top-0 left-0 h-full w-full z-[1] bg-black opacity-20"></div>
              <div className="leadership-text-overlay rounded-xl">
                <p className="px-4 text-small xl:text-base text-center">
                  A technocrat, Management Professional and ex bureaucrat with
                  over 34 Years of rich experience of working in manufacturing
                  org, Gov. of India in the fields of public policies,
                  governance, planning, Management and technology.
                </p>
              </div>
            </div>
            <div className="Photo-box2">
              <img src={Ceo} className="photo2 rounded-xl" />
              <div className="caption">
                Alok Tiwari<br></br>Founder & CEO
              </div>
              <div className="rounded-xl absolute top-0 left-0 h-full w-full z-[1] bg-black opacity-20"></div>
              <div className="leadership-text-overlay rounded-xl">
                <p className="px-4 text-small xl:text-base text-center">
                  With over 19 years of professional experience, Alok Kumar
                  Tiwari has specialized in Merger & Acquisition, along with
                  serving as a Financial and Strategic Advisor. His extensive
                  expertise in these areas has been instrumental in guiding
                  successful transactions and providing strategic insights for
                  business growth.
                </p>
              </div>
            </div>
            <div className="Photo-box2">
              <img src={Cto} className="photo2  rounded-xl" />
              <div className="caption">
                Sarmila Roy<br></br>CTO
              </div>
              <div className="rounded-xl absolute top-0 left-0 h-full w-full z-[1] bg-black opacity-20"></div>
              <div className="leadership-text-overlay rounded-xl">
                <p className="px-4 text-small xl:text-base text-center">
                  She a is graduate from ISI Kolkata with MS from SUNY Poly.
                  USA. She's having 30+ years of experience in Software
                  Development and achieved technical expertise in Systems
                  Analysis, Development, Machine learning and Deep learning
                  technologies.​
                </p>
              </div>
            </div>
            <div className="Photo-box2">
              <img src={Hr} className="photo2hr  rounded-xl" />
              <div className="caption">
                Nidhi Tiwari<br></br>Director- HR /<br></br>Fund Manager
              </div>
              <div className="rounded-xl absolute top-0 left-0 h-full w-full z-[1] bg-black opacity-20"></div>
              <div className="leadership-text-overlay rounded-xl">
                <p className="px-4 text-small xl:text-base text-center">
                  With an MBA in Finance and over 10 years of extensive
                  experience,Mrs. Nidhi specializes in Financial Planning,
                  Investment Strategy, and Fund Management. Her expertise spans
                  across managing diverse portfolios and crafting strategies
                  that drive sustainable growth and maximize returns.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="Assesment">
          <div className="Assesment-text1">
            Ready to take the first step towards a balanced, healthier life?
          </div>
          <div
            className="w-fit mt-5 mb-10 tab:mt-8 pcs:mt-10 bg-cbg2 text-white px-12 py-3 rounded-md text-xl cursor-pointer hover:scale-95 transition-all duration-300 ease-in-out"
            onClick={handleAssessmentStart}
          >
            Start the Assessment
          </div>
        </div>
        <Footer />
      </div>

      {/* M O B I L E */}
      <div className="w-full flex tabPotrait:hidden flex-col items-center  border-b border-black">
        <Header />
        <img src={HomeTop} className="w-full" />
        <div className="w-full flex flex-col items-center">
          <div className="p-3 flex flex-col items-center gap-3">
            <div className="text-[1.625rem] font-bold">Aivotayur</div>
            <div className="text-center text-xl text-tbg1 font-semibold px-1">
              REVOLUTIONIZE YOUR HEALTH JOURNEY WITH AYURVEDA & AI
            </div>
            <div className="text-center px-3">
              Merge traditional Ayurvedic principles with the power of
              artificial intelligence. Discover tailored recommendations that
              nurture your mind, body, and spirit for a vibrant life.
            </div>
            <div className="px-2 w-full">
              <button
                className="w-full py-5 text-white rounded-lg bg-cbg2"
                onClick={handleAssessmentStart}
              >
                Start your Journey Today
              </button>
            </div>
            <div className="text-center text-2xl font-semibold">
              Why Ayurveda & AI?
            </div>
            <div className="text-center px-2">
              Welcome to a unique health solution that bridges the timeless
              wisdom of Ayurveda with the power of AI. Our platform assesses
              your unique body constitution, or ‘Prakriti,’ and current state,
              or ‘Vikruti,’ to give you insights on diet, lifestyle, and
              wellness practices tailored just for you. Achieve optimal balance,
              vitality, and health by following an approach that’s customized to
              your needs.
            </div>
          </div>
          <div className="mt-3 w-full flex flex-col items-center">
            <div className="bg-cbg3 gap-6 py-5 px-10 flex flex-col items-center text-center">
              <div className="text-2xl">Prakriti</div>
              <div className="leading-7">
                Prakriti is your body’s inherent constitution or natural
                balance, which remains constant throughout your life. It’s based
                on the unique combination of the three doshas (Vata, Pitta, and
                Kapha) that Ayurveda considers the primary forces in every body.
                Understanding one’s Prakriti helps users know their optimal
                state of health.
              </div>
              <div
                className="w-full text-white"
                onClick={() => navigate("/learn")}
              >
                <button className="w-64 py-3 bg-cbg2 rounded-md shadow-cs1">
                  Learn More
                </button>
              </div>
            </div>
            <div className="bg-cbg4 gap-6 py-5 px-10 flex flex-col items-center text-center">
              <div className="text-2xl">Vikruti</div>
              <div className="leading-7">
                Vikruti is your current state, which can change with lifestyle,
                diet, environment, and stress. When Vikruti is out of alignment
                with Prakriti, imbalances occur, leading to potential health
                issues. The platform assesses both states to determine any
                discrepancies, giving users practical, everyday guidance to
                regain balance.
              </div>
              <div
                className="w-full text-white"
                onClick={() => navigate("/learn")}
              >
                <button className="w-64 py-3 bg-cbg2 rounded-md shadow-cs1">
                  Learn More
                </button>
              </div>
            </div>
          </div>
          <div className="py-5 flex flex-col items-center gap-2">
            <div className="text-center text-2xl font-semibold">
              How it Works ?
            </div>
            <div
              className="w-full h-fit p-[20px] gap-[5px] flex flex-row overflow-x-auto snap-x snap-mandatory select-none scrollContainer"
              onMouseDown={handleMouseDown}
              onMouseMove={handleMouseMove}
              onMouseUp={handleMouseUp}
              onMouseLeave={handleMouseUp}
              ref={scrollContainerRef}
              style={{
                cursor: isDragging ? "grabbing" : "grab",
                scrollBehavior: "smooth",
              }}
            >
              <div className="w-full py-4 gap-3 flex flex-col justify-start rounded-xl shadow-cs2 flex-shrink-0 snap-center box-border">
                <img src={Yoga} className="w-10 ml-3" />
                <div className="text-xl font-medium ml-3">
                  Discover Your Body Type (Prakriti)
                </div>
                <div className="text-tbg2 text-lg leading-6 ml-3">
                  Answer a quick set of questions to
                  <br />
                  determine your Ayurvedic body
                  <br />
                  constitution, or Prakriti, giving us
                  <br />
                  insights into your natural strengths
                  <br />
                  and tendencies.
                </div>
              </div>
              <div className="w-full py-4 gap-3 flex flex-col justify-start rounded-xl shadow-cs2 flex-shrink-0 snap-center box-border">
                <img src={Restart} className="w-10 ml-3" />
                <div className="text-xl font-medium ml-3">
                  Assess Your Current State (Vikruti)
                </div>
                <div className="text-tbg2 text-lg leading-6 ml-3">
                  Complete a lifestyle and health
                  <br />
                  assessment, covering your current
                  <br />
                  habits and any signs of inflammation.
                  <br />
                  This helps us identify your Vikruti and
                  <br />
                  health areas to focus on.
                </div>
              </div>
              <div className="w-full py-4 gap-3 flex flex-col justify-start rounded-xl shadow-cs2 flex-shrink-0 snap-center box-border">
                <img src={Camera} className="w-10 ml-3" />
                <div className="text-xl font-medium ml-3">
                  Capture Your Health Profile
                </div>
                <div className="text-tbg2 text-lg leading-6 ml-3">
                  Finish with a 60-second video. Our AI
                  <br />
                  will analyze your facial features and
                  <br />
                  heart rate to provide a final, tailored
                  <br />
                  health profile.
                </div>
              </div>
            </div>

            {/* Dots Indicator with onClick event */}
            <div className="flex justify-center items-center gap-2 cursor-pointer">
              {Array(displayedItems)
                .fill(0)
                .map((_, index) => (
                  <div
                    key={index}
                    className={`w-2 h-2 rounded-full ${
                      currentIndex === index ? "bg-cbg2" : "bg-gray-400"
                    }`}
                    onClick={() => scrollToIndex(index)} // Add onClick to each dot
                  />
                ))}
            </div>
          </div>
          <div className="p-8 pb-0 flex flex-col items-center gap-3">
            <div className="text-center text-2xl font-semibold">
              Experience the Transformative Benefits of Ayurveda
            </div>
            <div className="py-8 px-5 gap-3 flex flex-col justify-start rounded-xl shadow-cs3">
              <img src={Doshas} className="" />
              <div className="text-justify">
                <b>Personalized Diet & Lifestyle Recommendations -</b> Get
                tailored dietary suggestions and lifestyle tips that work for
                your unique body type and current health state.
                <br />
                <br />
                <b>Balance Your Body & Mind -</b> Achieve harmony by following
                Ayurveda’s holistic approach to health, which addresses both
                physical and mental well-being.
                <br />
                <br />
                <b>Track Your Health Progress -</b> Use our periodic assessments
                to monitor your health journey and adjust your recommendations
                as you improve.
                <br />
                <br />
                <b>Safe, Natural, and Proven -</b> Rely on age-old Ayurvedic
                principles validated by modern technology for a safe and
                sustainable health journey.0
                <br />
                <br />
              </div>
            </div>
            <div className="mt-10 mb-5 text-2xl">Advisory Board</div>
          </div>
          <div>
            <div className="text-cbg2 mx-7 text-center rounded-3xl mb-5">
              Our esteemed Advisory Board comprises industry veterans and
              thought leaders, guiding AIVOT with strategic insights and
              expertise.Their visionary advice shapes our innovation roadmap and
              ensures we stay ahead in the rapidly evolving tech landscape.
            </div>
            {advb === 1 ? (
              <div className="w-full px-8">
                <div
                  className="h-[70vw] bg-[#5d5d5d] bg-contain bg-top bg-no-repeat text-white flex flex-col justify-end items-start py-2 px-4"
                  style={{
                    backgroundImage: `url(${prof})`,
                  }}
                >
                  <div className="w-full px-2 bg-black bg-opacity-30 rounded-md flex flex-row justify-between items-center">
                    <div className="flex flex-col justify-center items-start">
                      <div className="text-xl font-semibold">
                        Prof. Bimal Roy
                      </div>
                      <div className="text-base font-medium">
                        Chief Strategic Advisor
                      </div>
                    </div>
                    {/* <div className="h-10 w-10 bg-white rounded-full flex items-center justify-center text-xl text-black font-bold">
                      in
                    </div> */}
                  </div>
                </div>
                <div className="py-5 px-3 text-left h-fit shadow-cs1 mb-5 rounded-b-lg">
                  He is one of the best cryptologist. Ex Chairman (NSC, India),
                  Ex Director (ISI, Kolkata). Prof Roy was awarded Padma Shree,
                  one of the highest honors bestowed by India on a person,
                  recognizing his accomplishments and contribution to education.
                </div>
              </div>
            ) : null}
            {advb === 2 ? (
              <div className="w-full px-8">
                <div
                  className="h-[70vw] bg-[#5d5d5d] bg-contain bg-top bg-no-repeat text-white flex flex-col justify-end items-start py-2 px-4"
                  style={{
                    backgroundImage: `url(${sandeep_sir})`,
                  }}
                >
                  <div className="w-full px-2 bg-black bg-opacity-30 rounded-md flex flex-row justify-between items-center">
                    <div className="flex flex-col justify-center items-start">
                      <div className="text-xl font-semibold">
                        Dr Sandeep Jyot
                      </div>
                      <div className="text-base font-medium">
                        Chief Clinical Advisor
                      </div>
                    </div>
                    {/* <div className="h-10 w-10 bg-white rounded-full flex items-center justify-center text-xl text-black font-bold">
                      in
                    </div> */}
                  </div>
                </div>
                <div className="py-5 px-3 text-left h-fit shadow-cs1 mb-5 rounded-b-lg">
                  He is MBBS along with Fellowship in Diabetes, MMSc in
                  Endocrinology and Diploma in Family Medicine. He served
                  government as IRS officer and founded Sound Healing Institute
                  for peoples wellness and holistic lifestyle.​​
                </div>
              </div>
            ) : null}
            {advb === 3 ? (
              
              <div className="w-full px-8">
                <div
                  className="h-[70vw] bg-[#5d5d5d] bg-contain bg-top bg-no-repeat text-white flex flex-col justify-end items-start py-2 px-4"
                  style={{
                    backgroundImage: `url(${rs_mam})`,
                  }}
                >
                  <div className="w-full px-2 bg-black bg-opacity-30 rounded-md flex flex-row justify-between items-center">
                    <div className="flex flex-col justify-center items-start">
                      <div className="text-xl font-semibold">
                        Dr. Rashmi Manjunatha Nookala
                      </div>
                      <div className="text-base font-medium">
                        Subject Matter Expert
                      </div>
                    </div>
                    {/* <div className="h-10 w-10 bg-white rounded-full flex items-center justify-center text-xl text-black font-bold">
                    in
                  </div> */}
                  </div>
                </div>
                <div className="py-5 px-3 text-left h-fit shadow-cs1 mb-5 rounded-b-lg">
                  Dr. Rashmi Manjunatha Nookala, founder of Parivartan Ayuryoga Wellness
                  Center, has 20+ years of expertise in Ayurveda and Yoga
                  Therapy. She has treated 10,000+ patients and trained
                  thousands worldwide, specializing in Panchakarma detox and
                  yoga for lifestyle disorders. Her mission: to help you become
                  symptom-free, medicine-free, and empowered through natural
                  healing.
                </div>
              </div>
            ) : null}
            <div className="flex flex-row justify-between mx-8">
              <div
                className="border border-[#ECEBEB] shadow-cs1 flex flex-row items-center justify-between w-fit py-2 px-3 gap-3 rounded-2xl"
                onClick={() => aPrev()}
              >
                <div className="h-6 w-6 font-semibold flex justify-center items-center border border-black rounded-full">
                  {"<"}
                </div>
                Prev
              </div>
              <div
                className="border border-[#ECEBEB] shadow-cs1 flex flex-row items-center justify-between w-fit py-2 px-3 gap-3 rounded-2xl"
                onClick={() => aNext()}
              >
                Next
                <div className="h-6 w-6 font-semibold flex justify-center items-center border border-black rounded-full">
                  {">"}
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="w-full text-center mt-10 mb-5 text-2xl">
              Our Leadership Team
            </div>
            <div className="text-cbg2 mx-7 text-center rounded-3xl mb-5">
              Our leadership team is composed of highly skilled professionals,
              each bringing a wealth of experience and expertise. Together, they
              guide AIVOT toward innovation and growth across all dimensions.
            </div>
            {ldb === 1 ? (
             
              <div className="w-full px-8">
                <div
                  className="h-[70vw] bg-[#5d5d5d] bg-contain bg-top bg-no-repeat text-white flex flex-col justify-end items-start py-2 px-4"
                  style={{
                    backgroundImage: `url(${md})`,
                  }}
                >
                  <div className="w-full px-2 bg-black bg-opacity-30 rounded-md flex flex-row justify-between items-center">
                    <div className="flex flex-col justify-center items-start">
                      <div className="text-xl font-semibold">Pradeep Gupta</div>
                      <div className="text-base font-medium">
                        Managing Director
                      </div>
                    </div>
                    {/* <div className="h-10 w-10 bg-white rounded-full flex items-center justify-center text-xl text-black font-bold">
                  in
                </div> */}
                  </div>
                </div>
                <div className="py-5 px-3 text-left h-fit shadow-cs1 mb-5 rounded-b-lg">
                  A technocrat, Management Professional and ex bureaucrat with
                  over 34 Years of rich experience of working in manufacturing
                  org, Gov. of India in the fields of public policies,
                  governance, planning, Management and technology.
                </div>
              </div>
            ) : null}
            {ldb === 2 ? (
           
              <div className="w-full px-8">
                <div
                  className="h-[70vw] bg-[#256D9D] bg-contain bg-top bg-no-repeat text-white flex flex-col justify-end items-start py-2 px-4"
                  style={{
                    backgroundImage: `url(${ceo})`,
                  }}
                >
                  <div className="w-full px-2 bg-black bg-opacity-30 rounded-md flex flex-row justify-between items-center">
                    <div className="flex flex-col justify-center items-start">
                      <div className="text-xl font-semibold">Alok Tiwari</div>
                      <div className="text-base font-medium">Founder & CEO</div>
                    </div>
                    {/* <div className="h-10 w-10 bg-white rounded-full flex items-center justify-center text-xl text-black font-bold">
                in
              </div> */}
                  </div>
                </div>
                <div className="py-5 px-3 text-left h-fit shadow-cs1 mb-5 rounded-b-lg">
                  With over 19 years of professional experience, Alok Kumar
                  Tiwari has specialized in Merger & Acquisition, along with
                  serving as a Financial and Strategic Advisor. His extensive
                  expertise in these areas has been instrumental in guiding
                  successful transactions and providing strategic insights for
                  business growth.
                </div>
              </div>
            ) : null}
            {ldb === 3 ? (
             
              <div className="w-full px-8">
                <div
                  className="h-[70vw] bg-[#5d5d5d] bg-contain bg-top bg-no-repeat text-white flex flex-col justify-end items-start py-2 px-4"
                  style={{
                    backgroundImage: `url(${cto_mam})`,
                  }}
                >
                  <div className="w-full px-2 bg-black bg-opacity-30 rounded-md flex flex-row justify-between items-center">
                    <div className="flex flex-col justify-center items-start">
                      <div className="text-xl font-semibold">Sarmila Roy</div>
                      <div className="text-base font-medium">CTO</div>
                    </div>
                    {/* <div className="h-10 w-10 bg-white rounded-full flex items-center justify-center text-xl text-black font-bold">
              in
            </div> */}
                  </div>
                </div>
                <div className="py-5 px-3 text-left h-fit shadow-cs1 mb-5 rounded-b-lg">
                  She a is graduate from ISI Kolkata with MS from SUNY Poly.
                  USA. She's having 30+ years of experience in Software
                  Development and achieved technical expertise in Systems
                  Analysis, Development, Machine learning and Deep learning
                  technologies.​
                </div>
              </div>
            ) : null}
            {ldb === 4 ? (
            
              <div className="w-full px-8">
                <div
                  className="h-[70vw] bg-[#5d5d5d] bg-contain bg-top bg-no-repeat text-white flex flex-col justify-end items-start py-2 px-4"
                  style={{
                    backgroundImage: `url(${nidhi_mam})`,
                  }}
                >
                  <div className="w-full px-2 bg-black bg-opacity-30 rounded-md flex flex-row justify-between items-center">
                    <div className="flex flex-col justify-center items-start">
                      <div className="text-xl font-semibold">Nidhi Tiwari</div>
                      <div className="text-base font-medium">
                        Director- HR /<br />
                        Fund Manager
                      </div>
                    </div>
                    {/* <div className="h-10 w-10 bg-white rounded-full flex items-center justify-center text-xl text-black font-bold">
            in
          </div> */}
                  </div>
                </div>
                <div className="py-5 px-3 text-left h-fit shadow-cs1 mb-5 rounded-b-lg">
                  With an MBA in Finance and over 10 years of extensive
                  experience, Mrs. Nidhi specializes in Financial Planning,
                  Investment Strategy, and Fund Management. Her expertise spans
                  across managing diverse portfolios and crafting strategies
                  that drive sustainable growth and maximize returns.
                </div>
              </div>
            ) : null}
            <div className="flex flex-row justify-between mx-8">
              <div
                className="border border-[#ECEBEB] shadow-cs1 flex flex-row items-center justify-between w-fit py-2 px-3 gap-3 rounded-2xl"
                onClick={() => lPrev()}
              >
                <div className="h-6 w-6 font-semibold flex justify-center items-center border border-black rounded-full">
                  {"<"}
                </div>
                Prev
              </div>
              <div
                className="border border-[#ECEBEB] shadow-cs1 flex flex-row items-center justify-between w-fit py-2 px-3 gap-3 rounded-2xl"
                onClick={() => lNext()}
              >
                Next
                <div className="h-6 w-6 font-semibold flex justify-center items-center border border-black rounded-full">
                  {">"}
                </div>
              </div>
            </div>
          </div>
          <div className="mx-5 mt-12 mb-5  text-2xl font-medium text-center">
            Ready to take the first step towards a balanced, healthier life?
          </div>
          <div className="w-full flex items-center justify-center">
            <button className="w-full mx-16 mb-20 p-3 text-xl rounded-lg bg-cbg2 text-white"   onClick={handleAssessmentStart}>
              Start the Assessment
            </button>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};
export default Dashboard;
