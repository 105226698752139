import React from 'react'
import yoga from '../../assets/member_login.png'
import ayurlogo from '../../assets/Care_Vedic.png'
import { useNavigate } from 'react-router-dom'
 
function MembersLogin() {
  const navigate = useNavigate()
 
  return (
    <div>
      {/* D E S K T O P */}
      <div className='h-screen w-full flex xxs:hidden tabPotrait:flex flex-row'>
        <div className='h-full w-1/2 px-10 tab:px-12 pcs:px-14 gap-2 tab:gap-5 pcs:gap-8 bg-[#F8F0F2] flex flex-col justify-center items-center'>
          <img src={ayurlogo} className='h-10 pcs:h-20'/>
          <div className='text-3xl pcs:text-5xl font-semibold tracking-wider'>Aivotayur</div>
          <div>
            <ul className='list-disc flex flex-col pcs:gap-5 text-sm tab:text-base pcs:text-xl font-medium'>
              <li>Aivotayur is a revolutionary platform that combines the timeless principles of Ayurveda with advanced AI technology.</li>
              <li>It provides users with a personalized Ayurvedic health report based on prakriti (innate constitution), vikruti (current imbalances), and other health indicators analyzed through a quick 60-second video and detailed questionnaire.</li>
              <li>The aim is to guide users toward optimal health through tailored diet, lifestyle, and wellness recommendations.</li>
            </ul>
          </div>
          <button
            className='w-48 tab:w-60 pcs:w-72 py-2 pcs:py-4 rounded-lg bg-cbg2 text-white shadow-cs1'
            onClick={() => navigate("/login")}
          >
            Aivotayur Member
          </button>
          <button
            className='w-48 tab:w-60 pcs:w-72 py-2 pcs:py-4 rounded-lg bg-cbg2 text-white shadow-cs1'
            onClick={() => navigate("/enduser")}
          >
            End User
          </button>
        </div>
        <div className='h-full w-1/2'>
          <img src={yoga} className='w-full h-full' />
        </div>
      </div>
 
      {/* M O B I L E */}
      <div className='h-screen w-full flex tabPotrait:hidden flex-col'>
        <div className='h-fit w-full relative'>
          <img src={yoga} className='w-full h-80' />
          <div className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white bg-opacity-20 backdrop-blur-2xl py-5 px-8 rounded-lg flex flex-col items-center gap-2'>
            <img src={ayurlogo} className='h-12'/>
            <div className='text-3xl pcs:text-5xl font-semibold tracking-wider'>Aivotayur</div>
          </div>
        </div>
        <div className='h-full w-full px-10 py-10 gap-10 bg-[#F8F0F2] flex flex-col justify-start items-center'>
          <div>
            <ul className='list-disc flex flex-col text-base font-semibold gap-5'>
              <li>Aivotayur is a revolutionary platform that combines the timeless principles of Ayurveda with advanced AI technology.</li>
              <li>It provides users with a personalized Ayurvedic health report based on prakriti (innate constitution), vikruti (current imbalances), and other health indicators analyzed through a quick 60-second video and detailed questionnaire.</li>
              <li>The aim is to guide users toward optimal health through tailored diet, lifestyle, and wellness recommendations.</li>
            </ul>
          </div>
          <button
            className='w-full py-4 rounded-lg bg-cbg2 text-white shadow-cs1'
            onClick={() => navigate("/login")}
          >
            Aivotayur Member
          </button>
          <button
            className='w-full py-4 rounded-lg bg-cbg2 text-white shadow-cs1'
            onClick={() => navigate("/enduser")}
          >
            End User
          </button>
        </div>
      </div>
    </div>
  )
}
 
export default MembersLogin








// import React from 'react'
// import yoga from '../../assets/member_login.png'
// import ayurlogo from '../../assets/Care_Vedic.png'
// import { useNavigate } from 'react-router-dom'

// function MembersLogin() {
//   const navigate = useNavigate()

//   return (
//     <div className='h-screen w-full flex flex-row'>
//       <div className='h-full w-1/2 px-10 tab:px-12 pcs:px-14 gap-2 tab:gap-5 pcs:gap-8 bg-[#F8F0F2] flex flex-col justify-center items-center'>
//         <img src={ayurlogo} className='h-10 pcs:h-20'/>
//         <div className='text-3xl pcs:text-5xl font-semibold tracking-wider'>Aivotayur</div>
//         <div>
//           <ul className='list-disc flex flex-col pcs:gap-5 text-sm tab:text-base pcs:text-xl font-medium'>
//             <li>Aivotayur is a revolutionary platform that combines the timeless principles of Ayurveda with advanced AI technology.</li>
//             <li>It provides users with a personalized Ayurvedic health report based on prakriti (innate constitution), vikruti (current imbalances), and other health indicators analyzed through a quick 60-second video and detailed questionnaire.</li>
//             <li>The aim is to guide users toward optimal health through tailored diet, lifestyle, and wellness recommendations.</li>
//           </ul>
//         </div>
//         <button 
//           className='w-48 tab:w-60 pcs:w-72 py-2 pcs:py-4 rounded-lg bg-cbg2 text-white shadow-cs1'
//           onClick={() => navigate("/login")}
//         >
//           Aivotayur Member
//         </button>
//         <button 
//           className='w-48 tab:w-60 pcs:w-72 py-2 pcs:py-4 rounded-lg bg-cbg2 text-white shadow-cs1'
//           onClick={() => navigate("/enduser")}
//         >
//           End User
//         </button>
//       </div>
//       <div className='h-full w-1/2'>
//         <img src={yoga} className='w-full h-full' />
//       </div>
//     </div>
//   )
// }

// export default MembersLogin