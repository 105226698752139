import React, { useState, useEffect } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import "./LearnMore.css";
import boardingImg from "../../assets/Learn-more-aurveda-pic.png";
import boardingImgMobile from "../../assets/Learn-more-aurveda-pic-mobile.png";
function LearnMore() {
  const [isMobile, setMobile] = useState(false);
  const [openIndex, setOpenIndex] = useState(null);

  const faqData = [
    {
      question: "1. What are the three doshas – Vata, Pitta, and Kapha?",
      answer:
        "In Ayurveda, Vata, Pitta, and Kapha are the three primary doshas, or energy types, that make up our physical and mental makeup. Each person has a unique combination of these doshas, which influence everything from body structure and temperament to tendencies for certain health conditions. Understanding your dosha balance helps in maintaining health and well-being.",
    },
    {
      question: "2. What does Vikruti mean, and how is it different from Prakriti?",
      answer:
        "Vikruti represents your body’s current state, which can change over time due to lifestyle, diet, environment, and stress. Unlike Prakriti, which is stable, Vikruti may shift and reveal imbalances that can affect your well-being. Analyzing both Prakriti and Vikruti gives a holistic view of your health needs.",
    },
    {
      question: "3. How does the Ayurvedic assessment process work?",
      answer:
        "The Ayurvedic assessment involves answering questions to identify your Prakriti and Vikruti, along with a health check for inflammatory and non-inflammatory symptoms. A final video analysis captures facial features and heart rate, enabling a comprehensive AI-driven health profile based on Ayurvedic principles.",
    },
    {
      question: "4. How is my data used and protected in the assessment?",
      answer:
        "We value your privacy and handle your data with utmost care. The information gathered from your responses and video analysis is used solely to generate personalized health recommendations. We adhere to strict data protection policies to ensure your information remains confidential.",
    },
    {
      question: "5. How often should I update my Vikruti assessment?",
      answer:
        "It’s recommended to reassess your Vikruti periodically or whenever you experience notable lifestyle, health, or environmental changes. Regular assessments help keep your wellness plan aligned with your current needs, supporting a balanced lifestyle.",
    },
    {
      question: "6. How does Ayurvedic assessment help with dosha balance?",
      answer:
        "The Ayurvedic assessment identifies your Prakriti (natural constitution) and Vikruti (current state) to detect any dosha imbalances. This helps in providing personalized recommendations on diet, lifestyle, and wellness practices tailored to rebalance your doshas and improve your overall health.",
    },
  ];

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  useEffect(() => {
    const handleResize = () => {
      setMobile(window.innerWidth < 768);
    };
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.addEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="learnMore-main-container">
      <Header />
      <div className="learnMore-boarding">
        {isMobile ? (
          <img src={boardingImgMobile} className="learnMore-boarding-img" />
        ) : (
          <img src={boardingImg} className="learnMore-boarding-img" />
        )}
        <div className="learnMore-boarding-text">
          Learn More about Aivotayur
        </div>
      </div>

      <div className="learnMore-Info">
        <div className="learnMore-Info-box">
          <div className="learnMore-info-box-question">
            1. What is Aivotayur?
          </div>
          <div className="learnMore-info-box-answer">
            Aivotayur is a revolutionary platform that combines the timeless
            principles of Ayurveda with advanced AI technology. It provides
            users with a personalized Ayurvedic health report based on prakriti
            (innate constitution), vikruti (current imbalances), and other
            health indicators analyzed through a quick 60-second video and
            detailed questionnaire. The aim is to guide users toward optimal
            health through tailored diet, lifestyle, and wellness
            recommendations.
          </div>
        </div>

        <div className="learnMore-Info-box">
          <div className="learnMore-info-box-question">
            2. What is Prakriti?
          </div>
          <div className="learnMore-info-box-answer">
            Prakriti refers to an individual’s innate constitution or natural
            balance of the three doshas: Vata, Pitta, and Kapha. It is
            determined at conception and remains unchanged throughout life.
            Prakriti governs your physical structure, physiological tendencies,
            and mental attributes.
          </div>
        </div>

        <div className="learnMore-Info-box">
          <div className="learnMore-info-box-question">
            3. How is Prakriti determined?
          </div>
          <div className="learnMore-info-box-answer">
            Prakriti is influenced by factors such as genetics, parental doshas,
            and the environment during conception. It is identified through
            observations of physical traits, mental characteristics, and
            responses to environmental factors.
          </div>
        </div>

        <div className="learnMore-Info-box">
          <div className="learnMore-info-box-question">
            4. Why is Prakriti important?
          </div>
          <div className="learnMore-info-box-answer">
            Understanding your Prakriti helps you align your diet, lifestyle,
            and habits to maintain balance and prevent diseases. For instance, a
            Vata-dominant individual should prioritize grounding and warming
            activities to counteract their natural tendencies.
          </div>
        </div>

        <div className="learnMore-Info-box">
          <div className="learnMore-info-box-question">
            5. What is Vikruti in Ayurveda?
          </div>
          <div className="learnMore-info-box-answer">
            Vikruti represents the current state of imbalance in your doshas. It
            reflects how lifestyle, diet, stress, or environmental factors have
            disturbed your natural prakriti.
          </div>
        </div>

        <div className="learnMore-Info-box">
          <div className="learnMore-info-box-question">
            6. How is Vikruti identified?
          </div>
          <div className="learnMore-info-box-answer">
            Vikruti is determined by analyzing your current physical, mental,
            and emotional states. Changes in sleep patterns, digestion, energy
            levels, and mood often signal an imbalance.
          </div>
        </div>

        <div className="learnMore-Info-box">
          <div className="learnMore-info-box-question">
            7. Why is Vikruti important?
          </div>
          <div className="learnMore-info-box-answer">
            Identifying your Vikruti helps target specific imbalances and
            provides actionable steps to restore harmony. For example, an
            aggravated Pitta may cause inflammation, which can be mitigated with
            cooling foods and calming activities.
          </div>
        </div>

        <div className="learnMore-Info-box">
          <div className="learnMore-info-box-question">
            8. What are the characteristics of Vata?
          </div>
          <div className="learnMore-info-box-answer">
            Physical Traits: Thin body frame, dry skin, cold hands and feet.
          </div>
          <div className="learnMore-info-box-answer">
            Mental Traits: Creativity, quick thinking, but prone to anxiety.
          </div>
          <div className="learnMore-info-box-answer">
            Digestive Traits: Irregular appetite and digestion.
          </div>
        </div>

        <div className="learnMore-Info-box">
          <div className="learnMore-info-box-question">
            9. What happens when Vata is increased or decreased?
          </div>
          <div className="learnMore-info-box-answer">
            Increased Vata: Causes restlessness, dry skin, constipation, and
            insomnia.
          </div>
          <div className="learnMore-info-box-answer">
            Decreased Vata: Leads to lethargy, slow digestion, and poor
            circulation.
          </div>
        </div>

        <div className="learnMore-Info-box">
          <div className="learnMore-info-box-question">
            10. How can Vata be balanced?
          </div>
          <div className="learnMore-info-box-answer">
            Eat warm, grounding foods like soups and stews. Follow a regular
            routine and engage in calming activities like yoga or meditation.
          </div>
        </div>

        <div className="learnMore-Info-box">
          <div className="learnMore-info-box-question">
            11. What is Pitta Dosha?
          </div>
          <div className="learnMore-info-box-answer">
            Pitta is the energy of transformation, governed by fire and water
            elements. It regulates digestion, metabolism, and body temperature.
          </div>
        </div>

        <div className="learnMore-Info-box">
          <div className="learnMore-info-box-question">
            12. What are the characteristics of Pitta?
          </div>
          <div className="learnMore-info-box-answer">
            Physical Traits: Medium build, warm body temperature, oily skin.
          </div>
          <div className="learnMore-info-box-answer">
            Mental Traits: Sharp intellect, competitiveness, but prone to
            irritability.
          </div>
          <div className="learnMore-info-box-answer">
            Digestive Traits: Strong digestion but prone to acidity.
          </div>
        </div>

        <div className="learnMore-Info-box">
          <div className="learnMore-info-box-question">
            13. What happens when Pitta is increased or decreased?
          </div>
          <div className="learnMore-info-box-answer"></div>
          <div className="learnMore-info-box-answer">
            Increased Pitta: Causes inflammation, acidity, anger, and skin
            rashes.
          </div>
          <div className="learnMore-info-box-answer">
            Decreased Pitta: Results in poor digestion, low appetite, and cold
            sensations.
          </div>
        </div>

        <div className="learnMore-Info-box">
          <div className="learnMore-info-box-question">
            14. How can Pitta be balanced?
          </div>
          <div className="learnMore-info-box-answer">
            Incorporate cooling foods like cucumbers and melons. Avoid spicy and
            fried foods. Practice relaxation techniques and spend time in
            nature.
          </div>
        </div>

        <div className="learnMore-Info-box">
          <div className="learnMore-info-box-question">
            15. What is Kapha Dosha?
          </div>
          <div className="learnMore-info-box-answer">
            Kapha is the energy of stability, formed by earth and water
            elements. It provides structure, lubrication, and immunity.
          </div>
        </div>

        <div className="learnMore-Info-box">
          <div className="learnMore-info-box-question">
            16. What are the characteristics of Kapha?
          </div>
          <div className="learnMore-info-box-answer">
            Physical Traits: Sturdy build, smooth and oily skin, slow
            metabolism.
          </div>
          <div className="learnMore-info-box-answer">
            Mental Traits: Calm, nurturing, but prone to laziness.
          </div>
          <div className="learnMore-info-box-answer">
            Digestive Traits: Slow digestion and tendency to gain weight.
          </div>
        </div>

        <div className="learnMore-Info-box">
          <div className="learnMore-info-box-question">
            17. What happens when Kapha is increased or decreased?
          </div>
          <div className="learnMore-info-box-answer">
            Increased Kapha: Leads to weight gain, congestion, lethargy, and
            attachment.
          </div>
          <div className="learnMore-info-box-answer">
            Decreased Kapha: Causes dryness, joint pain, and restlessness
          </div>
        </div>

        <div className="learnMore-Info-box">
          <div className="learnMore-info-box-question">
            18. How can Kapha be balanced?
          </div>
          <div className="learnMore-info-box-answer">
            Eat light, spicy, and warm foods. Engage in regular exercise and
            avoid oversleeping
          </div>
        </div>

        
      </div>
      <div className="FAQ-WRAP">
        <div className="FAQ-text">FAQs</div>
        <div className="faq-section">
          {faqData.map((item, index) => (
            <div className="faq-item" key={index}>
              <div className="faq-question" onClick={() => toggleFAQ(index)}>
                <span>{item.question}</span>
                <span className="plus-icon">{openIndex === index ? '-' : '+'}</span>
              </div>
              {openIndex === index && (
                <div className="faq-answer">
                  <p style={{ marginLeft: "2vw" }}>{item.answer}</p>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default LearnMore;
