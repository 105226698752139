import React from "react";
import "./Footer.css";
import Footerlogo from "../../assets/careVedic.png";
import Whatsapp from "../../assets/WhatsApp.png";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-section">
        <div className="footer-logo">
          <img src={Footerlogo} alt="Aivotayur Logo" className="Footerlogo" />
          <p>
            Aivotayur combines the ancient wisdom of Ayurveda with modern AI to
            provide personalized health insights tailored to your unique body type and lifestyle. 
            Our mission is to empower you to achieve balanced well-being through holistic, 
            data-driven recommendations that nurture both body and mind.
          </p>
        </div>
        <nav className="footer-nav">
          <ul>
            <a href="/dashboard">Home</a>
            <a href="/about">About Us</a>
            <a href="/test">Test Page</a>
            <a href="/contactus">Contact Us</a>
            <a href="/learn">Learn More</a>
            <a href="/reports">Reports</a>
          </ul>
        </nav>
        <div className="footer-contact">
         
          <div className="newsletter">
            <div className="newsletter-text">SUBSCRIBE TO NEWSLETTER</div>
            <form>
              <input type="email" placeholder="Enter your email" required />
              <button type="submit">Subscribe</button>
            </form>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
