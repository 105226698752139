import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom"; // React Router
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./OTPPage.css";
import googlelogo from "../../assets/google.svg";
import logo from "../../assets/logoayur.png";

function OTPPage() {
  const [currentSlide, setCurrentSlide] = useState(0);
  // const { state } = useLocation();
  // const { email } = state || {};
  const navigate = useNavigate();
  const email = localStorage.getItem("email");
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [message, setMessage] = useState("");
  const [resendMessage, setResendMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const insights = [
    {
      description:
        "Unlock lifestyle and diet guidance tailored to your unique body type and wellness goals.",
    },
    {
      description:
        "Experience a unique blend of Ayurveda and AI to reveal your body's natural balance.",
    },
    {
      description:
        "Understand your Dosha and receive recommendations crafted for your mind-body balance.",
    },
  ];
  // Automatically change slides every 3 seconds
  useEffect(() => {
    const slideInterval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % insights.length);
    }, 3000);

    return () => clearInterval(slideInterval);
  }, [insights.length]);

  const handleSlideClick = (index) => {
    setCurrentSlide(index);
  };
  const handleOtpChange = (e, index) => {
    const { value } = e.target;
    setOtp((prevOtp) => {
      const newOtp = [...prevOtp];
      newOtp[index] = value;
      return newOtp;
    });
  };
  const handleOtpSubmit = async () => {
    setIsSubmitting(true);
    setMessage("");

    try {
      const response = await axios.post(
        "https://aivotayur.com/api/verify-otp",
        {
          email,
          otp: otp.join(""),
        }
      );
      setMessage(response.data.message);
      // Navigate to a success page or dashboard
      navigate("/login");
    } catch (error) {
      setMessage(
        error.response?.data?.message ||
          "OTP verification failed. Please try again."
      );
    } finally {
      setIsSubmitting(false);
    }
  };
  const handleResendOtp = async () => {
    try {
      const response = await axios.post(
        "https://aivotayur.com/api/resend-otp",
        {
          email: email,
        }
      );
      if (response.status === 200) {
        setResendMessage("A new OTP has been sent to your email address.");
      }
    } catch (error) {
      console.error("Failed to resend OTP:", error);
      setResendMessage("Failed to resend OTP. Please try again.");
    }
  };

  return (
    <div className="OtpContainer">
      <div className="ayur-otp-container">
        <div className="form-section">
          <div className="welcome-text"> Welcome to Aivotayur</div>
          {/* <div className="Getting-Started">
            <p className="Getting-Started-text">Getting started is easy</p>
            <div className="social-signin">
              <div className="googlelayout">
                <div className="googlewrap">
                  <img src={googlelogo} alt="Logo" className="googlelogo" />
                  <p className="googletext flex items-center">Google</p>
                </div>
              </div>
            </div>
          </div>
          <p className="mt-4 mb-6">Or continue with</p> */}
          <section className="otp-section">
            <h2 className="font-bold">OTP Verification</h2>
            <p>
              Enter the verification code we just sent to your email address:
            </p>
            <div className="otp-inputs">
              {otp.map((digit, index) => (
                <input
                  key={index}
                  type="text"
                  maxLength="1"
                  value={digit}
                  onChange={(e) => handleOtpChange(e, index)}
                  onKeyUp={(e) => {
                    if (e.target.value && index < otp.length - 1) {
                      // Move to the next input if a digit is entered
                      document.getElementById(`otp-input-${index + 1}`).focus();
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Backspace" && !e.target.value && index > 0) {
                      // Move to the previous input if Backspace is pressed and the current input is empty
                      document.getElementById(`otp-input-${index - 1}`).focus();
                    }
                  }}
                  id={`otp-input-${index}`} // Give each input an id for easy reference
                />
              ))}
            </div>
            <button
              className="submit-btn-otp"
              onClick={handleOtpSubmit}
              disabled={isSubmitting}
            >
              {" "}
              {isSubmitting ? "Verifying..." : "Submit"}
            </button>
            {message && <p className="message">{message}</p>}
            {/* <p className="resend-link">
                  Didn't receive the code? <a  href="#!">Resend</a>
                </p> */}

            {/* <p className="resend-link">
                 Didn't receive the code? <a href="/resend">Resend</a>
                </p> */}
          </section>

          <div className="login-prompt-container mt-4">
            <p className="login-text">
              Didn't receive the code?{" "}
              <a href="#!" onClick={handleResendOtp}>
                Resend
              </a>
            </p>
            {resendMessage && <p className="resend-message">{resendMessage}</p>}
          </div>
        </div>
      </div>
      <div className="outerInsightmob">
      <div className="logo"><img src={logo}/></div>
      <div className="Otp-Insights-container">
        <h2 style={{ color: "var(--Gray-0, #FFF)" }}>
          Discover the Power of Ayurvedic Insights
        </h2>
        <p style={{ color: "var(--Gray-0, #FFF)" }}>
          {insights[currentSlide].description}
        </p>

        <div className="slide-dots">
          {insights.map((_, index) => (
            <span
              key={index}
              className={`dot ${index === currentSlide ? "active" : ""}`}
              onClick={() => handleSlideClick(index)}
            />
          ))}
        </div>
      </div>
      </div>
    </div>
  );
}
export default OTPPage;
