import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Homepage from "./routes/Homepage/Homepage";
import SignUp from "./routes/SignUp/SignUp";
import OTPPage from "./routes/OTPPage/OTPPage";
import ForgotPassword from "./routes/ForgotPassword/ForgotPassword";
import ChangePassword from "./routes/ChangePassword/ChangePassword";
import Header from "./components/Header/Header";
import Dashboard from "./routes/Dashboard/Dashboard";
import Footer from "./components/Footer/Footer";
import ContactUs from "./routes/ContactUs/ContactUs";
import Test from "./routes/Test/Test";
import EditProfile from "./routes/EditProfile/EditProfile";
import QuestionSet from "./routes/TestPage/Questionset";
import { AuthProvider, useAuth } from "./Authentication/Auth";
import Pdf from './routes/Pdf';
import Result from "./routes/Result/Result";
import LearnMore from "./routes/LearnMore/LearnMore";
import About from "./routes/About/About";
import EndUser from "./routes/EndUser/EndUser";
import MembersLogin from "./routes/MembersLogin/MenbersLogin";
import ReportsPage from "./routes/ReportsPage/ReportsPage";

function App() {
  const ProtectedRoute = ({ element, ...rest }) => {
    const auth = useAuth();

    // Check if the user is logged in and has the correct role
    const isAuthenticated = auth.isLoggedIn;

    return isAuthenticated ? element : <Navigate to="/" replace />;
  };
  return (
    <Router>
      <AuthProvider>
        <Routes>
          <Route path="/" element={<MembersLogin/>}/>
          <Route path="/login" element ={<Homepage/>}/>
          <Route path="/signup" element ={<SignUp/>}/>
          <Route path="/otp" element ={<OTPPage/>}></Route>
          <Route path="/forgot-password" element ={<ForgotPassword/>}></Route>
          <Route path="/change-password" element ={<ChangePassword/>}></Route>
          <Route path="/dashboard" element={<ProtectedRoute element={<Dashboard/>} />} />
          <Route path="/contactus"  element={<ProtectedRoute element ={<ContactUs/>}/>}/>
          <Route path="/test"  element={<ProtectedRoute element ={<Test/>}/>}/> 
          <Route path="/editprofile" element={<ProtectedRoute element ={<EditProfile/>}/>}/>
          <Route path="/pdf" element={<ProtectedRoute element ={<Pdf/>} />} />
          {/* <Route path="/result" element={<ProtectedRoute element ={<Result/>} />} /> */}
          <Route path="/learn" element={<ProtectedRoute element={<LearnMore/>}/>} />
          <Route path="about" element={<ProtectedRoute element={<About/>}/>}></Route>
          <Route path = "/test/:token" element={<Test/>}/>
          <Route path = "/result" element = {<Result/>}/>
          <Route path="/enduser" element={<EndUser/>} />
          <Route path="/reports" element={<ReportsPage/>}/>
        </Routes>
      </AuthProvider>
    </Router>
  );
}

export default App;
