import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import "./About.css";
import boardingImg from "../../assets/Learn-more-aurveda-pic.png";
import YogaSitting from "../../assets/YogaSitting.png";
import backGroundVideo from "../../assets/AboutVIDEO.mp4";

function About() {
  return (
    <div className="About-main-Container">
      <Header />
      <div className="About-boarding">
        <img src={boardingImg} className="About-boarding-img" />
        <div className="head-text-wrap">
          <div className="About-boarding-text">About Us</div>
          <div className="About-boarding-text2">
            Embrace the Wisdom of Ayurveda for a Healthier Tomorrow.
          </div>
        </div>
      </div>
      <div className="About-description">
        <div className="About-description-box">
          <div className="Pic-text-wrap">
            <div className="Pic-Wrap">
              <img src={YogaSitting} className="YogaSitting" />
            </div>
            <div className="Text-wrap">
              <div className="About-text">
               Aivotayur is an innovative platform that bridges the wisdom of
                ancient Ayurveda with cutting-edge AI technology.
              </div>
              <div className="About-text">
                Our goal is to provide personalized health and wellness
                solutions tailored to each individual’s unique constitution and
                lifestyle.{" "}
              </div>
              <div className="About-text">
                By blending tradition with technology, Aivotayur empowers users
                to achieve holistic well-being through customized diet plans,
                lifestyle recommendations, and wellness insights.
              </div>
              <div className="About-text">
                With precise AI-driven analysis and Ayurvedic expertise, we
                offer an unparalleled approach to identifying imbalances and
                restoring harmony.{" "}
              </div>
              <div className="About-text">
               Aivotayur is your trusted partner in navigating a personalized
                journey to optimal health and long-lasting vitality.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="About-video-mission">
        <video className="about-background-video" autoPlay muted loop playsInline>
          <source src={backGroundVideo} type="video/mp4" />
        </video>
        {/* <div className="bg-white w-[75%] rounded-lg text-center flexx flex-col justify-evenly items-center p-5 py-10">
            <div className="text-[#085946] font-bold text-2xl justify-self-center">Mission</div>
            <div className="italic font-light leading-8 w-[60%] justify-self-center" style={{ fontFamily: 'EB Garamond !important' }}>To integrate the ancient wisdom of Ayurveda with cutting-edge AI technology, empowering individuals to achieve holistic wellness through personalized insights and actionable recommendations.</div>
            <hr className="border border-gray-200 my-5"></hr>
            <div className="text-[#085946] font-bold text-2xl justify-self-center">Vision</div>
            <div className="italic font-light leading-8 w-[65%] justify-self-center" style={{ fontFamily: 'EB Garamond !important' }}>AIVOTayur aims to revolutionize health and wellness by making Ayurveda accessible and effective for modern lifestyles, fostering a harmonious balance between tradition and innovation for global well-being.</div>
        </div> */}
        <div className="Mission-Vision-Wrap">
          <div className="Mission-Wrap">
            <div className="Mission">Mission</div>
            <div className="Mission-text">
              To integrate the ancient wisdom of Ayurveda with cutting-edge AI
              technology, empowering individuals to achieve holistic wellness
              through personalized insights and actionable recommendations.
            </div>
          </div>
          <div className="Vision-Wrap">
            <div className="Vision">Vision</div>
            <div className="Vision-text">
              Aivotayur aims to revolutionize health and wellness by making
              Ayurveda accessible and effective for modern lifestyles, fostering
              a harmonious balance between tradition and innovation for global
              well-being.
            </div>
          </div>
        </div>
      </div>
      <div className="mobilemissionvission">
        <div className="mobhead">Mission</div>
        <div className="mobtext">
          To integrate the ancient wisdom of Ayurveda with cutting-edge AI
          technology, empowering individuals to achieve holistic wellness
          through personalized insights and actionable recommendations.
        </div>
        <div className="mobhead">Vision</div>
        <div className="mobtext">
          Aivotayur aims to revolutionize health and wellness by making Ayurveda
          accessible and effective for modern lifestyles, fostering a harmonious
          balance between tradition and innovation for global well-being.
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default About;
