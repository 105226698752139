import React, { useState, useEffect, useRef } from "react";
import Webcam from "react-webcam";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import classes from "./WebcamRecorder.module.css";
import extractRGBs from "./selftestUtil.js";
import * as faceLandmarksDetection from "@tensorflow-models/face-landmarks-detection";
import "@tensorflow/tfjs-core";
import "@tensorflow/tfjs-backend-webgl";
// import { updateInput } from "./formUtil";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

function uploadDataToDatabase(
  vitalsData,
  rgbd,
  navigate,
  setShowSuccess,
  setShowFailure
) {
  var accno = localStorage.getItem("accno");
  if (accno === "SA_Sub") {
    accno = "SA_Master";
  }
  const url = window.location.href;
  console.log("url", url);

  // Check if 'token=' is present in the URL
  const isTokenInUrl = url.includes("token");

  console.log("isTokenUrl", isTokenInUrl);

  if (!isTokenInUrl) {
    console.log(vitalsData);
    axios
      .post("https://iterve.aivot.ai/apollouser1", vitalsData, {
        params: { accno },
      })
      .then((response) => {
        const rgbid = response.data;
        console.log(rgbid);
        axios
          .post("https://iterve.aivot.ai/rgbvalue", {
            params: { accno: accno, rgbid: rgbid, rgbd: rgbd },
          })
          .then((res) => {
            console.log(res);
            vitalsData["id"] = rgbid;
            vitalsData["_id"] = rgbid;
            console.log(accno);
            if (accno === "Hiims_data" || accno === "NDC_DC") {
              navigate("/shortvitals", { state: vitalsData });
            } else if (accno === "Demo_1") {
              navigate("/hrvbodycardvitals", { state: vitalsData });
            } else if (accno === "Demo_2" || accno === "deepak") {
              navigate("/hrvbodycardhemovitals", { state: vitalsData });
            } else if (accno === "SA_Master") {
              window.scrollTo(0, 0);
              setShowSuccess(true);
            } else {
              navigate("/vitals", { state: vitalsData });
            }
          });
      })

      .catch((error) => {
        setShowFailure(true);
        console.error("Error:", error);
      });
  } else {
    console.log("end user", vitalsData);
    axios
      .post("https://iterve.aivot.ai/enduser", vitalsData)
      .then(async (response) => {
        console.log(response.data);

        const rgbid = response.data._id;
        const accno = response.data.name;
        axios
          .post("https://iterve.aivot.ai/rgbvalue", {
            params: { accno: accno, rgbid: rgbid, rgbd: rgbd },
          })
          .then((res) => {
            console.log(res);
            vitalsData["id"] = rgbid;
            vitalsData["_id"] = rgbid;
            if (accno === "Hiims_data" || accno === "NDC_DC") {
              navigate("/shortvitals", { state: vitalsData });
            } else if (accno === "SA_Sub") {
              navigate("/jamaicavitals", { state: vitalsData });
            } else {
              navigate("/vitals", { state: vitalsData });
            }
          });
      })
      .catch((error) => {
        console.log();
      });
  }
}

const generateCustomId = () => {
  const uuid = uuidv4();
  const letters = uuid
    .replace(/[^a-zA-Z]/g, "")
    .slice(0, 2)
    .toUpperCase(); // Get first two letters and convert to uppercase
  const digits = uuid.replace(/\D/g, "").slice(0, 4); // Remove non-digits and get the first 4 digits
  return `${letters}${digits}`;
};

function WebcamRecorder(props) {
  const [email, setEmail] = useState("enduser@aivot.ai");

  useEffect(() => {
    const emailAvailable = localStorage.getItem("email");
    if (emailAvailable) {
      setEmail(emailAvailable);
    }
  }, []);

  const [apidata, setApidata] = useState({});
  const [typearr, setTypearr] = useState([[]]);
  const [ayurResult, setAyurResult] = useState(null);
  const interval = 500;
  const navigate = useNavigate();

  useEffect(() => {
    console.log("apidata: ", apidata);
  }, [apidata]);

  const accno = localStorage.getItem("accno");

  const maxTime = 60 * 1000;
  const fps = 30;

  const {
    startRecording,
    setRecording,
    setLoading,
    isChecked,
    isAlertActive,
    setAlertActive,
    setShowSuccess,
    setShowFailure,
    ans,
  } = props;
  const url = window.location.href;

  // useEffect(() => {
  //   console.log('answers: ',ans);
  // }, [])

  // Check if 'token=' is present in the URL
  const isTokenInUrl = url.includes("token");
  let token = "";
  if (isTokenInUrl) {
    token = url.split("=")[1];
    console.log("token from circwebcam", token);
  }
  const webcamRef = useRef();
  const [progress, setProgress] = useState(0);
  const [recorder, setRecorder] = useState(null);
  const [model, setModel] = useState(null);
  const [detectionInProgress, setDetectionInProgress] = useState(false);
  const [senddata, setsenddata] = useState({});
  const [detectionProcessCount, setDetectionProcessCount] = useState(0);

  useEffect(() => {
    const model_setter = async () => {
      // const detector_baseModel = (await axios.get("https://iterve.aivot.ai/model/")).data;
      // console.log(detector_baseModel);
      const model = faceLandmarksDetection.SupportedModels.MediaPipeFaceMesh;
      console.log(model);
      const detectorConfig = {
        runtime: "mediapipe",
        solutionPath: "https://cdn.jsdelivr.net/npm/@mediapipe/face_mesh",
        // detectorModelUrl: `${process.env.PUBLIC_URL}/face_detector/model.json`,
        // detectorModelUrl: "https://iterve.aivot.ai/model/model.json",
      };
      const detector = await faceLandmarksDetection.createDetector(
        model,
        detectorConfig
      );
      console.log(detector);
      setModel(detector);
    };
    model_setter();
  }, []);

  const handleResultSave = async (params) => {
    console.log(params);
    try {
      const response = await axios.post(
        "https://aivotayur.com/api/updateResult",
        params
      );
    } catch (error) {
      // Error handling
      console.error("Error saving result:", error);
    }
  };

  const handleSave = async () => {
    try {
      const response = await axios.patch(
        "https://aivotayur.com/api/updateProfile",
        {
          email, // Include email to identify the user
          height: ans["height"],
          weight: "",
          age: ans["age"],
          contactno: ans["contactno"],
          Activity: ans["Activity"],
          sex: ans["sex"],
          Health: "",
        }
      );
      // alert("Profile updated successfully!");
      console.log("Updated user details:", response.data);
    } catch (error) {
      console.error("Error updating profile:", error);
      alert("Failed to update profile.");
    }
  };

  useEffect(() => {
    let apiresayur = null;
    if (detectionInProgress && webcamRef.current) {
      console.log("called through useEffect");
      console.log("loading starts here", performance.now());
      const ss = webcamRef.current.getScreenshot({ width: 320, height: 240 });

      const loadImage = (src) =>
        new Promise((imgResolve) => {
          const img = new Image();
          img.onload = () => {
            imgResolve(img);
          };
          img.src = src;
        });

      // console.log("screenshot", ss, webcamRef.current)

      let typeArray;
      let apiData;

      try {
        axios
          .post("https://dermo.iterve.ai/predict", {
            image: ss,
          })
          .then((apiResponse) => {
            console.log(apiResponse);

            const skinTypeMapping = {
              Dry: 1,
              Oily: 2,
              Normal: 3,
            };

            const hairTypeMapping = {
              straight: 1,
              wavy: 2,
              curly: 3,
            };

            const skin = skinTypeMapping[apiResponse.data["skin_type"]] || null;
            const hair = hairTypeMapping[apiResponse.data["hair_type"]] || null;

            typeArray = [[], [], [], [], [], []];

            const mapping = {
              vikrutiSymptoms1: 0,
              "vikrutiSymptoms1.1": 0,
              "vikrutiSymptoms1.2": 0,
              vikrutiSymptoms2: 1,
              "vikrutiSymptoms3.1": 2,
              "vikrutiSymptoms3.2": 2,
              "vikrutiSymptoms3.3": 2,
              vikrutiSymptoms4: 3,
              "vikrutiSymptoms5.1": 4,
              "vikrutiSymptoms5.2": 4,
              "vikrutiSymptoms5.3": 4,
              vikrutiSymptoms6: 5,
            };

            // Populate typeArray
            Object.keys(mapping).forEach((key) => {
              if (key in ans) {
                typeArray[mapping[key]] = typeArray[mapping[key]].concat(
                  ans[key]
                );
              }
            });

            // Remove null values
            typeArray = typeArray.map((arr) =>
              arr.filter((item) => item !== null)
            );

            apiData = {
              mcqs: [
                ans["2a_performance"],
                ans["2b_memory"],
                ans["2c_sleep"],
                ans["2d_climate"],
                ans["2e_hunger"],
                ans["2f_BodyTemperature"],
                ans["2g_Stamina"],
                ans["2h_Bowel"],
                ans["2i_Reaction"],
                skin,
                hair,
              ].map(Number),
              TypeValues: typeArray,
              height: Number(ans["height"]),
              weight: Number(ans["weight"]),
            };
            setApidata(apiData);
            setTypearr(typeArray);

            try {
              axios
                .post("https://aivotayur.com/api/ayurveda", apiData, {
                  headers: {
                    "Content-Type": "application/json",
                  },
                })
                .then((ayurResponse) => {
                  console.log("API ayurResponse:", ayurResponse);
                  setAyurResult(ayurResponse.data);
                  apiresayur = ayurResponse.data;

                  // navigate("/result", {
                  //   state: {
                  //     ayurResult: ayurResponse.data,
                  //   }
                  // });
                });
            } catch (error) {
              console.error("There was an error!", error);
            }
          });
      } catch (error) {
        console.error("Error:", error);
      }

      loadImage(ss)
        .then((img) => model.estimateFaces(img))
        .then(console.log("loading resolves here", performance.now()))
        .then(
          setTimeout(() => {
            setLoading(false);
            document
              .getElementById("webcam-container")
              .scrollIntoView({ behavior: "smooth", block: "center" });
          }, 1000)
        )
        .then(
          extractRGBs(
            webcamRef,
            fps,
            model,
            detectionInProgress,
            setProgress,
            setDetectionProcessCount,
            setShowFailure
          ).then((res) => {
            const formData = new FormData();
            const apiInputObject = {};
            apiInputObject.name = ans["fullName"];
            apiInputObject.phoneNumber = ans["contactno"];
            apiInputObject.age = Number(ans["age"]);
            apiInputObject.weight = Number(ans["weight"]);
            apiInputObject.height = Number(ans["height"]);
            apiInputObject.gender = ans["sex"] === "Male" ? 1 : 0;
            apiInputObject.Diabetic = 0;
            apiInputObject.Activity_Factor = 1.23;
            setProgress(0);
            setRecording(false);
            const rgbDatas = res[0];
            const fps = res[1];

            if (!rgbDatas) {
              setDetectionInProgress(false);
              setAlertActive(true);

              window.alert(
                "Make sure that your face is in front of the camera"
              );
              setTimeout(() => setAlertActive(false), 100);
              return;
            }

            apiInputObject["subject_id"] = generateCustomId();
            apiInputObject.fps = fps;
            apiInputObject.red = [];
            apiInputObject.green = [];
            apiInputObject.blue = [];

            if (rgbDatas === true) return; // detection is not in progress

            formData.append(`whole_rgbData`, JSON.stringify(rgbDatas));
            const customId = generateCustomId();
            formData.append("subject_id", customId);
            console.log(formData);

            for (let i = 0; i < 1350; i++) {
              apiInputObject.red.push(rgbDatas[i][0]);
              apiInputObject.green.push(rgbDatas[i][1]);
              apiInputObject.blue.push(rgbDatas[i][2]);
            }
            console.log(apiInputObject);

            fetch(`https://test.iterve.ai/api_a/process_data`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(apiInputObject),
            })
              .then((res) => {
                if (res.status !== 200) {
                  throw new Error(
                    `Request failed with status ${res.status}, ${res.json()}`
                  );
                }
                return res.json();
              })
              .then((response_data) => {
                console.log("response_data", response_data);

                const vitalsData = {
                  name: apiInputObject.name,
                  email: apiInputObject.email,
                  subjectid: apiInputObject["subject_id"],
                  phoneNumber: apiInputObject.phoneNumber,
                  diabetic: apiInputObject.Diabetic,
                  activityFactor: apiInputObject.Activity_Factor,
                  age: apiInputObject.age,
                  gender: apiInputObject.gender == 1 ? "Male" : "Female",
                  weight: apiInputObject.weight,
                  height: apiInputObject.height,
                  allowable_blood_loss: response_data["Average Blood Loss"],
                  bmi: response_data["bmi"],
                  body_fat: response_data["bfp"],
                  body_water: response_data["tbw"],
                  breathing_rate: response_data["Breathing Rate"],
                  cardiac_index: response_data["Cardiac Index"],
                  cardiac_output: response_data["Cardiac Output"],
                  dbp: response_data["DBP"],
                  hdl: response_data["HDL"],
                  hba1c: response_data["HBA1C"],
                  heart_rate: response_data["HR"],
                  hematocrit: response_data["Haematocrit"],
                  hemoglobin: response_data["Haemoglobin"],
                  hemoglobin_palm: response_data["Haemoglobin_palm"],
                  ibi: response_data["Mean IBI"],
                  ldl: response_data["LDL"],
                  mean_atrial_pressure: response_data["Mean Arterial Pressure"],
                  mean_corpuscular_hemoglobin: response_data["MCHC"],
                  mean_corpuscular_volume: response_data["MCV"],
                  mean_plasma_glucose: response_data["ABG"],
                  oxygen_saturation: response_data["SpO2"],
                  pulse_pressure: response_data["Pulse Pressure"],
                  pulse_rate_quotient: response_data["Pulse Rate Quotient"],
                  rbc_count: response_data["RBC Count"],
                  rmssd: response_data["RMSSD"],
                  random_blood_sugar: response_data["random_blood_sugar"],
                  random_blood_sugar_palm:
                    response_data["random_blood_sugar_palm"],
                  sbp: response_data["SBP"],
                  sd1: response_data["SD1"],
                  sd2: response_data["SD2"],
                  sdnn: response_data["SDNN"],
                  stress_index: response_data["Stress Index Bravesky"],
                  stroke_volume: response_data["Stroke Volume"],
                  subcutaneous_fat: response_data["sf"],
                  total_cholestrol: response_data["Total_Cholesterol"],
                  triglycerides: response_data["Triglycerides"],
                  vldl: response_data["VLDL"],
                  visceral_fat: response_data["vf"],
                  Amid: response_data["AMID"],
                  Amp: response_data["Amplitude"],
                  BSA: response_data["BSA"],
                  bloodVolume: response_data["Blood Volume"],
                  // New parameters
                  DMID: response_data["DMID"],
                  fattyLiverIndex: response_data["Fatty liver Index"],
                  GGPP: response_data["GGPP"],
                  GGPP_old: response_data["GGPP_old"],
                  HF_power: response_data["HF power"],
                  Hip: response_data["Hip"],
                  Hip_Waist_Ratio: response_data["Hip_Waist_Ratio"],
                  K_Value: response_data["K-Value"],
                  Kurtosis: response_data["Kurtosis"],
                  LDL_old: response_data["LDL_old"],
                  LF_HF_Ratio: response_data["LF HF Ratio"],
                  LF_power: response_data["LF power"],
                  MCH: response_data["MCH"],
                  PNS_Index: response_data["PNS Index"],
                  RBC_Count_Old: response_data["RBC Count_Old"],
                  SDSD: response_data["SDSD"],
                  SGOT: response_data["SGOT"],
                  SGOT_old: response_data["SGOT_old"],
                  SGPT: response_data["SGPT"],
                  SGPT_old: response_data["SGPT_old"],
                  SNS_Index: response_data["SNS Index"],
                  Skewness: response_data["Skewness"],
                  Spectral_Energy: response_data["Spectral Energy"],
                  Stress_Index_old: response_data["Stress Index Bravesky_old"],
                  Stress_Index_us: response_data["Stress Index us"],
                  Triglycerides_old: response_data["Triglycerides_old"],
                  Waist: response_data["Waist"],
                  age_sex_factor: response_data["age_sex_factor"],
                  bfm: response_data["bfm"],
                  bmr: response_data["bmr"],
                  dcn: response_data["dcn"],
                  ecgplot: response_data["ecgplot"],
                  lbm: response_data["lbm"],
                  mNPV: response_data["mNPV"],
                  param1: response_data["param1"],
                  param2: response_data["param2"],
                  serumCreatinine: response_data["serum_creatine"],
                  uricAcid: response_data["uric_acid"],
                };
                console.log("vitasData: ", vitalsData);
                // uploadDataToDatabase(
                //   vitalsData,
                //   JSON.stringify(rgbDatas),
                //   navigate,
                //   setShowSuccess
                // );
                let inflate = [[], []];

                const mapping = {
                  noninflamation1: 0,
                  "noninflamation1.2": 0,
                  inflamation1: 1,
                  inflamation2: 1,
                  inflamation3: 1,
                };

                Object.keys(mapping).forEach((key) => {
                  if (key in ans) {
                    inflate[mapping[key]] = inflate[mapping[key]].concat(
                      ans[key]
                    );
                    // console.log("len ++");
                  }
                });

                // Remove null values
                inflate = inflate.map((arr) =>
                  arr.filter((item) => item !== null)
                );

                console.log("array: ", inflate);

                const inflamation =
                  inflate[1].length > inflate[0].length ? 1 : 0;
                console.log("inflamation", inflamation);

                handleSave();

                handleResultSave({
                  email: email,
                  fullName: ans["fullName"],
                  height: ans["height"],
                  weight: ans["weight"],
                  age: ans["age"],
                  contactno: ans["contactno"],
                  Activity: ans["Activity"] || "",
                  sex: ans["sex"],
                  Health: ans["Health"] || "",
                  mcq: apiData.mcqs,
                  category: typeArray,
                  // vitals: {
                  //   heart_rate: vitalsData.heart_rate,
                  //   stroke_volume: vitalsData.stroke_volume,
                  //   mean_atrial_pressure: vitalsData.mean_atrial_pressure,
                  //   sbp: vitalsData.sbp,
                  //   dbp: vitalsData.dbp,
                  //   rmssd: vitalsData.rmssd,
                  //   sdnn: vitalsData.sdnn,
                  //   ibi: vitalsData.ibi,
                  //   sd1: vitalsData.sd1,
                  //   sd2: vitalsData.sd2,
                  //   fattyLiverIndex: vitalsData.fattyLiverIndex,
                  //   uricAcid: vitalsData.uricAcid,
                  //   stress_index: vitalsData.stress_index,
                  //   oxygen_saturation: vitalsData.oxygen_saturation,
                  // },
                  vitals: vitalsData,
                  inflamationArray: inflate,
                  aivotayur: apiresayur,
                  resultImg: "picture",
                  inflamation: inflamation,
                });

                navigate("/result", {
                  state: {
                    iterveResult: vitalsData,
                    ayurResult: apiresayur,
                    profileData: {
                      name: ans["fullName"],
                      age: Number(ans["age"]),
                      weight: Number(ans["weight"]),
                      height: Number(ans["height"]),
                      gender: ans["sex"],
                    },
                    infla: inflamation,
                    capturedImage: ss,
                    token: token,
                  },
                });
              })
              .catch((e) => {
                window.scrollTo(0, 0);
                setShowFailure(true);
              });
          })
        );
    }
  }, [detectionInProgress, webcamRef.current]);

  useEffect(() => {
    // document.getElementById("record-btn").style.backgroundColor =
    //   startRecording || !isChecked ? "#444" : "#000";
    if (startRecording && model) {
      let elapsedTime = 0;
      if (!detectionInProgress) {
        setDetectionInProgress(true);
      }
      navigator.mediaDevices
        .getUserMedia({
          video: {
            width: 640,
            height: 480,
          },
        })
        .then((stream) => {
          // function clockTick() {
          //   if (elapsedTime > maxTime || !startRecording) {
          //     setProgress(0);
          //     setRecording(false);
          //   } else {
          //     const newProgress = Math.min((elapsedTime / maxTime) * 100, 100);
          //     setProgress(newProgress);
          //     elapsedTime += interval;
          //     setTimeout(clockTick, interval);
          //   }
          // }
          // clockTick();
        });
    }
    if (!startRecording) setDetectionInProgress(false);
    return () => {
      setDetectionInProgress(false);
      if (recorder) {
        setRecorder(null);
      }
      setProgress(0);
    };
  }, [startRecording, model, isChecked]);

  window.addEventListener("beforeunload", function (e) {
    e.preventDefault();
    e.returnValue = "";
    setRecording(false);
  });

  return (
    <div className={classes.column}>
      {!isAlertActive && (
        <div className={classes.webcamrecorder}>
          <div className={classes.circularframe} id="webcam-container">
            <canvas
              className={classes.webcamOverlay}
              id="webcam-overlay"
            ></canvas>
            <Webcam
              audio={false}
              videoConstraints={{
                facingMode: "user",
              }}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              className={classes.webcam}
              mirrored
            />
          </div>
          <div className={classes.progressbar}>
            <CircularProgressbar
              strokeWidth={3}
              value={progress}
              styles={buildStyles({
                rotation: 0,
                strokeLinecap: "butt",
                pathColor: "#085946",
              })}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default WebcamRecorder;
