import React, { useState, useEffect, useRef } from "react";
import "./EditProfile.css";
import Header from "../../components/Header/Header";
import logout from "../../assets/logout.svg";
import { useAuth } from "../../Authentication/Auth";
import Profile from "../../assets/profile.jpg";
import EditIcon from "../../assets/edit.svg";
import IconProfile from "../../assets/iconprofile.png";
import axios from "axios";

const EditProfile = () => {
  const [userDetails, setUserDetails] = useState({});
  const { signOutUser } = useAuth();
  const [formData, setFormData] = useState({
    fullName: "",
    contactno: "",
    sex: "",
    profileImage: "",
  });
  const [profileImage, setProfileImage] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const fileInputRef = useRef(null);
  const email = localStorage.getItem("email");

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const User = await axios.get("https://aivotayur.com/api/loginDetails", {
          params: { email }, // Pass the email as a query parameter
        });
        // console.log("UserDetails response: ",User.data.LoginData);
        setUserDetails(User.data.LoginData);
        setFormData({
          fullName: User.data.LoginData.fullName || "",
          contactno: User.data.LoginData.contactno || "",
          sex: User.data.LoginData.sex || "",
          profileImage: User.data.LoginData.profileImage || "",
        });
        setProfileImage(User.data.LoginData.profileImage || "");
      } catch (error) {
        console.error("Error fetching user:", error);
      }
    };

    fetchUser();
  }, [email]);

  // useEffect(() => {
  //   console.log("'user details", userDetails);
  // }, [userDetails]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // const handleImageChange = (e) => {
  //   const file = e.target.files[0];
  //   if (file) {
  //     const reader = new FileReader();
  //     reader.onload = () => setProfileImage(reader.result);
  //     reader.readAsDataURL(file);
  //   }
  // };
  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const formDataImg = new FormData();
      formDataImg.append("profileImage", file);
      formDataImg.append("email", email); // Assuming you pass email for identification

      try {
        const response = await axios.post(
          "https://aivotayur.com/api/upload-profile-image",
          formDataImg,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );

        // Update the profile image in the state after successful upload
        console.log(
          `Profile image updated successfully:${response.data.profileImagePath}`
        );
        setProfileImage(
          `https://aivotayur.com${response.data.profileImagePath}`
        );
        setFormData((prevData) => ({
          ...prevData,
          profileImage: `https://aivotayur.com${response.data.profileImagePath}`, // Constructing the full image URL
        }));
        alert("Profile image updated successfully!");
      } catch (error) {
        console.error("Error uploading profile image:", error);
        alert("Failed to update profile image.");
      }
    }
  };

  const handleEditClick = () => {
    setIsEditing(!isEditing);
    if (isEditing) {
      // console.log("updated link",profileImage)
      handleSave();
    }
  };

  const handleSave = async () => {
    try {
      const response = await axios.patch(
        "https://aivotayur.com/api/updateProfile",
        {
          email, // Include email to identify the user
          ...formData,
        }
      );
      alert("Profile updated successfully!");
      // console.log("Updated user details:", response.data);
    } catch (error) {
      console.error("Error updating profile:", error);
      alert("Failed to update profile.");
    }
  };

  const handleLogout = () => {
    if (window.confirm("Are you sure you want to log out?")) {
      signOutUser();
    }
  };

  // console.log("ProfileImage",formData);

  // const name = localStorage.getItem("fullName");

  return (
    <div className="EditProfile-Container">
      <Header />
      <div className="Profile-wrap">
        <div className="Profile-box-green"></div>
        <div className="Profile-box">
          <div className="Profile-IE-wrap py-5">
            {/* <img src={Profile} className="Pimage"/> */}
            <div className="EditProfile-Image">
              <div className="avatar-wrapper">
                {true ? (
                  <img
                    src={`${formData.profileImage}`}
                    alt="Profile Avatar"
                    className="avatar-image"
                  />
                ) : (
                  <img
                    src={IconProfile}
                    alt="Profile Avatar"
                    className="avatar-image"
                  />
                )}

                {isEditing && (
                  <div
                    className="edit-badge"
                    onClick={() => fileInputRef.current.click()}
                  >
                    <img src={EditIcon} />
                  </div>
                )}
              </div>
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                accept="image/*"
                onChange={handleImageChange}
              />
            </div>

            <button
              style={{
                cursor: "pointer",
              }}
              className="EditButton text-white important hover:shadow-lg transition-all duration-300 ease-in-out"
              onClick={handleEditClick}
            >
              {" "}
              {isEditing ? "Save" : "Edit"}
            </button>
          </div>
          <form className="EditProfile-form">
            <div className="EditProfile-inputgroup1">
              <label>Full Name</label>
              <input
                type="text"
                placeholder="Ram"
                name="fullName"
                value={formData.fullName}
                onChange={handleInputChange}
                disabled={!isEditing}
              />
              <label>Email</label>
              <input
                type="email"
                placeholder="Ram@gmail.com"
                value={email}
                disabled
              />
            </div>
            <div className="EditProfile-inputgroup2">
              <label>Gender</label>
              <select
                style={{
                  WebkitAppearance: "none",
                  position: "relative",
                  display: "block",
                  border: "none",
                  backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="20" viewBox="0 0 24 20" fill="none"><path d="M20 8.5L13.4142 15.0858C12.6332 15.8668 11.3668 15.8668 10.5858 15.0858L4 8.5" stroke="white" stroke-width="2" stroke-linecap="round"/></svg>')`,
                  background: `white url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23666'%3E%3Cpath d='M7 10l5 5 5-5H7z'/%3E%3C/svg%3E") no-repeat right 12px center`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "right 0.25rem center",
                  backgroundSize: "1.1rem",
                }}
                className="bg-white text-black rounded-md hover:bg-white mb-[2vh] py-[13px] px-[19px]"
                value={formData.sex} // Bind to the state value
                onChange={(e) =>
                  setFormData({ ...formData, sex: e.target.value })
                }
                disabled={!isEditing}
              >
                <option className="" disabled>
                  Select Gender
                </option>
                <option value="Male">Male</option>
                <option className="Female">Female</option>
                <option className="Other">Other</option>
              </select>
              {/* <input
                type="text"
                name="sex"
                value={formData.sex}
                onChange={handleInputChange}
                disabled={!isEditing}
              /> */}
              <label>Contact No</label>
              <input
                type="text"
                name="contactno"
                value={formData.contactno}
                onChange={handleInputChange}
                disabled={!isEditing}
              />
            </div>
          </form>
          <div
            className="logout-container cursor-pointer"
            onClick={handleLogout}
          >
            <img src={logout} className="logoutimage" />
            <div className="logout-text"> Logout</div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default EditProfile;
