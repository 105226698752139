import React, { useState } from "react";
import "./ContactUs.css";
import Header from "../../components/Header/Header";
import Cbackground from "../../assets/Contactbackground.png";
import location from "../../assets/mi_location.svg";
import mail from "../../assets/fluent_mail-24-regular.svg";
import Footer from "../../components/Footer/Footer";

function ContactUs() {
 
  return (
    <div className="Contactus-main-container">
      <Header />
      <div className="LF-WRAP">
        <div className="left-container">
          <img src={Cbackground} className="cimage" alt="Contact Background" />
        </div>
        <div className="right-container">
          <div className="form-container">
            <div className="rtext1">Keep In Touch</div>
            <div className="rtext2">
              We prioritize responding to your inquiries promptly to ensure you
              receive the assistance you need in a timely manner.
            </div>
            <form className="contact-form">
              <input type="text" name="name" placeholder="Name" required />
              <input type="email" name="email" placeholder="Email" required />
              <input
                type="tel"
                name="phone"
                placeholder="Phone (+91)"
                required
              />
              <textarea
                name="message"
                placeholder="Message"
                rows="4"
                required
              />
              <button type="submit">Send Message</button>
            </form>
          </div>
        </div>
      </div>
      <div className="ADF-Wrap">
        <div className="Adrees-box">
          <div className="india">INDIA (HQ)</div>
          <div className="itext">
            We love to connect over email/ phone or meet in person
          </div>
          <div className="itext2">
            We are eager to connect to understand your business need and build
            an innovative AI-powered solution together.
          </div>
          <div className="irwrap">
            <img src={location} className="location" alt="Location Icon" />
            <div className="itext3">
              1609-1610, Kamdhenu Commerz, Sector - 14, Kharghar, Navi Mumbai -
              410210, India
            </div>
          </div>
          <div className="irwarp2">
            <img src={location} className="location" alt="Location Icon" />
            <div className="itext3">
              Plot No. 11, Vub Paradise, Shop No 3, Sector 35D, Kharghar, Navi
              Mumbai, Maharashtra 410210
            </div>
          </div>
          <div className="irwarp3">
            <img src={mail} className="mail" alt="Mail Icon" />
            <div className="itext3">info@aivot.in</div>
          </div>
        </div>
      </div>
      {/* <div className="FAQ-WRAP">
        <div className="FAQ-text">FAQs</div>
        <div className="faq-section">
          {faqData.map((item, index) => (
            <div className="faq-item" key={index}>
              <div className="faq-question" onClick={() => toggleFAQ(index)}>
                <span>{item.question}</span>
                <span className="plus-icon">{openIndex === index ? '-' : '+'}</span>
              </div>
              {openIndex === index && (
                <div className="faq-answer">
                  <p style={{ marginLeft: "2vw" }}>{item.answer}</p>
                </div>
              )}
            </div>
          ))}
        </div>
      </div> */}
      <Footer />
    </div>
  );
}

export default ContactUs;
